import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PrintersService } from 'src/app/services/printers.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'export-properties-modal',
  templateUrl: './export-properties-modal.component.html',
  styleUrls: ['./export-properties-modal.component.scss']
})
export class ExportPropertiesModalComponent implements OnInit {
  selectedPropertyOption: string;

  arrAvailableProperties: string[] = [];
  arrSelectedProperties: string[] = [];
  loggedPropertyList: string[] = [];
  blnShowLoader: boolean = false;
  blnJobsLoading: boolean = false;

  startDate: any;
  endDate: any;
  objMinDate: Date;
  objMaxDate: Date;
  objStartMaxDate: Date;
  @Output('eventEmitter') eventEmitter = new EventEmitter(false);

  constructor(
    public dialogRef: MatDialogRef<ExportPropertiesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private printerService: PrintersService
  ) { }

  ngOnInit() {
    this.loggedPropertyList = this.data.loggedPropertyList;
    this.arrSelectedProperties = this.loggedPropertyList;
    this.selectedPropertyOption = "all";
    this.setStartEndTime()
    if (this.data && !isNullOrUndefined(this.data.printerId)) {
      // this.getAvailablePropertiesForThePrinter();
    }
  }

  getAvailablePropertiesForThePrinter() {
    // API call to get available properties
    this.blnShowLoader = true;
    this.printerService.getAllPrinterProperties(this.data.printerId).then(res => {
      if (res) {
        if (res.rows && res.rows.length > 0) {
          this.arrAvailableProperties = res.rows.map(x => x.name);
        }
      }
    })
    .catch(err => { throw err; })
    .finally(() => this.blnShowLoader = false);
  }

  onPropertyClick(property) {
    let index = this.arrSelectedProperties.findIndex(x => x == property);
    if (index > -1) {
      this.arrSelectedProperties.splice(index, 1);
    } else {
      this.arrSelectedProperties.push(property);
    }
  }

  clearSelectedPropertyList() {
    this.arrSelectedProperties = [];
  }

  setSelectedPropertyList() {
    this.arrSelectedProperties = this.loggedPropertyList;
  }

  setStartEndTime() {
    this.endDate = this.data.endDate || new Date();
    this.startDate = this.data.startDate || new Date(this.endDate.getTime() - (2 * 24 * 60 * 60 * 1000));
    this.objMinDate = this.startDate;
    this.objMaxDate = this.endDate;
    this.objStartMaxDate = this.endDate;
  }

  dateChange(event, type) {
    switch (type) {
      case 0: this.startDate = new Date(event.value); break;
      default: this.endDate = new Date(event.value); break;
    }
    this.objMinDate = this.startDate;
    this.objMaxDate = new Date();
    this.objStartMaxDate = this.endDate;
  }

  changeMaxEndTime() {
    this.objMaxDate = new Date();
    this.objStartMaxDate = this.endDate;
  }

  onCancel() {
    this.dialogRef.close('cancel');
  }

  onSave() {
    // this.printerService.getTimeseriesExport().then(res => console.log(res));
    let returnObj = {
      "selectedOption": this.selectedPropertyOption,
      "selectedProperties": this.arrSelectedProperties,
      "startDate": this.startDate,
      "endDate": this.endDate
    }
    this.eventEmitter.emit(returnObj);
    this.dialogRef.close(returnObj);
  }
}
