import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { SvgMapPipe, HeaderTransformPipe, FilterUserPipe, FilterPrinterPipe, OrderByPipe, StringNameSeparatorPipe, PropertyTransform, AdapterSerialSearchPipe } from '../../pipes/common-pipes';
import { SvgModule } from '../svg/svg.module';
import { SearchComponent } from './search/search.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { RouterModule } from '@angular/router';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OwlDateTimeIntl } from 'ng-pick-datetime';
import { DATE_NATIVE_FORMATS } from '../../models/constants/constants';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonLayoutComponent } from './common-layout/common-layout.component';
import { TreeModule } from 'angular-tree-component';
import { AlertModalComponent } from '../../components/modals/alert-modal/alert-modal.component';
import { TriggerModalService } from '../../services/trigger-modal.service';
import { LoaderComponent } from './loader/loader.component';
import { ThresholdComponent } from '../../components/threshold/threshold.component';
import { ScrollDispatchModule, ScrollingModule } from '@angular/cdk/scrolling';
import { TagsModalComponent } from '../../components/modals/tags-modal/tags-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CustomGridModalComponent } from '../../components/modals/custom-grid-modal/custom-grid-modal.component';
import { UnderConstructionComponent } from '../../components/under-construction/under-construction.component';
import { MaterialModuleModule } from '../material-module/material-module.module';
import { TranslateModule } from '@ngx-translate/core';
import { PrevMaintPrinterSelectModalComponent } from 'src/app/components/modals/prev-maint-printer-select-modal/prev-maint-printer-select-modal.component';
import { PrevMaintModalComponent } from 'src/app/components/modals/prev-maint-modal/prev-maint-modal.component';
import { DataGridComponent } from './data-grid/data-grid.component';
import { CountryCodeSelectComponent } from 'src/app/components/country-code-select/country-code-select.component';
import { FilterPipe } from 'src/app/components/country-code-select/filter.pipe';


export class DefaultIntl extends OwlDateTimeIntl {
  setBtnLabel = 'Apply'
};

@NgModule({
  declarations: [
    SvgMapPipe,
    HeaderTransformPipe,
    FilterUserPipe,
    FilterPrinterPipe,
    ProgressBarComponent,
    AlertModalComponent,
    LoaderComponent,
    OrderByPipe,
    ThresholdComponent,
    TagsModalComponent,
    StringNameSeparatorPipe,
    PropertyTransform,
    CustomGridModalComponent,
    UnderConstructionComponent,
    SearchComponent,
    CommonLayoutComponent,
    PrevMaintPrinterSelectModalComponent,
    PrevMaintModalComponent,
    DataGridComponent,
    CountryCodeSelectComponent,
    FilterPipe,
    AdapterSerialSearchPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModuleModule,
    RouterModule,
    SvgModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TreeModule.forRoot(),
    ScrollDispatchModule,
    ScrollingModule,
    DragDropModule,
    TranslateModule,
  ],
  exports: [
    
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    SvgModule,
    SvgMapPipe,
    HeaderTransformPipe,
    FilterUserPipe,
    FilterPrinterPipe,
    ProgressBarComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AlertModalComponent,
    TreeModule,
    LoaderComponent,
    OrderByPipe,
    ThresholdComponent,
    ScrollDispatchModule,
    ScrollingModule,
    TagsModalComponent,
    DragDropModule,
    StringNameSeparatorPipe,
    PropertyTransform,
    CustomGridModalComponent,
    UnderConstructionComponent,
    SearchComponent,
    CommonLayoutComponent,
    TranslateModule,
    PrevMaintPrinterSelectModalComponent,
    PrevMaintModalComponent,
    DataGridComponent,
    AdapterSerialSearchPipe
  ],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: DATE_NATIVE_FORMATS },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    TriggerModalService,
    StringNameSeparatorPipe,
    TitleCasePipe,
    OrderByPipe,
    AdapterSerialSearchPipe
  ],
  entryComponents: [
    AlertModalComponent,
    TagsModalComponent,
    SearchComponent,
    CustomGridModalComponent,
    PrevMaintModalComponent,
    PrevMaintPrinterSelectModalComponent,
    CountryCodeSelectComponent
  ]
})
export class SharedModule { }
