import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { CustomModalComponent } from '../components/modals/custom-modal/custom-modal.component';
import { AlertModalComponent } from '../components/modals/alert-modal/alert-modal.component';
import { TagsModalComponent } from '../components/modals/tags-modal/tags-modal.component';
import { ExportPropertiesModalComponent } from '../components/modals/export-properties-modal/export-properties-modal.component';
import { CustomGridModalComponent } from '../components/modals/custom-grid-modal/custom-grid-modal.component';
import { PrevMaintPrinterSelectModalComponent } from '../components/modals/prev-maint-printer-select-modal/prev-maint-printer-select-modal.component';
import { PrevMaintModalComponent } from '../components/modals/prev-maint-modal/prev-maint-modal.component';

@Injectable()
export class TriggerModalService {

    constructor(private dialog: MatDialog) { }

    private TriggerModalCustom(component: any, data: any, opt:any): MatDialogRef<any> {
        let dialogRef = this.dialog.open(component,
            {
                width: data.width != undefined ? data.width : "481px",
                height: data.height != undefined ? data.height : '255px',
                id: 'dialog-custom',
                panelClass: 'noPadding',
                data: data,
                position: data.position != undefined ? data.position : {},
                disableClose: true
            });
        return dialogRef;
    }

    private TriggerModal(component: any, data: any, opts: any): MatDialogRef<any> {
        let dialogRef = this.dialog.open(component, 
            { 
                width: opts.width != undefined ? opts.width : '332px', 
                height: opts.height != undefined ? opts.height : '275px', 
                panelClass: opts.panelClass != undefined ? opts.panelClass : 'noPadding', 
                data: data,
                disableClose: true 
            });
        return dialogRef;
    }

    public Modal_custom(data: any, opt:any): MatDialogRef<any> {
        return this.TriggerModal(CustomModalComponent, data, opt);
    }

    public Modal_alert(data: any, opt: any): MatDialogRef<any> {
        return this.TriggerModal(AlertModalComponent, data, opt);
    }

    public Modal_tags(data: any, opt: any) {
        return this.TriggerModal(TagsModalComponent, data, opt);
    }

    public Modal_Export_Properties(data: any, opt: any) {
        return this.TriggerModal(ExportPropertiesModalComponent, data, opt);
    }

    public Modal_close() {
        this.dialog.closeAll();
    }

    public Modal_grid_custom(data: any): MatDialogRef<any> {
        let opt={width:'50%',height:'75%'}
        return this.TriggerModal(CustomGridModalComponent, data,opt);
    }

    public Modal_Prev_maint_select(data: any, opt: any): MatDialogRef<any> {
        return this.TriggerModal(PrevMaintPrinterSelectModalComponent, data, opt);
    }

    public Modal_Prev_maint(data: any, opt: any): MatDialogRef<any> {
        return this.TriggerModal(PrevMaintModalComponent, data, opt);
    }
} 