import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatListModule } from "@angular/material/list";

import {
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatCheckboxModule,
  MatButtonModule,
  MatDialogModule,
  MatCardModule,
  MatDividerModule,
  MatProgressSpinnerModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatChipsModule,
  MatTooltipModule,
  MatMenuModule,
  MatExpansionModule,
  MatTabsModule,
  MatAutocompleteModule,
  MatRadioModule
} from '@angular/material';

@NgModule({
  declarations: [ ],
  imports: [
    CommonModule,
    
  ],
  exports: [
    MatTabsModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatCardModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatMenuModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatTooltipModule,
    MatSidenavModule,
    MatIconModule,
    MatTreeModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatBottomSheetModule,
    MatListModule
  ]
})
export class MaterialModuleModule { }
