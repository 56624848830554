import { Component, OnInit } from '@angular/core';
import { SvgBaseComponent } from '../../svgbase.component';

@Component({
  selector: 'shutdown-icon',
  templateUrl: './shutdown.component.html',
  styleUrls: ['./shutdown.component.sass']
})
export class ShutdownComponent extends SvgBaseComponent {
  ngOnInit() {
  }

}
