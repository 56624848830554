import { Component, Input } from '@angular/core';
import { SvgBaseComponent } from '../../svgbase.component';

@Component({
    selector: 'checkmark-icon',
    templateUrl: './checkmark.component.html',
    styles: [require('../common.component.css').toString()]
})
export class CheckmarkComponent extends SvgBaseComponent {

}