import { Component, ChangeDetectorRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseClass } from './models/baseClass';
import { NotifyService } from './services/notify.service';
import { amplifyServiceAuthState, Routes } from './models/constants/constants';
import { Location } from '@angular/common';

import Amplify, { Auth, Hub } from 'aws-amplify';
import { Subscription } from 'rxjs';
import { AutoLogoutService } from './services/auto-logout.service';
import { GroupsService } from './services/groups.service';
import { PrintersService } from './services/printers.service';
import { UsersService } from './services/users.service';
import { deployments } from 'src/environments/deployments';
import { serverUrls } from 'src/environments/server-urls';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Endpoint } from 'aws-sdk/global';
import { async } from '@angular/core/testing';
import { LoaderService } from './services/loader.service';

try {
  Amplify.configure({
    API: {
      endpoints: [
        {
          name: "printerService",
          endpoint: serverUrls[deployments.stage].printerService,
          custom_header: async () => {
            let session:any = await Auth.currentSession();
            /*return { Authorization: session.idToken.jwtToken, "AccessToken": session.accessToken.jwtToken  }*/
            return { Authorization: session.accessToken.jwtToken  }
          }
        },
        {
          name: "alertService",
          //endpoint: "https://oytxhg6nn8.execute-api.us-east-1.amazonaws.com/Dev15",
          endpoint: serverUrls[deployments.stage].alertService,
          custom_header: async () => {
            let session:any = await Auth.currentSession();
            /*return { Authorization: session.idToken.jwtToken, "AccessToken": session.accessToken.jwtToken  }*/
            return { Authorization: session.accessToken.jwtToken  }
          }
        },
        {
          name: "mtms",
          //endpoint: "https://mtms-3dc-dev.herokuapp.com/api/v1",
          endpoint: serverUrls[deployments.stage].mtms,
          custom_header: async () => {
            try{
              let session:any = await Auth.currentSession();
              return { "AccessToken": session.accessToken.jwtToken  }
            }catch{
              return {}
            }
          }
        },
        {
          name: "userMgmt",
          endpoint: serverUrls[deployments.stage].userMgmt,
          custom_header: async () => {
            try{
              let session:any = await Auth.currentSession();
              return { "Authorization": session.idToken.jwtToken  }
            }catch{
              return {}
            }
          }
        },
        {
          name: "facade",
          endpoint: serverUrls[deployments.stage].facade,
          custom_header: async () => {
            try{
              let session:any = await Auth.currentSession();
              return { "Authorization": session.idToken.jwtToken  }
            }catch{
              return {}
            }
            // let session:any = await Auth.currentSession();
            /*return { Authorization: session.idToken.jwtToken, "AccessToken": session.accessToken.jwtToken  }*/
            // return { Authorization: session.accessToken.jwtToken  }
          }
        },
        {
          name: 'facade2',
          endpoint: serverUrls[deployments.stage].facade2,
          custom_header: async () => {
            try {
                  let session: any = await Auth.currentSession();
                  return { 'Authorization': session.idToken.jwtToken };
                } catch { return {}; }
          }
        },
        {
          name: "deviceRegistration",
          endpoint: serverUrls[deployments.stage].devicerRegistration,
          custom_header: async () => {
            try{
              let session:any = await Auth.currentSession();
              return { "Authorization": session.idToken.jwtToken  }
            }catch{                 return {}
            }
          }
        },
        {
          name: "connectionData",
          endpoint: serverUrls[deployments.stage].facade,
          custom_header: async() => {
            try{
              let session:any = await Auth.currentSession();
              return { "Authorization": session.idToken.jwtToken }
            }catch{
              return {}
            }
          }
        },
        {
          name: "timeseries-data",
          endpoint: serverUrls[deployments.stage].facade,
          custom_header: async () => {
          }
        }
      ]
    }
  });
}catch(e) {
  console.log(e);
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseClass implements OnInit, OnDestroy {
  title = 'hydra20-ui';
  public isLoggedIn: any;
  beUrls = null
  // drawerSubscription: Subscription;
  // routerSubscription: Subscription;
  objLogoutSub: Subscription;

  blnShowHeader: boolean;

  @ViewChild('drawer') drawer;

  constructor(
    public loaderService: LoaderService,
    private amplifyService: AmplifyService,
    private router: Router,
    public translate: TranslateService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected notifyService: NotifyService,
    private location: Location,
    private groupService: GroupsService,
    private printerService: PrintersService,
    private usersService: UsersService,
    private autoLogout: AutoLogoutService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) {
    super(changeDetectorRef, notifyService, translate);
    this.setTranslateDefaults();
    // location.subscribe(x =>
    //   {
    //     if(x.url.includes('/login'))
    //       this.blnShowHeader = false;
    //   })
    this.amplifyService.authStateChange$.subscribe(authState => {
      if(authState.state == amplifyServiceAuthState.require_pwd){
        setTimeout(() => {
          let pwdinput = document.getElementsByClassName("amplify-form-row")[0];
          if(pwdinput){
            pwdinput.classList.add("pwd-input-div");
            let password = pwdinput.querySelector('.amplify-form-input');
            let togglePassword = pwdinput.querySelector('.fa-eye');
            if(!togglePassword){
              pwdinput.insertAdjacentHTML('beforeend', '<i class="far fa-eye" id="togglePassword"></i>');
              togglePassword = pwdinput.querySelector('#togglePassword');
              togglePassword.addEventListener('click', function (e) {
                const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
                password.setAttribute('type', type);
                this.classList.toggle('fa-eye-slash');
              });
            }
          }
        }, 1000);
      }
      const isLoggedIn = authState.state === amplifyServiceAuthState.signed_in || authState.state === amplifyServiceAuthState.confirm_signin;
      this.blnShowHeader = isLoggedIn;
      if (isLoggedIn) this.setAccessToken();
      if (this.isLoggedIn && !isLoggedIn) {
        router.navigate(['/login']);
      } else if (!this.isLoggedIn && isLoggedIn) {
        var redirect = this.route.snapshot.queryParamMap.get('return') || '/dashboard';
        if(this.location.path().includes('/login')){
          this.router.navigateByUrl(redirect);
          //router.navigate(['/dashboard']);
        }
      }else if(authState.state === amplifyServiceAuthState.sign_in){ //after setting temp password
        this.amplifyService.auth().signOut();
      }
      this.isLoggedIn = isLoggedIn;
    });
    Hub.listen('auth', (data) => {
        const { payload } = data;
        this.onAuthEvent(payload);
        this.replaceErrorMessge(data);
        //console.log('A new auth event has happened: ', data.payload.data.username + ' has ' + data.payload.event);
    })
    this.matIconRegistry.addSvgIcon(
      "treeview",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/treeview.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "preselectedview",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/preselectedview.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "csvfile",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/csv.svg")
    );
    //this.getEnvAndInitAmplify();
  }

  onAuthEvent(payload) {
    if(payload.event == "signIn_failure") {this.loaderService.hide();}
  }

  replaceErrorMessge(data) {
    if(data && data.payload && data.payload.data && (data.payload.data.code == "UnexpectedLambdaException" || data.payload.data.code == "InvalidParameterException")) {
      data.payload.data.message = "Failed to sign in. Please type in correct password";
    }
  }

  ngOnInit(): void {
    this.objLogoutSub = this.notifyService.logout.subscribe(res => {
      this.groupService.clearGroup();
      this.printerService.clearSubgroupTreeData();
      this.printerService.clearStaticInfoData();
      this.usersService.clearCurrentUserData();
    });
  }

  ngOnDestroy(): void {
    if(this.objLogoutSub) { this.objLogoutSub.unsubscribe(); }
  }

  async setAccessToken() {
    let session:any = await Auth.currentSession();
    this.printerService.setAccessToken(session.accessToken.jwtToken);
  }

}
