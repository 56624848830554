import { Component, OnInit } from '@angular/core';
import { SvgBaseComponent } from '../../svgbase.component';

@Component({
  selector: 'maintenance-icon',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.sass']
})
export class MaintenanceComponent extends SvgBaseComponent {

  ngOnInit() {
  }

}
