export class LocalStorageService {

    static readonly Key_CurrentUser: string = "current_user";
    static readonly Key_SelectedNode: string = "selected_node";
    static readonly Key_FilterCriteria: string = "filter_criteria";

    static exist(key: string) {
        return localStorage.getItem(key) != null;
    }

    static getJSON(key: string) {
        //Log.Console(`getting ${key} from local storage.`);
        return JSON.parse(localStorage.getItem(key));
    }

    static getString(key: string) {
        //Log.Console(`getting ${key} from local storage.`);
        return localStorage.getItem(key);
    }

    static set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static clear(key: string) {
        localStorage.removeItem(key);
    }

    static clearAll() {
        localStorage.clear();
    }

    static getJSONObservable(key: string) {
        return LocalStorageService.getJSON(key);
    }
}