import { dataShapeDefinitionConst, gatewayDataShapeDefinitionConst, alertDefDataShapeDefinitionConst, PrinterModels, printerModelList2, printerStateOverlayProperty, PrinterStateColorMappings } from '../models/constants/constants';
import { isNullOrUndefined } from 'util';

export function isNullOrUndefinedOrEmpty(data: any): boolean {
    return typeof data == 'string' ? data.trim() == '' || isNullOrUndefined(data) : isNullOrUndefined(data);
}

export function RemoveUserTimezoneOffset(date: Date) {
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    let newDate = new Date(date.getTime() + userTimezoneOffset);
    return newDate.getDate()+'/'+(newDate.getMonth()+1)+'/'+newDate.getFullYear()+' '+appendZero(newDate.getHours())+':'+appendZero(newDate.getMinutes())+':'+appendZero(newDate.getSeconds())
}

function appendZero(num) {
    return num < 10 ? '0'+num : num; 
}

export function convertToTreeData(data) {
    let newData = [];

    data.forEach(x => {
        let td = dataMapper(x);
        newData.push(td);
    });

    let CompanyData = newData.filter(x => x.printerStatus.toLowerCase() == 'Company'.toLowerCase());
    let facultyData = newData.filter(x => x.printerStatus.toLowerCase() == 'Facility'.toLowerCase());
    let printerData = newData.filter(x => x.printerStatus.toLowerCase() != 'Facility'.toLowerCase() && x.printerStatus != 'Company'.toLowerCase());

    facultyData.forEach(x => {
        x.children = printerData.filter(y => y.from == x.to);
    });

    CompanyData.forEach(x => {
        x.children = facultyData.filter(y => y.from == x.to);
    });
    console.log(CompanyData);
    return CompanyData;
}

export function convertMTMSTreeToTreeData(data) {
    addDeviceNode(data);
}

function addDeviceNode(data) {
    if (data.devices && data.devices.length > 0) {
        let new_node = {}
        data.devices.forEach(y => {
            new_node = {
                id: y.id,
                //name: y.name, //name is not fully defined yet
                name: y.id,
                type: "device",
                tags: y.tags,
                subgroups: []
            }
            data.total_devices = data.devices.length;
            data.subgroups.push(new_node);
        });
    } else {
        data.total_devices = 0;
    }
    data.subgroups.forEach(x => {
        addDeviceNode(x);
    });
}

function convertDeviceToNode(group) {

}

export function convertData(data) {
    let arr = [];
    data.forEach(x => {
        let td = dataMapper(x);
        arr.push(td);
    });
    return arr;
}

function dataMapper(x) {
    return {
        "from": x[dataShapeDefinitionConst["from"]],
        "to": x[dataShapeDefinitionConst["to"]],
        "connectionType": x[dataShapeDefinitionConst["connectionType"]],
        "printerState": x[dataShapeDefinitionConst["printerState"]],
        "friendlyDescription": x[dataShapeDefinitionConst["friendlyDescription"]],
        "FriendlyName": x[dataShapeDefinitionConst["FriendlyName"]],
        "ModelNumber": x[dataShapeDefinitionConst["ModelNumber"]],
        "treeDisplayName": x[dataShapeDefinitionConst["treeDisplayName"]],
        "printerStatus": x[dataShapeDefinitionConst["printerStatus"]],
        "ipAddress": x[dataShapeDefinitionConst["ipAddress"]],
        "SerialNumber": x[dataShapeDefinitionConst["SerialNumber"]],
        "name": (x[dataShapeDefinitionConst["SerialNumber"]] != undefined) ? (x[dataShapeDefinitionConst["ModelNumber"]] + " " + x[dataShapeDefinitionConst["to"]]) : x[dataShapeDefinitionConst["treeDisplayName"]],
        "id": x[dataShapeDefinitionConst["to"]]
    };
}

export function convertGatewayData(data) {
    if (!data) { return; }
    let arr = [];
    data.forEach(x => {
        let td = gatewayDataMapper(x);
        arr.push(td);
    });
    return arr;
}


function gatewayDataMapper(x) {
    return {
        "printerStatus": x[gatewayDataShapeDefinitionConst["printerStatus"]],
        "brand": x[gatewayDataShapeDefinitionConst["brand"]],
        "tags": x[gatewayDataShapeDefinitionConst["tags"]],
        "id": x[gatewayDataShapeDefinitionConst["printerId"]],
        "printerModel": x[gatewayDataShapeDefinitionConst["printerModel"]]
    };
}

export function convertAlertDefData(data) {
    let arr = [];
    data.forEach(x => {
        let td = alertDefDataMapper(x);
        arr.push(td);
    });
    return arr;
}

function alertDefDataMapper(x) {
    return {
        "alertName": x[alertDefDataShapeDefinitionConst["alertName"]],
        "alertPropName": x[alertDefDataShapeDefinitionConst["alertPropName"]],
        "alertDescription": x[alertDefDataShapeDefinitionConst["alertDescription"]],
        "threshold": getThresholdValue(x[alertDefDataShapeDefinitionConst["threshold"]][0]),
        "attribute": x[alertDefDataShapeDefinitionConst["threshold"]][0] //This for configuration
    };
}

function getThresholdValue(x) {
    let res: string = '';
    switch (x.alertType) {
        case 'EqualTo': { res = "= " + x.value; break; }
        case 'NotEqualTo': { res = "!= " + x.value; break; }
        case 'Above': {
            res = ((x.limitInclusive) ? ">= " : ">") + x.limit;
            break;
        }
        case 'Below': {
            res = ((x.limitInclusive) ? "<= " : "<") + x.limit;
            break;
        }
        case 'InRange':
            res = (x.minLimit + (x.minlimitInclusive) ? "&#8804;" : "<") + "x" + ((x.maxlimitInclusive) ? "&#8804;" : "<") + x.maxLimit;
        case 'OutOfRange': { break; } //??
        case 'DeviationAbove': { break; } //??
        case 'DeviationBelow': { break; } //??
    }
    return res;
}

export function getPrinterNameFromModel(modelNumber) {
    let printerName: string = "unknown";
    for (const key in PrinterModels) {
        if (PrinterModels.hasOwnProperty(key)) {
            if (PrinterModels[key] == modelNumber) {
                printerName = key;
                break;
            }
        }
    }
    return printerName;
}

export function getPrinterNameFromFullModel(modelNumber) {
    let printerName: string = "unknown";
    for (const fullModel in printerModelList2) {     
        if (printerModelList2.hasOwnProperty(fullModel)) {
            if (printerModelList2[fullModel].fullModel == modelNumber) {
                printerName = printerModelList2[fullModel].officialName;
                break;
            }
        }
    }
    return printerName;
}

export function minMaxValidator(min, max, type) {
    let error_message = '';
    if (!isNaN(max) && !isNaN(min)) {
        switch (type) {
            case 'Above':
            case 'Below':
            case 'EqualTo':
            case 'NotEqualTo':
                {
                    if (min == "" && max == "") {
                        error_message = "You cannot pass empty value.";
                    }
                    break;
                }
            case 'OutOfRange':
            case 'InRange':
            case 'DeviationBelow':
            case 'DeviationAbove':
                {
                    if (min == "" || max == "") {
                        error_message = "You cannot pass empty value";
                    }
                    else if (min == max) {
                        error_message = "Alert type: " + type + " cannot have equal values.";
                         }
                    else if (min > max) {
                        error_message = "Min cannot be greater than Max";
                         }
                    else if (max < min) {
                        error_message = "Max cannot be less than Min";
                         }
                    break;
                }
            default: error_message = "Alert type is unrecognized.";
        }
    }
    else {
        error_message = "Values have to be numeric.";
    }
    return error_message;
}

export function generateTooltip(row,tooltipFor:string = 'chart', offset = "+00:00") {
    let difference = (new Date(row.end).getTime() - new Date(row.start).getTime()) / 1000;
    
    var result = getTimeFrameStringFromSeconds(difference);

    if (tooltipFor == 'export') {
        return result;
    }
    else {
        return `
        <span>${row.rowLabel}</span><br/>
        <span>${row.start + offset} to ${row.end + offset}</span><br/>
        <span>Duration: ${result}</span><br/>
    `;
    }
}

export function getUserPreferenceDataForContextFromMeta({ ...metaObject }, context) {
    let data = [];
    if (!isNullOrUndefined(metaObject) && !isNullOrUndefined(metaObject.UserPreferences)) {
        let userPreferenceObject = metaObject.UserPreferences;
        if (!isNullOrUndefined(userPreferenceObject) && !isNullOrUndefined(userPreferenceObject.Preferences)) {
            let preferencesArr: object[] = userPreferenceObject.Preferences;
            let reportObj = preferencesArr.find(x => x['Context'] == context);
            if (!isNullOrUndefined(reportObj)) {
                data = reportObj['Reports'];
            }
        }
    }
    return isNullOrUndefined(data) ? [] : data.slice();
}

export function getUserPreferenceDataForMultiContextFromMeta({ ...metaObject }, context) {
    let data = [];
    if (!isNullOrUndefined(metaObject) && !isNullOrUndefined(metaObject.UserPreferences)) {
        let userPreferenceObject = metaObject.UserPreferences;
        
        if (!isNullOrUndefined(userPreferenceObject) && !isNullOrUndefined(userPreferenceObject.Preferences)) {
            let preferencesArr: object[] = userPreferenceObject.Preferences;
            let reportObj = preferencesArr.find(x => x['Context'] == context);
            if (!isNullOrUndefined(reportObj)) {
                data = reportObj['Reports'];
            }
        }
    }
    //return data.slice()
    return isNullOrUndefined(data) ? [] : data.slice();
}

export function addDataToPreferenceObject(metaObject, context, newObject) {
    if (!isNullOrUndefined(metaObject)) {
        if (isNullOrUndefined(metaObject.UserPreferences)) {
            metaObject.UserPreferences = {};
        }
        if (isNullOrUndefined(metaObject.UserPreferences.Preferences)) {
            metaObject.UserPreferences.Preferences = [];
        }

        if (isNullOrUndefined(metaObject.UserPreferences.Preferences.find(x => x['Context'] == context))) {
            metaObject.UserPreferences.Preferences.push({
                "Context": context,
                "Reports": []
            });
        }
        metaObject.UserPreferences.Preferences.map(x => {
            if (isNullOrUndefined(x.Reports)) { x.Reports = []; }
            if (x.Context == context) {
                x.Reports.push(newObject);
            }
        });
    }
}

export function updateDataToPreferenceObject(metaObject, context, newObject) {
    if (!isNullOrUndefined(metaObject)) {
        if (isNullOrUndefined(metaObject.UserPreferences)) {
            metaObject.UserPreferences = {};
        }
        if (isNullOrUndefined(metaObject.UserPreferences.Preferences)) {
            metaObject.UserPreferences.Preferences = [];
        }

        if (isNullOrUndefined(metaObject.UserPreferences.Preferences.find(x => x['Context'] == context))) {
            metaObject.UserPreferences.Preferences.push({
                "Context": context,
                "Reports": []
            });
        }
        metaObject.UserPreferences.Preferences.map(x => {
            if (x.Context == context) {
                x.Reports = newObject;
            }
        });
    }
}

export function preparePrinterStatusData(data = []) {
    let printerStatusData = [
        { key: 1, value: "Printing", totalCount: 0 },
        { key: 2, value: "Print Complete", totalCount: 0 },
        { key: 3, value: "Idle", totalCount: 0 },
        { key: 4, value: "Error", totalCount: 0 },
        { key: 5, value: "Disconnected", totalCount: 0 }
    ];
    data.forEach(x => {
        let searchKey = 0;
        if (x.key == "35") {
            searchKey = 1;
        } else if (x.key == "37") {
            searchKey = 2;
        } else if (x.key == "31") {
            searchKey = 3;
        } else if (x.key == "0" || x.key == "30" || x.key == "32" || x.key == "39" || x.key == "40" || x.key == "41" || x.key == "42" || x.key == "43" || x.key == "44") {
            searchKey = 4;
        } else if (x.key == "1") {
            searchKey = 5;
        }
        let stateObj = printerStatusData.find(y => y.key == searchKey);
        if (stateObj) {
            stateObj.totalCount += x.totalCount;
        }
    });
    return printerStatusData;
}

export function preparePrinterStateArrFromSearchStatusArr(dataArr = []) {
    let printerStateArr = [];
    dataArr.forEach(x => {
        switch (x.key) {
            case 1: printerStateArr.push(35); break;
            case 2: printerStateArr.push(37); break;
            case 3: printerStateArr.push(31); break;
            case 4: printerStateArr.push(0, 30, 32, 39, 40, 41, 42, 43, 44); break;
            case 5: printerStateArr.push(1); break;
        }
    });
    return printerStateArr;
}

export function getPrinterModelFromId(device_id) {
    try {
        return device_id.split('-')[0];
    } catch (e) {
        console.log("exception while deducing the printer model: " + e);
    }
}

export function evaluateThresholdDisplayKey(operator: string) {
    switch (operator.toLowerCase()) {
      case "lessthan": return "value_min";
      case "lessthaninclusive": return "value_min";
      case "greaterthan": return "value_max";
      case "greaterthaninclusive": return "value_max";
      case "equal": return "value";
      case "notequal": return "value";
    }
  }

export function convertBytesToMbs(bytes: number) {
    return bytes / (1024 * 1024);
}

export function convertUTCtoPrinterTimezone(timestamp, timezone_offset) {
    // function to accept time in utc and offset of printer and return the adjusted time in printer timezone

    try {
        if (timezone_offset === '' || isNullOrUndefined(timezone_offset)) { timezone_offset = '0000'; }
        if (timestamp === '' || isNullOrUndefined(timestamp)) { return ''; }

        if (typeof timestamp === 'string' &&  timestamp.charAt(timestamp.length - 1) !== 'Z') {
            if (timestamp.endsWith(preparePrintertimeZoneOffset(timezone_offset))) {
                timestamp = new Date(timestamp).toISOString();
            } else {
                timestamp += 'Z';
            }
        } else if (typeof timestamp === 'number') {
            timestamp = timestamp * 1000; // converting from Unix timestamp
        }

        let multiplier = 1;
        let abs_timezone = timezone_offset;
        const utc_date = new Date(timestamp).getTime()/1000;

        if (parseInt(timezone_offset) < 0) {
            multiplier = -1;
        }

        if (timezone_offset.charAt(0) == '-' || timezone_offset.charAt(0) === '+') {
            abs_timezone = timezone_offset.substr(1);
        }

        const hours_to_add = parseInt(abs_timezone.substr(0, 2)) * 60 * 60;
        const mins_to_add = parseInt(abs_timezone.substr(2)) * 60;

        const printer_time_epoch = utc_date + (multiplier * hours_to_add + mins_to_add);
        timestamp = new Date(printer_time_epoch * 1000).toISOString();

        return timestamp.split(".")[0];
    } catch (error) {
        console.log(error);
    }
    return timestamp;
}

export function sortMaintenanceTasks(tasks) {
    let maint_tasks = [...tasks];
    maint_tasks.map(x => {
      if (isNullOrUndefined(x.time_unit) || x.time_unit == "") { x.time_unit = "Hours"; }
    });

    let hours_task = sortByTimeFrame([...maint_tasks], "hours");
    let days_task = sortByTimeFrame([...maint_tasks], 'days');

    let new_sorted_list = [];

    let j = 0;
    hours_task.forEach(x => {
      while ((days_task[j].due_time * 24) < x.due_time) {
        new_sorted_list.push(days_task[j]);
        j++;
      }
      new_sorted_list.push(x);
    });

    if (j < days_task.length) {
      while (j < days_task.length) {
        new_sorted_list.push(days_task[j]);
        j++;
      }
    }

    return new_sorted_list;
  }

export function sortByTimeFrame(maint_tasks, time_frame) {
    let arr = [];
    let temp_arr = maint_tasks.filter(x => x.time_unit && x.time_unit.toLowerCase() == time_frame);
    if (temp_arr.length) {
        temp_arr = temp_arr.sort((a, b) => a.due_time - b.due_time);
        arr = temp_arr;
    }
    return arr;
}

export function formChartJSLineChartObject(x, timeZone) {    
    let obj = {};
    if (!isNullOrUndefined(x['_source'].value)) {
        obj = {
        "x": convertUTCtoPrinterTimezone(new Date(x['_source'].timestamp).toISOString(), timeZone),
        "y": x['_source'].value
        }
    }
    else if (!isNullOrUndefined(x['_source'].message)) {
        obj = {
        "x": convertUTCtoPrinterTimezone(new Date(x['_source'].timestamp).toISOString(), timeZone),
        "y": x['_source'].message
        }
         }
    else if (!isNullOrUndefined(x['_source'].value_string)) {
        obj = {
        "x": convertUTCtoPrinterTimezone(new Date(x['_source'].timestamp).toISOString(), timeZone),
        "y": x['_source'].value_string
        }
    }
    else if (!isNullOrUndefined(x['_source'].message_string)) {
        obj = {
            "x": convertUTCtoPrinterTimezone(new Date(x['_source'].timestamp).toISOString(), timeZone),
            "y": x['_source'].message_string
            }
        }
    return obj;
}

export function prepareChartDataSetForChartJS(data, property, unit, backgroundClr?, borderColor?, pointColor?, pointBorder?, fill?, radius?, showOverlay = false, hidden = false) {
    let chartObj = {
        "data": data,
        "label": property,
        "fill": fill ? fill : false,
        "backgroundColor": (backgroundClr ? backgroundClr : '#409acc'),
        "borderColor": (borderColor ? borderColor : '#3a8fbd'),
        "pointBackgroundColor": (pointColor ? pointColor : '#409acc'),
        'pointBorderColor': (pointBorder ? pointBorder :'#7ebcde'),
        'pointRadius': !isNullOrUndefined(radius) ? radius : 3,
        'lineTension': 0,
        'YUnit': unit ? unit : 'value',
        'showOverlay': showOverlay,
        "pointHitRadius": 0
    }
    return chartObj;
}

export function prepareOverlayData(maxPoint, chartData, previousStateData, stateData, startDate, timeZone, endDate) {
    let overlayData = [];
    let arrOverlayData = [];

    if (!chartData || chartData.length < 1) { return null; }

    if (isNullOrUndefined(maxPoint) || maxPoint == 0) { maxPoint = 1; }

    if (previousStateData && previousStateData.length > 0 && chartData.length > 0) {
        let obj = formChartJSLineChartObject(previousStateData[0], timeZone);          
        obj['property'] = printerStateOverlayProperty;
        let color = PrinterStateColorMappings.find(x => x.label == obj['y']);
        if (color) { overlayData.push(color.color); }
        else { overlayData.push(PrinterStateColorMappings.find(x => x.printerState == 0).color); }
        obj['y'] = maxPoint;
        obj['x'] = convertUTCtoPrinterTimezone(new Date(startDate).toISOString(), timeZone);
        arrOverlayData.push(obj);
    }

    let entries = [ ...stateData ];
    entries.forEach(x => {
        let obj = formChartJSLineChartObject(x, timeZone);
        if (!isNullOrUndefined(obj['x'])) {
            obj['property'] = printerStateOverlayProperty;
            let color = PrinterStateColorMappings.find(x => x.label == obj['y']);
            if (color) { overlayData.push(color.color); }
            else { overlayData.push(PrinterStateColorMappings.find(x => x.printerState == 0).color); }
            obj['y'] = maxPoint;
            arrOverlayData.push(obj);
        }
    });
    
    if (overlayData.length > 0 && arrOverlayData.length > 0 && chartData.length > 0) {
        overlayData.push(overlayData[overlayData.length - 1]);
        arrOverlayData.push({ 'x': convertUTCtoPrinterTimezone(new Date(endDate).toISOString(), timeZone), 'y': maxPoint });
    }
    
    return prepareChartDataSetForChartJS(arrOverlayData, printerStateOverlayProperty, null, overlayData, "#ffffff00", null, null, true, 0, true);
}

export function preparePrintertimeZoneOffset(timezone) {
    try {
        if (timezone.trim() === "" || isNullOrUndefined(timezone)) { return null; }
        let sign = "+";
        let tz = timezone.trim();
        if (tz.charAt(0) == "-" || tz.charAt(0) == "+") {
            sign = tz.charAt(0);
            tz = tz.slice(1);
        }
        tz = tz.padStart(4, "0");
        return sign + tz.slice(0, 2) + ":" + tz.slice(2);
    } catch(ex) {
        console.log(ex);
    }
    return "(Time zone undefined)";
}

export function getTimeFrameStringFromSeconds(seconds) {
    // localization can be added wherever necessary
    var h = Math.floor(seconds / 3600);
    var m = Math.floor(seconds % 3600 / 60);

    let timeFrame = "";
    if (h < 1) {
        // less than 1 hour
        if (m < 1) {
            // xx secs
            timeFrame = `${seconds} second` + (seconds > 1 ? "s" : "");
        } else {
            // xx mins yy secs
            timeFrame = `${m} minute` + (m > 1 ? "s" : "");
            timeFrame += `, ${seconds%60} second` + (seconds%60 > 1 ? "s" : "");
        }
    } else if (h < 24) {
        // less that a day - xx hours yy mins
        timeFrame = `${h} hour` + (h > 1 ? "s" : "");
        timeFrame += `, ${m} minute` + (m > 1 ? "s" : "");
    } else {
        // more than a day - xx days yy hours zz mins
        let days: any = Math.floor(h/24);
        timeFrame = `${days} day` + (days > 1 ? "s" : "");
        timeFrame += `, ${h%24} hour` + (h%24 > 1 ? "s" : "");
        timeFrame += `, ${m} minute` + (m > 1 ? "s" : "");
    }

    return timeFrame;
}