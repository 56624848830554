import { Component } from '@angular/core';
import { SvgBaseComponent } from '../../svgbase.component';

@Component({
  selector: 'pending-icon',
  templateUrl: './pending.component.html',
  styleUrls: ['../common.component.css']
})
export class PendingComponent extends SvgBaseComponent {

}
