export const svgMap = {
  disconnected: 'disconnected',
  connected: 'connected',
  error: 'error',
  printing: 'start',
  idle: 'idle',
  'print error aborted': 'error',
  'print cancel aborted': 'error',
  'print initializing error aborting': 'error',
  'print initializing error aborted': 'error',
  'idle error': 'error',
  'print complete': 'checkmark',
  completed: 'checkmark',
  aborted: 'error',
  unknown: 'unknown',
  cancelled: 'cross',
  failed: 'cross',
  'unknown (default)': 'unknown',
  maintenance: 'maintenance',
  material_warning: 'maintenance',
  initializing: 'initialize',
  preparing: 'pending',
  'print finalizing': 'finalize',
  'print paused': 'pause',
  paused: 'pause',
  'print error aborting': 'error',
  'print cancel aborting': 'error',
  // "print cancel aborted":"error",
  shutdown: 'shutdown',
  pending: 'pending',
  running: 'start',
  dead: 'cross',
  reset: 'reset',
  '': 'unknown',
  'print initializing': 'initialize',
};

export const UserStatus = [
  'CONFIRMED', 'FORCE_CHANGE_PASSWORD'
];
export const UserStatusText = {
  CONFIRMED: 'Confirmed',
  FORCE_CHANGE_PASSWORD: 'Confirmation needed'
};

export const DefaultTime = '0h 0m';

export const PrinterSummaryNavLinks = [
  { name: 'summary', path: '/dashboard' },
  { name: 'alerts', path: '/dashboard/alerts' }
];

export const PrinterDetailsNavLinks = [
  { name: 'overview', path: '/dashboard/printer_details' },
  { name: 'sensors', path: '/dashboard/printer_details/sensors' },
  { name: 'alerts', path: '/dashboard/printer_details/alerts' },
  { name: 'jobs', path: '/dashboard/printer_details/jobs' }
];

export const PrinterSearchResultNavLinks = [
  { name: 'printers', path: '/dashboard/printer_search' }
];

export const DialogModalMessageConst = {
  thresholdComponent: {
    title: 'set_threshold',
    component: 'threshold_component',
    buttonOKLabel: 'save',
    buttonCloseLabel: 'cancel',
    hintMsg: '',
    // message: '',
    // contentIcon: IconConsts.fa_trash,
    // buttonOkIcon: IconConsts.fa_check,
    buttonCloseIcon: '',
    // messageClass: classString.bodytext,
    containerClass: 'paddings',
    buttonClass: '',
    attrs: {},
    read_only: false
  },
  printerSearchComponent: {
    title: 'advanced_printer_search',
    component: 'printer_search_component',
    buttonOKLabel: 'ok',
    buttonCloseLabel: 'cancel',
    buttonCloseIcon: '',
    containerClass: 'paddings',
    buttonClass: '',
    height: '22em',
    position: {
      top: '7%',
      right: '2%'
    },
    attrs: {}
  },
  alerts: {
    title: '',
    buttonOKLabel: 'ok',
    buttonCloseLabel: 'cancel',
    message: '',
    alertType: '',
    messageClass: ''
  },
  tags: {
    title: 'tags',
    buttonOKLabel: 'save',
    buttonCloseLabel: 'cancel',
    message: '',
    messageClass: '',
    attrs: {}
  },
  dynamicChartComponent: {
    title: '',
    component: 'dynamic_chart_component',
    buttonOKLabel: 'ok',
    buttonCloseLabel: 'cancel',
    buttonCloseIcon: '',
    containerClass: 'paddings',
    buttonClass: '',
    height: '40em',
    width: '80em',
    startDate: null,
    endDate: null,
    chartType: null,
    chartData: null,
    chartOptions: null,
    secondaryChartType: null,
    secondaryChartData: null,
    secondaryChartOptions: null,
    secondaryFilter: false,
    view: 'custom'
  },
  dynamicC3ChartComponent: {
    title: '',
    component: 'dynamic_c3_chart_component',
    buttonOKLabel: 'ok',
    buttonCloseLabel: 'cancel',
    hintMsg: '',
    height: '90%',
    width: '80em',
    startDate: null,
    axis: null,
    endDate: null,
    chartData: null,
    secondaryChartData: null,
    overlayData: null

  },
  dynamicChartJSComponent: {
    title: '',
    component: 'dynamic-chartjs',
    buttonOKLabel: 'ok',
    buttonCloseLabel: 'cancel',
    hintMsg: '',
    height: '90%',
    startDate: null,
    axis: null,
    endDate: null,
    chartData: null,
    secondaryChartData: null,
    overlayData: null,
    dropDown: null,
    timeZone: null,
    view: 'default',
    secondaryFilter: false
  },
  dynamicStackedChartJSComponent: {
    title: '',
    component: 'dynamic_stacked_chart',
    buttonOKLabel: 'ok',
    buttonCloseLabel: 'cancel',
    hintMsg: '',
    height: '90%',
    startDate: null,
    axis: null,
    endDate: null,
    chartData: null,
    secondaryChartData: null,
    overlayData: null,
    dropDown: null,
    timeZone: null,
    view: 'default',
    secondaryFilter: false
  },
  reset_threshold: {
    title: '',
    buttonOKLabel: 'yes',
    buttonCloseLabel: 'cancel',
    message: '',
    alertType: '',
    messageClass: ''

  },
  adapter_remote_command: {
    title: '',
    buttonOKLabel: 'yes',
    buttonCloseLabel: 'cancel',
    message: '',
    alertType: '',
    messageClass: ''
  }
};

export const ComponentConst = {
  threshold_component: 'thresholdComponent',
  // "printer_search": "printerSearchComponent"
};

export const dataShapeDefinitionConst = {
  from: '_0',
  to: '_1',
  connectionType: '_2',
  printerState: '_3',
  friendlyDescription: '_4',
  FriendlyName: '_5',
  ModelNumber: '_6',
  treeDisplayName: '_7',
  printerStatus: '_8',
  ipAddress: '_9',
  SerialNumber: '_10'
};
export const gatewayDataShapeDefinitionConst = {
  brand: 'printer_brand',
  printerStatus: 'printer_status',
  SerialNumber: 'printer_serial_number',
  printerId: 'printer_id',
  tags: 'tags',
  printerModel: 'printer_model'
};

export const alertDefDataShapeDefinitionConst = {
  alertName: 'name',
  alertPropName: 'sourceProperty',
  alertDescription: 'description',
  threshold: 'alertAttributes'
};

//header map will no longer be used. To enable localization, this key value pair will be stored in assets/i18n/{language_name}.json file.
export const HeaderMap = {
  from: 'Group',
  to: 'Name',
  connectionType: 'Connection Type',
  printerState: 'Printer State',
  friendlyDescription: 'Description',
  FriendlyName: 'Name',
  ModelNumber: 'Model',
  treeDisplayName: 'Name',
  printerStatus: 'Status',
  ipAddress: 'IP',
  SerialNumber: 'Serial No.',
  //// field from gateway
  brand: 'Model',
  tags: 'Tags',
  alertName: 'Name',
  alertPropName: 'Property Name',
  alertDescription: 'Description',
  threshold: 'Threshold'
};

export const Routes = {
  login: '/login',
  signup: '/signup',
  approaval: '/signup/approval',
  printer_summary: '/dashboard',
  printer_details: '/dashboard/printer_details',
  printer_search: '/dashboard/printer_search',
  user: '/admin/user',
  user_create: '/admin/user/create',
  group: '/admin/group',
  admin_printer_details: '/admin/printer',
  admin_adapter : '/admin/adapter',
  group_create: '/admin/group/create',
  fleet_printers: '/admin/fleet/printers',
  fleet_adapters_basic: '/admin/fleet/adapters/basic',
  fleet_adapters_adv: '/admin/fleet/adapters/advanced',
  fleet_adapters_provisioning: '/admin/fleet/adapters/provisioning',
  fleet_printers_status:'/admin/fleet/printers/statusReport',
  fleet_printers_historical_status:'/admin/fleet/printers/historicalStatus',
  fleet_printers_build:'/admin/fleet/printers/buildReport',
  fleet_printers_alert: '/admin/fleet/printers/alertReport',
  //"admin_adapters_provisioning": "/admin/adapters",
  printer_overview: '/printers/overview',
  dashboard: '/dashboard',
  followed_printers: '/dashboard/followed_printers',
  active_alerts: '/printers/alerts/active',
  active_faults: '/printers/faults/active',
  history: '/printers/history',
  sensor: '/printers/sensors',
  material: '/printers/material',
  deployed_adapters: '/admin/deployed_adapters',
};

export const amplifyServiceAuthState = {
  sign_in: 'signIn',
  signed_in: 'signedIn',
  confirm_signin: 'confirmSignIn',
  require_pwd: 'requireNewPassword'
};

export const DATE_NATIVE_FORMATS = {
  fullPickerInput: { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true },
};

export const assetLinks = {
  default_user: 'assets/images/default-profile.png',
  default_group: 'assets/images/default-group.png'
};

export const roleGroups = {
  AdminRole: 'Administrator',
  //"ManagerRole": "Manager"
};

export const otherRoleGroups = {
  Organization: 'Organization'
};

export const navigationConst = [
  {
    header: 'dashboard',
    child_routes: [
      { key: 'dashboard', route: '/dashboard', disabled: false },
      { key: 'configuration', route: '/config', disabled: true }
    ]
  },
  {
    header: 'admin',
    child_routes: [
      { key: 'users', route: '/admin/user', disabled: false },
      { key: 'groups', route: '/admin/group', disabled: false },
      //{"key": "roles_and_permissions", "route": "/admin/roles", "disabled": true},
      //{"key": "adapters", "route": "/admin/adapters", "disabled": true},
      { key: 'printers', route: '/admin/printer', disabled: false }
    ]
  },
  {
    header: 'printers',
    child_routes: [
      { key: 'status', route: '/printers/status', disabled: true },
      { key: 'jobs', route: '/printers/jobs', disabled: true },
      { key: 'alerts', route: '/printers/alerts', disabled: true }
    ]
  }
];

export const mainNavLinks = [
  {
    header: 'admin',
    link: '/admin'
  },
  {
    header: 'printers',
    link: '/printers'
  }
];

export const cardTexts = ['primaryText', 'secondaryText'];
export const printerCardTexts = ['primaryText', 'secondaryText', 'tags'];

export const PrinterImagePathList = {
  32012: 'assets/images/printer_images/DMP200.png',
  32000: 'assets/images/printer_images/DMP320.png',
  32005: 'assets/images/printer_images/DMP500.png',
  32001: 'assets/images/printer_images/DMPFlex100.png',
  32002: 'assets/images/printer_images/DMPFlex200.png',
  34200: 'assets/images/printer_images/Fig4ModularController.png',
  34100: 'assets/images/printer_images/Fig4Standalone.png',
  34201: 'assets/images/printer_images/PrintStation.png',
  30002: 'assets/images/printer_images/ProJet6000.png',
  30001: 'assets/images/printer_images/ProX800.png',
  30000: 'assets/images/printer_images/ProX950.png',
  31000: 'assets/images/printer_images/ProXSLS500.png',
  31001: 'assets/images/printer_images/ProXSLS6100.png',
  31006: 'assets/images/printer_images/ProXSLS6150.png',
  34000: 'assets/images/printer_images/Fig4Dental.png',
  31002: 'assets/images/printer_images/Spro60.png',
  20000: 'assets/images/printer_images/ProJet2500.png',
  32011: 'assets/images/printer_images/DMP100.png',
  32013: 'assets/images/printer_images/DMP300.png',
  32004: 'assets/images/printer_images/DMP350.png',
  32003: 'assets/images/printer_images/DMPFlex350Dual.png',
  32006: 'assets/images/printer_images/DPMF500.png',
  24000: 'assets/images/printer_images/Panda.png',
  32007: 'assets/images/printer_images/PAMF500.png',
  32008: 'assets/images/printer_images/DMPFactory500PRM.png',
  30003: 'assets/images/printer_images/iPro8000.png',
  30004: 'assets/images/printer_images/iPro8000.png',
  30005: 'assets/images/printer_images/Kodiak.png',
  NUC: 'assets/images/NUC.png',
  60000: 'assets/images/printer_images/PostCure.png',
  20004: 'assets/images/printer_images/ProJet300W.png',
};

export const PrinterModels = {
  Fig4_Dental: '34000',
  Fig4_ModularPrintStation: '34201',
  Fig4_ModularWorkcellController: '34200',
  Fig4_Standalone: '34100',
  ProJet6k: '30002',
  ProJet7k: '30002',
  ProX_800: '30001',
  ProX_950: '30000',
  Kodiak: '30005',
  ProX_SLS500: '31000',
  ProX_SLS6100: '31001',
  ProX_SLS6150: '31006',
  Dmp_Flex100: '32001',
  Dmp_Flex200: '32002',
  Orthus:'32003',
  Dmp_320: '32000',
  Dmp_500: '32005',
  Dmp_100: '32011',
  Dmp_200: '32012',
  Dmp_350: '32004',
  Spro60: '31002',
  ProJet_MJP_2500: '20000',
  DMP_300: '32013',
  CJP_Panda: '24000',
  Postcure_1050: '60000',
  MJP_300W: '20004'
};

export const SensorHistogramOptions = [
  { key: 'default', value: 'Default' },
  { key: 'last_build', value: 'Last Build' },
  { key: 'one_day', value: 'Last 24 Hours' },
  { key: 'last_month', value: 'Last Month' },
  { key: 'custom', value: 'Custom' }
];

export const printerTimeLineOptions = [
  { key: 'default', value: 'Last 2 days' },
  { key: 'seven_days', value: 'Last 7 days' },
  { key: 'last_month', value: 'Last 30 days' },
  { key: 'custom', value: 'Custom' }
];

export const alertPropertyLineChartOptions = [
  { key: 'default', value: 'Last 7 days' },
  { key: 'one_day', value: 'Last 24 Hours' },
  { key: 'last_month', value: 'Last 30 days' },
  { key: 'custom', value: 'Custom' }
];

export const printStatusColorReference = [
// <<<<<<< dashboard2
//   { key: "#ed684a", values: ["Unknown (default)", "Unknown", "Idle Error", "Shutdown", "Print Initializing Error Aborting", "Print Initializing Error Aborted", "Print Error Aborting", "Print Error Aborted", "Print Cancel Aborting", "Print Cancel Aborted", "Maintenance"] },
//   { key: "#f2b927", values: ["Disconnected"] },
//   { key: "#a2a2a2", values: ["Idle"] },
//   { key: "#eeeeee", values: ["Print Initializing", "Print Finalizing"] },
//   { key: "#09aec6", values: ["Printing", "Print Paused"] },
//   { key: "#3ec556", values: ["Print Complete"] }
// =======
  { key: 'rgba(237, 104, 74, 0.65)', text_color: '#000', values: ['Unknown (default)', 'Unknown', 'Idle Error', 'Shutdown', 'Print Initializing Error Aborting', 'Print Initializing Error Aborted', 'Print Error Aborting', 'Print Error Aborted', 'Print Cancel Aborting', 'Print Cancel Aborted'] },
  { key: 'rgba(242, 185, 39, 0.65)', text_color: '#000', values: ['Disconnected']},
  { key: 'rgba(162, 162, 162, 0.65)', text_color: '#fff', values: ['Idle', 'Maintenance']},
  { key: '#eeeeee', text_color: '#000', values: ['Print Initializing', 'Print Finalizing']},
  { key: 'rgba(9, 174, 198, 0.65)', text_color: '#fff', values: ['Printing', 'Print Paused']},
  { key: 'rgba(62, 197, 86, 0.65)', text_color: '#fff', values: ['Print Complete']}
];

export const deviceStatusHistoryChartOptions = [
  { key: 'default', value: 'Today' },
  { key: 'seven_days', value: 'Last 7 days' },
  { key: 'last_month', value: 'Last 30 days' },
  { key: 'custom', value: 'Custom' }
];

export const faultSortingOptions = [
  { key: 'FAULT', label: 'FAULT' },
  { key: 'NORMAL', label: 'NORMAL' },
  { key: 'WARNING', label: 'WARNING' }
];


export const buildCardProperties = [
  { alias: 'start_time', name: 'Build Start Time', timeseries: false, predefined: false, visible: true },
  { alias: 'build_estimated_end_time', name: 'Build Estimated End Time', timeseries: false, predefined: false, visible: true },
  { alias: 'build_id', name: 'Build Id', timeseries: false, predefined: false, visible: true }
];


export const sensorBuildCardProps = [
  {
    31001: {
      buildInfo: [
        { alias: 'build_mode', name: 'Build Mode', timeseries: false, predefined: false, visible: true},
        { alias: 'build_time_estimate', name: 'Build Time Estimate', timeseries: false, predefined: false, visible: true , unit: {symbol:'HH:mm:ss', scale: 1}},
        { alias: 'current_height', name: 'Current Height', timeseries: false, predefined: false, visible: true, unit: {symbol: 'mm', scale: 1} },
        { alias: 'total_print_height', name: 'Total Print Height', timeseries: false, predefined: false, visible: true , unit: {symbol: 'mm', scale: 1} },
        { alias: 'build_elapsed_time', name: 'Build Elapsed Time', timeseries: false, predefined: false, visible: true , unit: {symbol:'HH:mm:ss', scale: 1}},
      ]
    }
  },
  {
    31006: {
      buildInfo: [
        { alias: 'build_mode', name: 'Build Mode', timeseries: false, predefined: false, visible: true},
        { alias: 'build_time_estimate', name: 'Build Time Estimate', timeseries: false, predefined: false, visible: true , unit: {symbol:'HH:mm:ss', scale: 1}},
        { alias: 'current_height', name: 'Current Height', timeseries: false, predefined: false, visible: true, unit: {symbol: 'mm', scale: 1} },
        { alias: 'total_print_height', name: 'Total Print Height', timeseries: false, predefined: false, visible: true , unit: {symbol: 'mm', scale: 1} },
        { alias: 'build_elapsed_time', name: 'Build Elapsed Time', timeseries: false, predefined: false, visible: true , unit: {symbol:'HH:mm:ss', scale: 1}},
      ]
    }
  },
  {
    31002: {
      buildInfo: [
        { alias: 'build_time_estimate', name: 'Build Estimated Duration', timeseries: false, predefined: false, visible: true, unit: {symbol: 'HH:mm:ss', scale: 1} },
        { alias: 'total_layers', name: 'Total Print Height', timeseries: false, predefined: false, visible: true, unit: {symbol: 'mm', scale: 1} },
        { alias: 'current_layer', name: 'Current Height', timeseries: false, predefined: false, visible: true, unit: {symbol: 'mm', scale: 1} },
        { alias: 'build_elapsed_time', name: 'Build Elapsed Time', timeseries: false, predefined: false, visible: true, unit: {symbol: 'HH:mm:ss', scale: 1} }
      ]
    }
  },
  {
    30001: {
      buildInfo: [
        { alias: 'status', name: 'Build Status', timeseries: false, predefined: false, visible: true },
        { alias: 'build_mode', name: 'Build Mode', timeseries: false, predefined: false, visible: true },
        { alias: 'build_time_estimate', name: 'Build Time Estimate', timeseries: false, predefined: false, visible: true }
      ]
    }
  },
  {
    30000: {
      buildInfo: [
        { alias: 'status', name: 'Build Status', timeseries: false, predefined: false, visible: true },
        { alias: 'build_mode', name: 'Build Mode', timeseries: false, predefined: false, visible: true },
        { alias: 'build_time_estimate', name: 'Build Time Estimate', timeseries: false, predefined: false, visible: true }
      ]
    }
  },
  {
    30005: {
      buildInfo: [
        { alias: 'status', name: 'Build Status', timeseries: false, predefined: false, visible: true },
        { alias: 'build_mode', name: 'Build Mode', timeseries: false, predefined: false, visible: true },
        { alias: 'build_time_estimate', name: 'Build Time Estimate', timeseries: false, predefined: false, visible: true }
      ]
    }
  },
  {
    32001: {
      buildInfo: [
        { alias: 'total_layers', name: 'Total Layers', timeseries: false, predefined: false, visible: true },
        { alias: 'build_mode', name: 'BUild Mode', timeseries: false, predefined: false, visible: true },
      ]
    }
  },
  {
    32002: {
      buildInfo: [
        { alias: 'total_layers', name: 'Total Layers', timeseries: false, predefined: false, visible: true },
        { alias: 'build_mode', name: 'BUild Mode', timeseries: false, predefined: false, visible: true },
      ]
    }
  },
  {
    32000: {
      buildInfo: [
        { alias: 'total_layers', name: 'Total Layers', timeseries: false, predefined: false, visible: true },
        { alias: 'build_mode', name: 'BUild Mode', timeseries: false, predefined: false, visible: true },
      ]
    }
  },
  {
    32005: {
      buildInfo: [
        { alias: 'total_layers', name: 'Total Layers', timeseries: false, predefined: false, visible: true },
      ]
    }
  },
  {
    32004: {
      buildInfo: [
        { alias: 'total_layers', name: 'Total Layers', timeseries: false, predefined: false, visible: true },
      ]
    }
  },
  {
    32003: { 
      buildInfo: [
        { alias: 'build_mode', name: 'Build Mode', timeseries: false, predefined: false, visible: true},
        { alias: 'build_time_estimate', name: 'Build Time Estimate', timeseries: false, predefined: false, visible: true , unit: {symbol:'HH:mm:ss', scale: 1}},
        { alias: 'current_height', name: 'Current Height', timeseries: false, predefined: false, visible: true, unit: {symbol: 'mm', scale: 1} },
        { alias: 'total_print_height', name: 'Total Print Height', timeseries: false, predefined: false, visible: true , unit: {symbol: 'mm', scale: 1} },
        { alias: 'build_elapsed_time', name: 'Build Elapsed Time', timeseries: false, predefined: false, visible: true , unit: {symbol:'HH:mm:ss', scale: 1}},
      ]
    }
  },
  {
    32011: {
      buildInfo: [
        { alias: 'total_layers', name: 'Total Layers', timeseries: false, predefined: false, visible: true },
      ]
    }
  },
  {
    32012: {
      buildInfo: [
        { alias: 'total_layers', name: 'Total Layers', timeseries: false, predefined: false, visible: true },
      ]
    }
  },
  {
    32013: {
      buildInfo: [
        { alias: 'total_layers', name: 'Total Layers', timeseries: false, predefined: false, visible: true },
      ]
    }
  },
  {
    20000: {
      buildInfo: [
        { alias: 'total_layers', name: 'Total Layers', timeseries: false, predefined: false, visible: true },
      ]
    }
  },
  {
    20004: {
      buildInfo: [
        { alias: 'total_layers', name: 'Total Layers', timeseries: false, predefined: false, visible: true },
      ]
    }
  },
  {
    34100: {
      buildInfo: [
        { alias: 'total_layers', name: 'Total Layers', timeseries: false, predefined: false, visible: true },
        { alias: 'current_layers', name: 'Current Layers', timeseries: false, predefined: false, visible: true },
      ]
    }
  },
  {
    30002: {
      buildInfo: [
        { alias: 'total_layers', name: 'Total Layers', timeseries: false, predefined: false, visible: true },
        { alias: 'build_mode', name: 'BUild Mode', timeseries: false, predefined: false, visible: true },
      ]
    }
  },
  {
    24000: {
      buildInfo: [
        { alias: 'total_layers', name: 'Total Layers', timeseries: false, predefined: false, visible: true },
        { alias: 'build_mode', name: 'BUild Mode', timeseries: false, predefined: false, visible: true },
      ]
    }
  },
  {
    30003: {
      buildInfo: [
        { alias: 'status', name: 'Build Status', timeseries: false, predefined: false, visible: true },
        { alias: 'build_mode', name: 'Build Mode', timeseries: false, predefined: false, visible: true },
        { alias: 'build_time_estimate', name: 'Build Time Estimate', timeseries: false, predefined: false, visible: true }
      ]
    }
  },
  {
    30004: {
      buildInfo: [
        { alias: 'status', name: 'Build Status', timeseries: false, predefined: false, visible: true },
        { alias: 'build_mode', name: 'Build Mode', timeseries: false, predefined: false, visible: true },
        { alias: 'build_time_estimate', name: 'Build Time Estimate', timeseries: false, predefined: false, visible: true }
      ]
    }
  },
  {
    34201: {
      buildInfo: [
        { alias: 'build_jobs_success_cumulative', name: 'Cumulative Successful', timeseries: false, predefined: false, visible: true },
        { alias: 'build_jobs_failed_cumulative', name: 'Cumulative Error', timeseries: false, predefined: false, visible: true },
        { alias: 'build_jobs_cancelled_cumulative', name: 'Cumulative Canceled', timeseries: false, predefined: false, visible: true },
        { alias: 'build_time_estimate', name: 'Build Time Estimate', timeseries: false, predefined: false, visible: true },
        { alias: 'total_layers', name: 'Total Layers', timeseries: false, predefined: false, visible: true },
        { alias: 'current_layers', name: 'Current Layers', timeseries: false, predefined: false, visible: true },
      ]
    }
  }
];

export const printerModelList = [
  { model: '20000', submodel: '1', officialName: 'ProJet 2500', enable: true, hasAlert: true, commonName: 'ProJet 2500/Plus/W/IC'},
  { model: '20000', submodel: '2', officialName: 'ProJet 2500 Plus', enable: true, hasAlert: true, commonName: 'ProJet 2500/Plus/W/IC'},
  { model: '20000', submodel: '123', officialName: 'ProJet 2500W', enable: true, hasAlert: true, commonName: 'ProJet 2500/Plus/W/IC'},
  { model: '20000', submodel: '125', officialName: 'ProJet 2500IC', enable: true, hasAlert: true, commonName: 'ProJet 2500/Plus/W/IC'},
  { model: '20004', submodel: '124', officialName: 'MJP 300W', enable: true, hasAlert: false, commonName: 'ProJet 300/W'},
  { model: '24000', submodel: '0', officialName: 'Brill 3D Culinary Printer', enable: false, hasAlert: true, commonName: 'Panda'},
  { model: '30000', submodel: '0', officialName: 'ProX 950	', enable: true, hasAlert: true, commonName: 'ProX 950' },
  { model: '30001', submodel: '0', officialName: 'ProX 800', enable: true, hasAlert: true, commonName: 'ProX 800' },
  { model: '30002', submodel: '0', officialName: 'ProJet 6k/7k', enable: true, hasAlert: true, commonName: 'ProJet 6k/7k' },
  { model: '30002', submodel: '1', officialName: 'ProJet 6k', enable: true, hasAlert: true, commonName: 'ProJet 6k/7k' },
  { model: '30002', submodel: '4', officialName: 'ProJet 7k', enable: true, hasAlert: true, commonName: 'ProJet 6k/7k' },
  { model: '30003', submodel: '0', officialName: 'iPro8000', enable: true, hasAlert: false, commonName: 'iPro8000' },
  { model: '30004', submodel: '0', officialName: 'iPro9000SV', enable: true, hasAlert: false, commonName: 'iPro9000SV' },
  { model: '30005', submodel: '0', officialName: 'ProX 8500', enable: true, hasAlert: true, commonName: 'ProX 8500' },
  { model: '31000', submodel: '0', officialName: 'ProX SLS 500	', enable: true, hasAlert: true, commonName: 'ProX SLS 500' },
  { model: '31001', submodel: '0', officialName: 'ProX SLS 6100', enable: true, hasAlert: true, commonName: 'ProX SLS 6100' },
  { model: '31006', submodel: '0', officialName: 'SLS 380', enable: true, hasAlert: true, commonName: 'SLS 380' },
  { model: '31002', submodel: '0', officialName: 'sPro 60', enable: true, hasAlert: true, commonName: 'sPro 60' },
  { model: '31003', submodel: '0', officialName: 'sPro 140', enable: false, hasAlert: false, commonName: 'sPro 140'},
  { model: '31004', submodel: '0', officialName: 'sPro 230', enable: false, hasAlert: false, commonName: 'sPro 230'},
  { model: '32000', submodel: '0', officialName: 'DMP 320', enable: true, hasAlert: true, commonName: 'DMP 320' },
  { model: '32001', submodel: '0', officialName: 'DMP Flex 100', enable: true, hasAlert: true, commonName: 'DMP Flex 100' },
  { model: '32002', submodel: '0', officialName: 'DMP Flex 200', enable: true, hasAlert: true, commonName: 'DMP Flex 200' },
  { model: '32003', submodel: '0', officialName: 'DMP Flex 350 Dual', enable: true, hasAlert: true, commonName: 'DMP Factory/Flex 350 Dual' },
  { model: '32003', submodel: '1', officialName: 'DMP Factory 350 Dual', enable: true, hasAlert: true, commonName: 'DMP Factory/Flex 350 Dual' },
  { model: '32004', submodel: '1', officialName: 'DMP Factory 350', enable: true, hasAlert: true, commonName: 'DMP Factory/Flex 350' },
  { model: '32004', submodel: '0', officialName: 'DMP Flex 350', enable: true, hasAlert: true, commonName: 'DMP Factory/Flex 350' },
  { model: '32005', submodel: '0', officialName: 'DMP Factory 500', enable: true, hasAlert: true, commonName: 'DMP Factory 500' },
  { model: '32006', submodel: '0', officialName: 'DMP F500 DPM', enable: true, hasAlert: true , commonName: 'DMP F500 DPM'},
  { model: '32007', submodel: '0', officialName: 'DMP F500 PAM', enable: true, hasAlert: true, commonName: 'DMP F500 PAM' },
  { model: '32008', submodel: '0', officialName: 'DMP F500 PRM', enable: true, hasAlert: true, commonName: 'DMP F500 PRM' },
  { model: '32011', submodel: '0', officialName: 'DMP ProX 100', enable: true, hasAlert: true, commonName: 'DMP ProX 100' },
  { model: '32012', submodel: '0', officialName: 'DMP ProX 200', enable: true, hasAlert: true, commonName: 'DMP ProX 200' },
  { model: '32013', submodel: '0', officialName: 'DMP ProX 300', enable: true, hasAlert: true, commonName: 'DMP ProX 300' },
  { model: '34000', submodel: '0', officialName: 'NextDent 5100', enable: true, hasAlert: false, commonName: 'Fig4 AG/NextDent 5100' },
  { model: '34000', submodel: '2', officialName: 'Fig4 AG', enable: true, hasAlert: false, commonName: 'Fig4 AG/NextDent 5100' },
  { model: '34100', submodel: '0', officialName: 'Figure 4 Standalone', enable: true, hasAlert: false, commonName: 'Figure 4 Standalone/Jewelry' },
  { model: '34100', submodel: '0', officialName: 'Fig4 Jewelry', enable: true, hasAlert: false, commonName: 'Figure 4 Standalone/Jewelry' },
  { model: '34200', submodel: '0', officialName: 'Figure 4 Modular Workcell Controller', enable: true, hasAlert: false, commonName: 'Figure 4 Modular Workcell Controller' },
  { model: '34201', submodel: '0', officialName: 'Figure 4 Modular Print Station', enable: true, hasAlert: false, commonName: 'Figure 4 Modular Print Station' },
  { model: '32002', submodel: '0', officialName: 'PostCure 1050', enable: true, hasAlert: true, commonName: 'PostCure 1050' },
];

export const printerModelList2 = [
  { fullModel: undefined, officialName: '--- None ---', enable:true, noindent:true},
  { fullModel: undefined, officialName: 'Printer Technology', enable:true, noindent:true, catTitle:true, genericModel: true },
  { fullModel: '20', officialName: 'MJP', enable: true, genericModel: true  },
  { fullModel: '24', officialName: 'CJP', enable: true, genericModel: true  },
  { fullModel: '30', officialName: 'SLA', enable: true, genericModel: true  },
  { fullModel: '31', officialName: 'SLS', enable: true, genericModel: true  },
  { fullModel: '32', officialName: 'DMP', enable: true, genericModel: true  },
  { fullModel: '34', officialName: 'Figure 4', enable: true, genericModel: true  },
  { fullModel: undefined, officialName: 'Printer Model', enable:true, noindent:true, catTitle:true, genericModel: true },
  { fullModel: '20000-1', officialName: 'ProJet 2500', enable: true },
  { fullModel: '20000-2', officialName: 'ProJet 2500 Plus', enable: true },
  { fullModel: '20000-123',officialName: 'ProJet 2500W', enable: true },
  { fullModel: '20000-125', officialName: 'ProJet 2500IC', enable: true },
  { fullModel: '20004-124', officialName: 'MJP 300W', enable: true },
  { fullModel: '24000-1', officialName: 'Brill 3D Culinary Printer', enable: true, group:true },
  { fullModel: '30000-0', officialName: 'ProX 950	', enable: true },
  { fullModel: '30001-0', officialName: 'ProX 800', enable: true },
  { fullModel: '30002-0', officialName: 'ProJet 6k/7k', enable: true },
  { fullModel: '30002-1', officialName: 'ProJet 6k', enable: true },
  { fullModel: '30002-4', officialName: 'ProJet 7k', enable: true },
  { fullModel: '30003-0', officialName: 'iPro8000	', enable: true },
  { fullModel: '30004-0', officialName: 'iPro9000SV', enable: true },
  { fullModel: '30005-0', officialName: 'ProX 8500', enable: true },
  { fullModel: '31000-0', officialName: 'ProX SLS 500	', enable: true },
  { fullModel: '31001-0', officialName: 'ProX SLS 6100', enable: true },
  { fullModel: '31006-0', officialName: 'SLS 380', enable: true },
  { fullModel: '31002-0', officialName: 'sPro 60', enable: true },
  { fullModel: '31003-0', officialName: 'sPro 140', enable: false },
  { fullModel: '31004-0', officialName: 'sPro 230', enable: false },
  { fullModel: '32000-0', officialName: 'DMP 320', enable: true },
  { fullModel: '32001-0', officialName: 'DMP Flex 100', enable: true },
  { fullModel: '32002-0', officialName: 'DMP Flex 200', enable: true },
  { fullModel: '32003-0', officialName: 'DMP Flex 350 Dual', enable: true },
  { fullModel: '32003-1', officialName: 'DMP Factory 350 Dual', enable: true },
  { fullModel: '32004-1', officialName: 'DMP Factory 350', enable: true },
  { fullModel: '32004-0', officialName: 'DMP Flex 350', enable: true },
  { fullModel: '32005-0', officialName: 'DMP Factory 500', enable: true },
  { fullModel: '32006-0', officialName: 'DMP F500 DPM', enable: true },
  { fullModel: '32007-0', officialName: 'DMP F500 PAM', enable: true },
  { fullModel: '32008-0', officialName: 'DMP F500 PRM', enable: true },
  { fullModel: '32011-0', officialName: 'DMP ProX 100', enable: true },
  { fullModel: '32012-0', officialName: 'DMP ProX 200', enable: true },
  { fullModel: '32013-0', officialName: 'DMP ProX 300', enable: true },
  { fullModel: '34000-0', officialName: 'NextDent 5100', enable: true },
  { fullModel: '34000-2', officialName: 'Fig4 AG', enable: true },
  { fullModel: '34100-0', officialName: 'Figure 4 Standalone', enable: true },
  { fullModel: '34100-3', officialName: 'Fig4 Jewelry', enable: true },
  { fullModel: '34200-0', officialName: 'Figure 4 Modular Workcell Controller', enable: true },
  { fullModel: '34201-0', officialName: 'Figure 4 Modular Print Station', enable: true },
  { fullModel: undefined, officialName: 'Curing Model', enable:true, noindent:true, catTitle:true, genericModel: true },
  { fullModel: '60000-0', officialName: 'PostCure 1050', enable: true }
];


export const SFRegions = ['Americas', 'EMEA', 'APJ'];

export const AllStates = ['print initializing error aborting', 'print initializing error aborted', 'print error aborting', 'print error aborted', 'print cancel aborting', 'print cancel aborted', 'unknown', 'idle error', 'shutdown', 'print complete', 'printing', 'print paused', 'idle', 'disconnected', 'print initializing', 'print finalizing', 'maintenance'];
export const PrinterStateMappings = [
  { error: ['print initializing error aborting', 'print initializing error aborted', 'print error aborting', 'print error aborted', 'print cancel aborting', 'print cancel aborted', 'unknown', 'idle error', 'shutdown'] },
  { 'print complete': ['print complete'] },
  { printing: ['printing', 'print paused'] },
  { idle: ['idle'] },
  { disconnected: ['disconnected'] },
  { 'initializing/ finalizing': ['print initializing', 'print finalizing'] },
  { maintenance: ['maintenance'] }
];

export const DefaultPrinterStatePreference = [
  { value: 'printing', key: 'printing' },
  { value: 'print complete', key: 'print complete' },
  { value: 'idle', key: 'idle' },
  { value: 'error', key: 'error' },
  { value: 'disconnected', key: 'disconnected' },
  { value: 'initializing/ finalizing', key: 'initializaing/finalizing'},
  { value: 'maintenance', key: 'maintenance' }
];

export const user_types = {
  super_admin: 'super_admin',
  admin: 'admin',
  group_owner: 'group_owner',
  user: 'user'
};

// export const PrinterStateColorMappings = [
//   {"#ed684a": },
//   {"#f2b927":  },
//   {"#a2a2a2": [31]},
//   {"#eeeeee": [34,36]},
//   {"#09aec6": [35,38]}
// ]

// export const PrinterStateColorMappings = [
//  {
//    printerState : [0, 30, 32, 33, 39, 40, 41, 42, 43, 44],
//    label : 'Error',
//    color: '#ed684a'
//  },
//  {
//   printerState : [1],
//   label : 'Disconnected',
//   color: '#f2b927'
// },
// {
//   printerState : [31,45],
//   label : 'Idle',
//   color: '#a2a2a2'
// },
// {
//   printerState : [34,36],
//   label : 'Initializing/Finalizing',
//   color: '#eeeeee'
// },
// {
//   printerState : [35,38],
//   label : 'Printing',
//   color: '#09aec6'
// }
// ]

export const PrinterStateColorMappings = [
  {
    printerState: 0,
    label: 'Unknown ',
    color: '#ed684a'
  },
  {
    printerState: 0,
    label: 'Unknown (Default)',
    color: '#ed684a'
  },
  {
    printerState: 30,
    label: 'Unknown',
    color: '#ed684a'
  },
  {
    printerState: 32,
    label: 'Idle Error',
    color: '#ed684a'
  },
  {
    printerState: 33,
    label: 'Shutdown',
    color: '#ed684a'
  },
  {
    printerState: 39,
    label: 'Print Initializing Error Aborting',
    color: '#ed684a'
  },
  {
    printerState: 40,
    label: 'Print Initializing Error Aborted',
    color: '#ed684a'
  },
  {
    printerState: 41,
    label: 'Print Error Aborting',
    color: '#ed684a'
  },
  {
    printerState: 42,
    label: 'Print Error Aborted',
    color: '#ed684a'
  },
  {
    printerState: 43,
    label: 'Print Cancel Aborting',
    color: '#ed684a'
  },
  {
    printerState: 44,
    label: 'Print Cancel Aborted',
    color: '#ed684a'
  },
  {
    printerState: 1,
    label: 'Disconnected',
    color: '#f2b927'
  },
  {
    printerState: 31,
    label: 'Idle',
    color: '#a2a2a2'
  },
  {
    printerState: 45,
    label: 'Maintenance',
    color: '#a2a2a2'
  },
  {
    printerState: 34,
    label: 'Print Initializing',
    color: '#eeeeee'
  },
  {
    printerState: 36,
    label: 'Print Finalizing',
    color: '#eeeeee'
  },
  {
    printerState: 35,
    label: 'Printing',
    color: '#09aec6'
  },
  {
    printerState: 38,
    label: 'Print Paused',
    color: '#09aec6'
  },
  {
    printerState: 37,
    label: 'Print Complete',
    color: '#3ec556'
  }
];

export const PrintersListForMaterialSection = ['30000', '30001', '30002', '32000', '32001', '32002',
  '32004', '32005', '32011', '32012', '32013', '34000', '34100', '34201'];

export const AggregateInterval = [
  {
    name: 'Seconds',
    options: [
      { value: '15_seconds', viewValue: '15 seconds' },
      { value: '30_seconds', viewValue: '30 seconds' },
      { value: '45_seconds', viewValue: '45 seconds' }
    ]
  },
  {
    name: 'Minutes',
    options: [
      { value: '1_minutes', viewValue: '1 minute' },
      { value: '5_minutes', viewValue: '5 minutes' },
      { value: '10_minutes', viewValue: '10 minutes' },
      { value: '15_minutes', viewValue: '15 minutes' },
      { value: '45_minutes', viewValue: '45 minutes' }
    ]
  },
  {
    name: 'Hours',
    options: [
      { value: '1_hours', viewValue: '1 hour' },
      { value: '2_hours', viewValue: '2 hours' },
      { value: '5_hours', viewValue: '5 hours' },
      { value: '10_hours', viewValue: '10 hours' }
    ]
  },
  {
    name: 'Days',
    options: [
      { value: '1_days', viewValue: '1 day' },
      { value: '2_days', viewValue: '2 days' },
      { value: '5_days', viewValue: '5 days' },
      { value: '10_days', viewValue: '10 days' }
    ]
  }
];

export const printerStateOverlayProperty = 'printer_status_log';
