import { Component, OnInit } from '@angular/core';
import { SvgBaseComponent } from '../../svgbase.component';

@Component({
  selector: 'pause-icon',
  templateUrl: './pause.component.html',
  styleUrls: ['./pause.component.sass']
})
export class PauseComponent extends SvgBaseComponent {
  ngOnInit() {
  }

}
