import { Component } from '@angular/core';
import { SvgBaseComponent } from '../../svgbase.component';

@Component({
  selector: 'connected',
  templateUrl: './connected.component.html',
  styleUrls: ['./connected.component.scss']
})
export class ConnectedComponent extends SvgBaseComponent {

}
