import { ErrorHandler, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';
import { AlertMessage } from '../models/alertMessage';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    objAlertMessage: AlertMessage;
    constructor(
        private translateService: TranslateService,
        private matSnackBar: MatSnackBar
    ) {
        this.objAlertMessage = new AlertMessage(this.translateService, this.matSnackBar);
    }

    handleError(error) {
        let errorObj = (error.rejection) ? error.rejection : null;
        let message = '';
        let action = null;
        let duration = null;
        let additionalMessage = "";

        if (!navigator.onLine) {
            message = 'No Internet Connection';
        } else if (errorObj) {
            duration = 5000;
            switch(errorObj.status) {
                case 400: message = this.processError400(errorObj.data); break;
                // (errorObj.data && errorObj.data.app_error) ?  errorObj.data.app_error : "error_message"; break;
                case 403: message = (errorObj.data && errorObj.data.app_error) ?  errorObj.data.app_error : "no_permission"; break;
                case 500: {
                    message = (errorObj.data && errorObj.data.app_error) ?  errorObj.data.app_error : "error_message";                    
                    if (errorObj.data.message) {
                        additionalMessage = errorObj.data.message;
                    }
                    break;
                }
                case 409: message = "error_duplicated"; break;
                case 503: message = "service_unavailable"; break;
                default: message = "error_message"; break;
            }
        } else {
            duration = 4000;
            message = error.message ? error.message : error.toString();
        }
        console.log(error);
        this.objAlertMessage.showError(message, action, duration, additionalMessage);
    }

    processError400(errorObj) {
        let retMsg = '';

        if (errorObj) {
            if (errorObj.app_error) {
                retMsg = errorObj.app_error;
            } else if (errorObj.status) {
                retMsg = this.processNormalizeErrorStatusMessage(errorObj.status);
            } else {
                retMsg = 'error_message';
            }
        }
        return retMsg;
    }

    processNormalizeErrorStatusMessage(message) {
        let message1 = { ...message };
        message1 = message.replace(/ /g, '_').toLowerCase();

        if (message1.startsWith('adapter') && message1.includes('does_not_exist')) {
            return 'adapter_does_not_exist';
        }
        if (message1.startsWith('operation') && message1.includes('does_not_exist')) {
            return 'operation_does_not_exist';
        } else {
            return message1;
        }
    }
}