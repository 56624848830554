import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, Output, EventEmitter, SimpleChanges, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ChartType, ChartOptions, Chart } from 'chart.js';
import { SingleDataSet, Label, Color } from 'ng2-charts';
import { reduce } from 'rxjs/operators';
import * as zoomPlugin from 'chartjs-plugin-zoom';
import { NotifyService } from 'src/app/services/notify.service';
import { Subscription } from 'rxjs';
import { AggregateInterval, printStatusColorReference } from 'src/app/models/constants/constants'
import { AngularCsv } from 'angular7-csv';
import { BaseChartDirective } from 'ng2-charts';
import { isArray } from 'util';
import { isNullOrUndefinedOrEmpty } from 'src/app/functions/common-functions';

@Component({
  selector: 'chartjs',
  templateUrl: './dynamic-chartjs.component.html',
  styleUrls: ['./dynamic-chartjs.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartjsComponent implements OnChanges, AfterViewInit, OnInit {
  @Input() property1;
  @Input() options;
  @Input() secondaryFilters;
  @Input() view;
  @Input() showToolbar = true;
  @Output('eventEmit') eventEmit = new EventEmitter();
  objMinDate: any;
  objMaxDate: any;
  objStartMaxDate: any;
  oldChartBackgroundColor: any = [];
  zoomFunction: any;
  strPrinterTz: string;
  blnExportDataConvertedToPrinterTime: boolean = false;
  constructor(private notifyService: NotifyService,
    protected changeDetectorRef: ChangeDetectorRef) { }

  numLoadPercentage: number = 0; //added by andy for prod build
  MAX_POINTS_TO_RENDER = 50000;
  totalChartDataPoints: number = 0;

  @ViewChild('myCanvas') myCanvas: BaseChartDirective;
  objChartOptions: any;
  startDate: any;
  endDate: any;
  blnShowdatepicker: boolean = false;
  selected: string = 'default';
  selectedView: string = 'default';
  selectedInterval: string= '1_hours';
  viewOptions = ['default', 'aggregated'];
  agrregateOptions = AggregateInterval;
  blnUpdateChart: boolean = false;
  arrprinterStatusReference: object[] = printStatusColorReference;
  blnShowPrinterStateLegend: boolean = false;

  public canvas: ElementRef;
  public context: CanvasRenderingContext2D;
  dataChangeSubscription: Subscription;
  public chartType: string = 'line';
  public chartData: any[];
  public chartLabels: any[];
  public chartColors: any[];
  public chartOptions: any;
  public chartPlugins: any;
  @ViewChild(BaseChartDirective) baseChartDir: BaseChartDirective;

  detectChanges() {
      if (!this.changeDetectorRef['destroyed']) {
          this.changeDetectorRef.markForCheck();
          this.changeDetectorRef.detectChanges();
      }
  }


  ngAfterViewInit() {
    // this.createGradient();
  }

  ngOnInit() {
    this.selectedInterval = this.determineInterval();
  }

  ngOnDestroy() {
    if (this.dataChangeSubscription) { this.dataChangeSubscription.unsubscribe(); }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.blnExportDataConvertedToPrinterTime = false;
    this.blnUpdateChart = true;
    this.selectedView = this.view;
    var unit = this.property1[0].YUnit;
    this.objChartOptions = this.determineChartOptions(); // this.options.dropdown;
    this.setStartEndTime();
    this.chartData = this.restrictDataPoints(this.property1);
    this.strPrinterTz = this.chartData['timeZone'] ? this.chartData['timeZone'] : "";
    this.selected = (this.options.selected) ? this.options.selected : "default";
    this.chartOptions = {

      title: {
        text: 'Chart.js Time Scale'
      },
      scales: {
        xAxes: [{
          type: 'time',
          distribution: 'series',
          time: {
            tooltipFormat: 'YYYY-MM-DD[T]HH:mm:ss' + ((this.strPrinterTz && this.strPrinterTz != "") ? this.strPrinterTz : "+00:00"),
            unit: 'hour',
            displayFormats: {
              hour: 'MMM D, h:mm A'
            },
          },
          scaleLabel: {
            display: true,
            labelString: 'Timestamp'
          }
        }],
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: unit || 'value'
          }
        }]
      },
      plugins: {
        zoom: {
          pan: {
            enabled: false,
            drag: false,
            mode: "x",
            limits: {
              max: 10,
              min: 0.5
            }
          },
          zoom: {
            enabled: true,
            drag: false,
            mode: 'x',
            speed: 0.01,
            threshold: 3,
            // onZoomComplete: (chart) => { this.zoomFunction(); }
            onZoom: (chart) => { this.zoomFunction(); }
          },
        }
      },
    };

    this.chartPlugins = [zoomPlugin];

    this.detectChanges();
    if (this.blnUpdateChart) {
      this.blnUpdateChart = false;
      this.createGradient();
    }
  }

  determineChartOptions() {
    let options: any;
    if (isNullOrUndefinedOrEmpty(this.options.defaultView) && this.options.defaultView) {
      options = AggregateInterval;
    } else {
      options = this.options.dropdown;
    }
    return options;
  }

  determineInterval() {
    if (this.options.defaultInterval) {
      return this.options.defaultInterval;
    } else {
      return '1_hours';
    }
  }

  setStartEndTime() {
    this.startDate = this.options.start || new Date();
    this.endDate = this.options.end || new Date();
    this.objMinDate = this.startDate;
    this.objMaxDate = this.endDate;
    this.objStartMaxDate = this.endDate;
  }

  dateChange(event, type) {
    switch (type) {
      case 0: this.startDate = new Date(event.value); break;
      default: this.endDate = new Date(event.value); break;
    }
    this.objMinDate = this.startDate;
    this.objMaxDate = new Date();
    this.objStartMaxDate = this.endDate;
  }

  onSubmitRange() {
    let obj = {
      'startDate': this.startDate,
      'endDate': this.endDate
    };
    if (this.selectedView == 'default') {
      if (this.property1[0].printerId)
        obj['printerId'] = this.property1[0].printerId;
      if (!isNaN(this.property1[0].index))
        obj['index'] = this.property1[0].index;
      if (this.property1[0].label)
        obj['uuid'] = this.property1[0].label;
      this.eventEmit.emit(obj);
    }
    else {
      let obj = { 'startDate': this.startDate, 'endDate': this.endDate, 'fixed_interval': this.selectedInterval }
      if (this.property1[0].printerId)
        obj['printerId'] = this.property1[0].printerId;
      if (!isNaN(this.property1[0].index))
        obj['index'] = this.property1[0].index;
      if (this.property1[0].label)
        obj['uuid'] = this.property1[0].label;
      this.eventEmit.emit(obj);
    }

  }

  onOptionChange(key) {
    if (key.value == 'custom') {
      this.blnShowdatepicker = true;
    } else {
      // console.log(this.property1[0].printerId)
      if (this.property1[0].printerId) {
        let obj = { 'option': key.value }
        if (this.property1[0].printerId)
          obj['printerId'] = this.property1[0].printerId;
        if (!isNaN(this.property1[0].index))
          obj['index'] = this.property1[0].index;
        if (this.property1[0].label)
          obj['uuid'] = this.property1[0].label;
        this.eventEmit.emit(obj);
      }
      else
        this.eventEmit.emit(key.value);
      this.blnShowdatepicker = false;
    }
  }

  onIntervalChange(key) {
    this.selectedInterval = key.value;
  }

  onViewChange(key) {
    this.selectedView = key.value;
  }

  toggleDurationToCustom() {
    if (this.objChartOptions.findIndex(x => x.key == 'custom') > -1) {
      this.selected = "custom";
    }
  }

  export() {
    let export_data = this.chartData['exportObject'];
    let timeZone = this.strPrinterTz ? this.strPrinterTz : "+00:00";
    if (export_data) {
      if (timeZone && timeZone != "" && !this.blnExportDataConvertedToPrinterTime) {
        if (export_data.exportData && isArray(export_data.exportData)) {
          export_data.exportData = export_data.exportData.map(x => {
            let obj = { ...x };
            if (obj.timestamp) obj.timestamp += timeZone;
            if (obj.x) obj.x += timeZone;
            return obj;
          });
          this.blnExportDataConvertedToPrinterTime = true;
        }
      }
      new AngularCsv(export_data.exportData, export_data.exportFileName, export_data.exportOptions);
    }
  }

  createGradient() {
    this.zoomFunction = this.createGradient.bind(this);
    this.chartData.forEach((element, index) => {
      if (element.showOverlay) {
        this.blnShowPrinterStateLegend = true;
        var roundNumber = function (num, scale) {
          var number = Math.round(num * Math.pow(10, scale)) / Math.pow(10, scale);
          if (num - number > 0) {
            return (number + Math.floor(2 * Math.round((num - number) * Math.pow(10, (scale + 1))) / 10) / Math.pow(10, scale));
          } else {
            return number;
          }
        };

        var convertHex = function(hex) {
          try {
            hex = hex.replace('#','');
            let r = parseInt(hex.substring(0,2), 16);
            let g = parseInt(hex.substring(2,4), 16);
            let b = parseInt(hex.substring(4,6), 16);
            let result = 'rgba(' + r + ',' + g + ',' + b + ',' + 0.65 + ')';
            return result;
          } catch (ex) {
            console.log(hex);
          }
        }

        let backgroundColors = [];
        if (isArray(element.backgroundColor)) {
          this.oldChartBackgroundColor = [...element.backgroundColor];
          backgroundColors = element.backgroundColor;
        } else {
          backgroundColors = [...this.oldChartBackgroundColor];
        }
      
        if (backgroundColors && backgroundColors.length > 0) {
          var vm = this.myCanvas.chart.config.data.datasets[index]['_meta'][Object.keys(this.myCanvas.chart.config.data.datasets[index]['_meta'])[0]].dataset._view
          
          var points = this.myCanvas.chart.config.data.datasets[index]['_meta'][Object.keys(this.myCanvas.chart.config.data.datasets[index]['_meta'])[0]].dataset._children;
          
          var ctx = this.myCanvas.chart.ctx;

          if (points.length > 0) {
            var minX = points[0]._model.x;
            var maxX = points[points.length - 1]._model.x;

            if (!isNaN(minX) && !isNaN(maxX)) {
              var linearGradient = ctx.createLinearGradient(minX, 0, maxX, 0);
    
              points.forEach(function (point, i) {
                var colorStopPosition = roundNumber((point._model.x - minX) / (maxX - minX), 2);
                if (i === 0) {
                  linearGradient.addColorStop(0, backgroundColors[i]);
                } else {
                  if (backgroundColors[i] !== backgroundColors[i - 1]) {
                    linearGradient.addColorStop(colorStopPosition, convertHex(backgroundColors[i - 1]) || '#ed684a');
                    linearGradient.addColorStop(colorStopPosition, convertHex(backgroundColors[i]) || '#ed684a');
                  }
                }
              });
              linearGradient.addColorStop(1, convertHex(backgroundColors[points.length - 1]) || '#ed684a');

              vm.backgroundColor = linearGradient;
      
              this.myCanvas.chart.config.data.datasets[index].backgroundColor = linearGradient;
              this.myCanvas.chart.update();
            }
            
          }
        }
      }
    });
  }

  restrictDataPoints(chartData: any) {
    this.totalChartDataPoints = chartData[0].data.length;
    if (chartData[0].data.length <= this.MAX_POINTS_TO_RENDER) return chartData;
    chartData[0].data = chartData[0].data.slice(0, this.MAX_POINTS_TO_RENDER);
    return chartData;
  }
}
