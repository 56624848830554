import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnderConstructionComponent } from 'src/app/components/under-construction/under-construction.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  { path: 'login', loadChildren: './modules/feature-modules/login/login.module#LoginModule' },
  { path: 'signup', loadChildren: './modules/feature-modules/signup/signup.module#SignupModule' },
  { path: 'dashboard', loadChildren: './modules/feature-modules/dashboard/dashboard.module#DashboardModule'},
  { path: 'admin', loadChildren: './modules/feature-modules/admin/admin.module#AdminModule'},
  { path: 'profile', loadChildren: './modules/feature-modules/profile/profile.module#ProfileModule'},
  { path: 'printers', loadChildren: './modules/feature-modules/printers/printers.module#PrintersModule' },
  //{ path: 'admin', component: UnderConstructionComponent },
  //{ path: 'profile', component: UnderConstructionComponent },
  //{ path: 'dashboard', component: UnderConstructionComponent },
  { path: '**', redirectTo:'/dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
