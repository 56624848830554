import { Component, OnInit, Output, EventEmitter, Inject, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/services/notify.service';
import { LoaderService } from '../../../services/loader.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-custom-grid-modal',
  templateUrl: './custom-grid-modal.component.html',
  styleUrls: ['./custom-grid-modal.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomGridModalComponent implements OnInit {
  numPageSize: number = 5;
  numPageNumber: number = 0;
  arrDisplayColumn: any = [] = ['timestamp', 'value'];
  strTitle: string;
  arrDataSortableCloumns: any = [];
  objData: any = [];
  startDate: Date;
  endDate: Date;
  objMinDate: Date;
  objMaxDate: Date;
  objStartMaxDate: Date;
  blnJobsLoading: boolean = false;
  dateRangeDisplay: boolean = true;
  objCustomGridDataSubscription: Subscription;

  @ViewChild('sDate') eleStartDate: ElementRef;

  constructor(public dialogRef: MatDialogRef<CustomGridModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notifyService: NotifyService,
    private loaderService: LoaderService) { }

  @Output('eventEmitter') eventEmitter = new EventEmitter(false);

  ngOnInit() {
    if (this.data.flag) {
      this.dateRangeDisplay = false;
    }
    if (this.data.columnsToDisplay) {
      this.arrDisplayColumn = this.data.columnsToDisplay;
    }
    this.blnJobsLoading = true;
    this.strTitle = (this.data.title).replace(/_/g, " ");
    this.endDate = this.data.endDate || new Date();
    this.startDate = this.data.startDate || (new Date(this.endDate.getTime() - (2 * 24 * 60 * 60 * 1000)));

    this.objMinDate = this.startDate;
    this.objMaxDate = this.endDate;
    this.objStartMaxDate = this.endDate;


    if (!isNullOrUndefined(this.data.flag)) {
      this.dateRangeDisplay = this.data.flag;
      this.blnJobsLoading = false;
    }
    
    if (this.data.data && this.data.data.length > 0 && this.data.data[0] != null) {
      if (this.data.data[0].startDate) this.startDate = new Date(this.data.data[0].startDate);
      this.setData();
      if (!(this.dateRangeDisplay)) {
        this.objData.push(this.data.data[0]);
      }
      else if (this.data.data.length > 0) {
        this.objData = [ ...this.data.data ];
      }
      else if (this.data.data[0].rows.length > 0) {
        this.objData.push(this.data.data[0].rows);
      }
      this.objCustomGridDataSubscription = this.notifyService.customGridDataChange.subscribe(data => {
        this.objData = [];
        if (data && data.length > 0) {
          this.objData.push(data);
        }
      });
      this.blnJobsLoading = false;
    }
  }

  ngAfterViewInit() {
    this.eleStartDate.nativeElement.focus();
  }

  onButtonClick(msg) {
    this.dialogRef.close(msg);
  }

  onFilterApply() {
    let arrData = {
      start: this.startDate,
      end: this.endDate
    }
    this.eventEmitter.emit(arrData);
  }

  dateChange(event, type) {
    switch (type) {
      case 0: this.startDate = new Date(event.value); break;
      default: this.endDate = new Date(event.value); break;
    }
    this.objMinDate = this.startDate;
    this.objMaxDate = new Date();
    this.objStartMaxDate = this.endDate;
  }

  setData() {
    if (this.dateRangeDisplay) {
      this.arrDataSortableCloumns = ['timestamp', 'value'];
    }
  }

  changeMaxEndTime() {
    this.objMaxDate = new Date();
    this.objStartMaxDate = this.endDate;
  }

}


