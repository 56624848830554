import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PrintersService } from 'src/app/services/printers.service';
import { PrinterModels } from 'src/app/models/constants/constants';
import { BaseClass } from 'src/app/models/baseClass';
import { NotifyService } from 'src/app/services/notify.service';
import { TranslateService } from '@ngx-translate/core';
import { OrderByPipe } from 'src/app/pipes/common-pipes';
import { isNullOrUndefined } from 'util';
import { LoaderService } from 'src/app/services/loader.service';
import { sortMaintenanceTasks, convertUTCtoPrinterTimezone, preparePrintertimeZoneOffset } from 'src/app/functions/common-functions';

@Component({
  selector: 'app-prev-maint-modal',
  templateUrl: './prev-maint-modal.component.html',
  styleUrls: ['./prev-maint-modal.component.scss']
})
export class PrevMaintModalComponent extends BaseClass {
  arrPrevMaintData: any[] = [];
  cols: any = ["maintenance_task_id", "maintenance_task_description", "due_time", "time_unit", "operator_type", "last_executed_date"];

  constructor(public dialogRef: MatDialogRef<PrevMaintModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string[],
    private printerService: PrintersService, protected changeDetectorRef: ChangeDetectorRef,
    protected notifyService: NotifyService,
    protected translateService: TranslateService,
    private orderByPipe: OrderByPipe, private loader: LoaderService) {
    super(changeDetectorRef, notifyService, translateService);
  }

  ngOnInit() {
    for (const key in this.data) {
      if (this.data.hasOwnProperty(key)) {
        const element: any = this.data[key];
        element.forEach(printer => {
          let printerId = printer.model + '-' + printer.serial_no;
          if (printerId) {
            this.printerService.getAWSPrinter(printerId, false).then(res => {
              let printerDetails = this.printerService.getPrinterDetailsFromCache(printerId);
              this.getMaintenanceTasks(printer, printerId, printerDetails);
            });
          }
        });
      }
    }
  }

  getMaintenanceTasks(printer, printerId, printerDetails) {
    switch (printer.model) {
      case PrinterModels.DMP_300:
      case PrinterModels.Dmp_100:
      case PrinterModels.Dmp_200:
      case PrinterModels.Dmp_320:
      case PrinterModels.Dmp_350:
      case PrinterModels.Dmp_500:
      case PrinterModels.Dmp_Flex100:
      case PrinterModels.Dmp_Flex200:
        {
          this.loader.show();
          this.printerService.getSpecificSensorDetails2(printerId, "maintenance_schedule_tasks").then(res => {
            let maint_tasks = [];
            try {
              let raw_timezone = printerDetails['timeZone'];
              let prepared_timeZone = preparePrintertimeZoneOffset(printerDetails['timeZone']);
              if (res.body.timezoneInfo) {
                raw_timezone = res.body.timezoneInfo['maintenance_schedule_tasks'] ? res.body.timezoneInfo['maintenance_schedule_tasks'] : printerDetails['timeZone'];
                prepared_timeZone = res.body.timezoneInfo['maintenance_schedule_tasks'] ? preparePrintertimeZoneOffset(res.body.timezoneInfo['maintenance_schedule_tasks']) : preparePrintertimeZoneOffset(printerDetails['timeZone']);
              }
              if (!isNullOrUndefined(res.body.rows[0].maintenance_schedule_tasks)) {
                maint_tasks = res.body.rows[0].maintenance_schedule_tasks['data'];
                maint_tasks.map(x => {
                  x.time_unit = !isNullOrUndefined(x.time_unit) ? x.time_unit : "";
                  x.due_time = !isNullOrUndefined(x.due_time) ? x.due_time : "";
                  x.last_executed_date = !isNullOrUndefined(x.last_executed_date) ? (convertUTCtoPrinterTimezone(new Date(x.last_executed_date * 1000).toISOString(), raw_timezone) + prepared_timeZone) : 0;
                  return x;
                });
                let sorted_arr = sortMaintenanceTasks(maint_tasks);
                this.arrPrevMaintData.push({ printer: printerId, data: sorted_arr });
              }
              else this.arrPrevMaintData.push({ printer: printerId, data: undefined });
            } catch(e) {
              console.log(e);
              this.arrPrevMaintData.push({ printer: printerId, data: undefined });
            }
            this.loader.hide();
            this.detectChanges();
          });
          break;
        }
      default: this.arrPrevMaintData.push({ printer: printerId, data: undefined }); this.detectChanges();
    }
  }

  onButtonClick() {
    this.dialogRef.close();
  }

  detectChanges() {
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    }
  }
}
