import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UsersService } from '../services/users.service';
import { Routes } from '../models/constants/constants';
import { AlertMessage } from 'src/app/models/alertMessage';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';


@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
    objAlertMessage: AlertMessage;
    constructor(
        private router: Router,
        private userService: UsersService,
        translateService: TranslateService,
        private snackBar: MatSnackBar
    ) {
        this.objAlertMessage = new AlertMessage(translateService, this.snackBar);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.userService.getCurrentUserAclData().then(res => {
            if(res.admin == 'True' || eval(res.group_owner_of).length > 0){
                if(state.url.startsWith('/admin/fleet/adapters/provisioning')){
                    if(res.superadmin == "True"){
                        return true;
                    }else{
                        this.objAlertMessage.showError("admin_auth_need_adapters_provisioning", null, 5000);
                        this.router.navigate([Routes.fleet_adapters_basic]);
                        return false;
                    }
                }else{
                    return true;
                }
            } else if(state.url.startsWith('/admin')) {
                // this.objAlertMessage.showError("admin_or_owner_auth_need", null, 5000);
                this.router.navigate([Routes.fleet_printers]);
                return false;
            } else {
                this.router.navigate([Routes.dashboard]);
                return false;
            }
            // let data = res.acl;
            // if (data.is_admin || (data.groups.write.length > 0))
            //     return true;
            // else {
            //     this.router.navigate(['/dashboard']);
            //     return false;
            // }
        }).catch(() => {
            this.router.navigate(['/dashboard']);
            return false;
        });
    }
}