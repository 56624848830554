import { Pipe, PipeTransform } from '@angular/core';
import { svgMap, HeaderMap, cardTexts, printerCardTexts } from '../models/constants/constants';
import { isNullOrUndefined } from 'util';

@Pipe({
    name: 'svgMapPipe',
    pure: true
})
export class SvgMapPipe implements PipeTransform {
    transform(key: string) {
        return svgMap[key];
    }
}

@Pipe({
    name: 'headerTransformPipe',
    pure: true
})
export class HeaderTransformPipe implements PipeTransform {
    transform(key: string){
        return (HeaderMap[key] != undefined) ? HeaderMap[key] : key;
    }
}

@Pipe({
  name: 'filterUser'
})
export class FilterUserPipe implements PipeTransform {

  transform(arr: any[], value: string): any {
    if (arr) {
      if (!value) {
        return arr
      } else {
        return arr.filter(obj => this.filter(obj,value))
      }
    } else {
      return []
    }
  }

  filter(source :any, target :string) : boolean {
     return cardTexts.some(x=>{
       let returnVal = (source[x]) ? source[x].toLowerCase().includes(target.toLowerCase()) : false;
       return returnVal;
     });
  }
}

@Pipe({
  name: 'filterPrinter'
})
export class FilterPrinterPipe implements PipeTransform {

  transform(arr: any[], value: string): any {
    if (arr) {
      if (!value) {
        return arr
      } else {
        return arr.filter(obj => this.filter(obj,value))
      }
    } else {
      return []
    }
  }

  filter(source :any, target :string) : boolean {
     return printerCardTexts.some(x=>{
       let returnVal = false;
       if(x=='tags'){
         returnVal = (source[x]) ? source[x].map(v => v).includes(target) : false;
       }else{
         returnVal = (source[x]) ? source[x].toLowerCase().includes(target.toLowerCase()) : false;
       }
       return returnVal;
     });
  }
}

@Pipe({ name: 'orderBy' })
export class OrderByPipe {
  transform(array, orderBy, asc = true) {
    if (!orderBy || orderBy.trim() == "") {
      return array;
    }

    if (array != undefined) {
      //ascending
      if (asc) {
        return Array.from(array).sort((item1: any, item2: any) => {
          return this.orderByComparator(item1[orderBy], item2[orderBy]);
        });
      } else {
        //not asc
        return Array.from(array).sort((item1: any, item2: any) => {
          return this.orderByComparator(item2[orderBy], item1[orderBy]);
        });
      }
    }
  }

  orderByComparator(a: any, b: any): number {
    if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
      //Isn't a number so lowercase the string to properly compare
      if (a.toLowerCase() < b.toLowerCase()) return -1;
      if (a.toLowerCase() > b.toLowerCase()) return 1;
    }
    else {
      //Parse strings as numbers to compare properly
      if (parseFloat(a) < parseFloat(b)) return -1;
      if (parseFloat(a) > parseFloat(b)) return 1;
    }

    return 0; //equal each other
  }
}

@Pipe({
  name: 'stringNameSeparatorPipe',
  pure: true
})
export class StringNameSeparatorPipe implements PipeTransform {
  transform(str: string){
    // if ((key.match(/_/g) || []).length > 0) {
    //   return key.split('_').join(' ');
    // } else {
    //   return key.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    // }
    var  transformedStr = str.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
    transformedStr = transformedStr.replace(/[_.]/g, " ");
      return transformedStr;
  }
}

@Pipe({ name: 'propertyTransform'})
export class PropertyTransform implements PipeTransform{
  transform(str:string) : string{
      var  transformedStr = str.replace(/([A-Z])/g, ' $1').trim();
      return transformedStr;
  }
}

@Pipe({ name: 'adapterSerialSearch'})
export class AdapterSerialSearchPipe implements PipeTransform{
  transform(arr: any[], serial:string) : any[] {
    if (serial == "" || isNullOrUndefined(serial)) return arr;
    return arr.filter(x => x.adapter_id.split("-")[1].startsWith(serial));
  }
}
