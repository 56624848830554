import { Component, OnInit, ViewChild, Input, AfterViewInit, Output, EventEmitter, ChangeDetectionStrategy, OnChanges, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { BaseClass } from 'src/app/models/baseClass';
import { NotifyService } from 'src/app/services/notify.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataGridComponent extends BaseClass implements OnInit, AfterViewInit, OnChanges {
  dataSource = new MatTableDataSource();
  showLoadingSpinner: boolean;

  @Input() gridTitle: string;
  @Input() tableData = [];
  @Input() sortableCloumns: string[] = [];
  @Input() displayedColumns: string[];
  @Input() pageSize: number;
  @Input() pageSizeOptions: number[];  
  @Input() totalSize: number;
  @Input() showPaginator: boolean = true;
  @Input() showTitle: boolean = true;
  @Input() button1: string;
  @Input() button2: string;

  @Output() pageChange = new EventEmitter<any>(false);
  @Output() button1Click = new EventEmitter<any>(false);
  @Output() button2Click = new EventEmitter<any>(false);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
      changeDetectorRef: ChangeDetectorRef,
      notifyService: NotifyService,
      translateService: TranslateService
  ) {
    super(changeDetectorRef, notifyService, translateService);
  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.showLoadingSpinner = true;
    this.addButtonsAsDisplayColumns();
    this.dataSource = new MatTableDataSource(this.tableData);
    this.dataSource.sort = this.sort;
    this.showLoadingSpinner = false;
    this.detectChanges();
  }

  ngOnChanges(event) {
    if (event['tableData'] != undefined) {
      this.dataSource = new MatTableDataSource(event['tableData'].currentValue);
      this.dataSource.sort = this.sort;
      this.showLoadingSpinner = false;
    }
    if (event['totalSize'] != undefined) {
      this.totalSize = event['totalSize'].currentValue;
    }
    this.detectChanges();
  }

  onPageChange(event) {
    this.showLoadingSpinner = true;
    this.pageChange.emit(event);
  }

  isSortDisabled(item) {
    return this.sortableCloumns.indexOf(item) > -1;
  }

  onButton1Click(item, event) {
    console.log('clicked: ' + item);
    this.button1Click.emit({item, event});
  }

  onButton2Click(item, event) {
    this.button2Click.emit({item, event});
  }

  addButtonsAsDisplayColumns() {
    console.log(this.button1);
    if (this.button1) {
      this.displayedColumns.push(this.button1);
    }
    if (this.button2) {
      this.displayedColumns.push(this.button2);
    }
  }
}
