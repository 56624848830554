import { Component, OnInit, Inject, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ComponentConst } from '../../../models/constants/constants';
import { CustomModalService } from '../../../services/custom-modal.service';
import { PrinterImagePathList } from 'src/app/models/constants/constants';
import { getPrinterNameFromModel } from 'src/app/functions/common-functions';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/services/notify.service';
@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent implements OnInit {
  private component: any;
  imageUrl: string;
  title: string;
  printerFamily: string;

  blnDisableSaveBtn: boolean = false;
  
  dataChangeSubscription: Subscription;
  @Output('eventEmitter') eventEmitter = new EventEmitter(false);
  @Output('eventEmitter') sfEvent = new EventEmitter(false);
  constructor(
    private notifyService: NotifyService,
    public dialogRef: MatDialogRef<CustomModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customModalService: CustomModalService
  ) {
    this.component = ComponentConst;
  }

  ngOnInit() {
    this.dataChangeSubscription = this.notifyService.dynamicChartDataChange.subscribe(data => {
      this.data = data;
    });

    this.printerFamily = getPrinterNameFromModel(this.data.printerModel)
    this.title = this.data.title;
    this.blnDisableSaveBtn = this.data.read_only ? true : false;
    if (this.data.printerModel != undefined) {
      this.imageUrl = PrinterImagePathList[this.data.printerModel];
    }
  }

  onButtonClick(msg) {
    if (msg == 'ok') {
      this.customModalService.getData();
    }
    else if(msg == 'sf_sycup') {
      this.sfEvent.emit(msg);
      // this.dialogRef.close(msg);
    }
    else {
      this.dialogRef.close(msg);
    }
  }

  onDataEmit(data) {

    this.dialogRef.close(data);
  }

  onEventEmit(event) {
    this.eventEmitter.emit(event);
  }

}
