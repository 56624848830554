import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor( private amplifyService: AmplifyService) {
  }
  currentUser() {
    return this.amplifyService.auth().currentAuthenticatedUser()
      .then(user => user)
      .catch(err => {
          return null;
      });
  }
  signOut() {
    this.amplifyService.auth().signOut();
  }
}
