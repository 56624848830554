import { Component, OnInit } from '@angular/core';
import { SvgBaseComponent } from '../../svgbase.component';

@Component({
  selector: 'finalize-icon',
  templateUrl: './finalize.component.html',
  styleUrls: ['./finalize.component.sass']
})
export class FinalizeComponent extends SvgBaseComponent {

  ngOnInit() {
  }

}
