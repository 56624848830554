import { NgModule } from '@angular/core';

import { CloseComponent } from './component/close/close.component';
import { CrossComponent } from './component/cross/cross.component';
import { DynamicSvgComponent } from './component/dynamic-svg/dynamic-svg.component';
import { HamburgerComponent } from './component/hamburger/hamburger.component';
import { CommonModule } from '@angular/common';
import { LeftArrowComponent } from './component/left-arrow/left-arrow.component';
import { RightArrowComponent } from './component/right-arrow/right-arrow.component';
import { ErrorComponent } from './component/error/error.component';
import { CheckmarkComponent } from './component/checkmark/checkmark.component';
import { StartComponent } from './component/start/start.component';
import { AlarmComponent } from './component/alarm/alarm.component';
import { CalendarComponent } from './component/calendar/calendar.component';
import { ClockComponent } from './component/clock/clock.component';
import { InfoComponent } from './component/info/info.component';
import { TrashComponent } from './component/trash/trash.component';
import { DisconnectedComponent } from './component/disconnected/disconnected.component';
import { ConnectedComponent } from './component/connected/connected.component';
import { FinalizeComponent } from './component/finalize/finalize.component';
import { InitializeComponent } from './component/initialize/initialize.component';
import { MaintenanceComponent } from './component/maintenance/maintenance.component';
import { UnknownComponent } from './component/unknown/unknown.component';
import { PauseComponent } from './component/pause/pause.component';
import { ShutdownComponent } from './component/shutdown/shutdown.component';
import { ReadyComponent } from './component/ready/ready.component';
import { SpinnerComponent } from './component/spinner/spinner.component';
import { WarningComponent } from './component/warning/warning.component';
import { PendingComponent } from './component/pending/pending.component';
import { IdleComponent } from './component/idle/idle.component';
import { ResetComponent } from './component/reset/reset.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        CloseComponent,
        CrossComponent,
        DynamicSvgComponent,
        HamburgerComponent,
        LeftArrowComponent,
        RightArrowComponent,
        ErrorComponent,
        CheckmarkComponent,
        StartComponent,
        AlarmComponent,
        CalendarComponent,
        ClockComponent,
        InfoComponent,
        TrashComponent,
        DisconnectedComponent,
        ConnectedComponent,
        FinalizeComponent,
        InitializeComponent,
        MaintenanceComponent,
        UnknownComponent,
        PauseComponent,
        ShutdownComponent,
        ReadyComponent,
        SpinnerComponent,
        WarningComponent,
        ReadyComponent,
        PendingComponent,
        IdleComponent,
        ResetComponent
    ],
    exports: [
        CloseComponent,
        CrossComponent,
        DynamicSvgComponent,
        HamburgerComponent,
        LeftArrowComponent,
        RightArrowComponent,
        ErrorComponent,
        CheckmarkComponent,
        StartComponent,
        AlarmComponent,
        CalendarComponent,
        ClockComponent,
        InfoComponent,
        TrashComponent,
        ReadyComponent,
        SpinnerComponent,
        WarningComponent,
        PendingComponent,
        IdleComponent,
        ResetComponent
    ],
    entryComponents: [ DynamicSvgComponent ]
})
export class SvgModule { }