import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, NgZone } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts/google-charts-interfaces';
import {
  ChartReadyEvent, ChartErrorEvent, ChartSelectEvent,
  ChartMouseOverEvent, ChartMouseOutEvent, GoogleChartComponent
} from 'ng2-google-charts';
import { SensorHistogramOptions } from 'src/app/models/constants/constants';
import { CustomModalService } from 'src/app/services/custom-modal.service';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/services/notify.service';
import { AngularCsv } from 'angular7-csv';

declare var $: any;
declare var google: any;

@Component({
  selector: 'dynamic-chart',
  templateUrl: './dynamic-chart.component.html',
  styleUrls: ['./dynamic-chart.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})


export class DynamicChartComponent implements OnInit {
  @Input('data') data: any;

  @Output('eventEmit') eventEmit = new EventEmitter();
  @Output('dataEmitter') dataEmitter = new EventEmitter();

  @ViewChild('google-chart-div') googleChart: GoogleChartComponent;

  objChartOptions: any = SensorHistogramOptions;
  objExportData: any; 
  startDate: any;
  endDate: any;
  objMinDate: Date;
  objMaxDate: Date;
  objStartMaxDate: Date;
  selected: string = 'google-chart-div';
  selectedOption: string = 'default';
  strLeftLabel: string;
  strRightLabel: string;
  strPrinterId: string;
  strPrinterTz: string;

  blnShowdatepicker: boolean = false;
  blnShowChart: boolean = false;
  blnShowSecondaryChart: boolean = false;
  blnAppendedTimezoneToExportData: boolean = false;

  public selectEvent: ChartSelectEvent;

  customModalSub: Subscription;
  dataChangeSubscription: Subscription;

  public dynamicChart: GoogleChartInterface = {
    chartType: '',
    dataTable: [],
    options: {}
  };

  public secondaryDynamicChart: GoogleChartInterface = {
    chartType: '',
    dataTable: [],
    options: {}
  };
  exportFileName: any;
  objExportOptions: any;
  cardTitle: any;

  constructor(
    private notifyService: NotifyService,
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    // window.onresize = (e) => {
    //   //ngZone.run will help to run change detection
    //   this.ngZone.run(() => {
    //     let chartComponent = this.dynamicChart.component;
    //     let chartWrapper = chartComponent.wrapper;
    //     // if(chartComponent!=undefined){
    //        chartComponent.draw();
    //     // }
       
    //  });
    // };
  }

  ngOnInit() {
    console.log(this.data)
    this.cardTitle = this.data.title;
    this.strPrinterTz = this.data.timeZone ? this.data.timeZone : "";
    this.dataChangeSubscription = this.notifyService.dynamicChartDataChange.subscribe(data => {
      this.data = data;
      this.blnShowChart = false;
      if (this.data.chartData && this.data.chartData.length > 1) {
        this.blnShowChart = true;
        this.setStartEndTime();
        this.configureChart();
        this.detectChanges();
      } else {
        this.blnShowChart = false;
      }

      if (this.data.secondaryChartData && this.data.secondaryChartData.length > 1) {
        this.blnShowSecondaryChart = true;
        this.configureSecondaryChart();
        this.detectChanges();
      } else {
        this.blnShowSecondaryChart = false;
      }
      this.detectChanges();

      if(this.data.exportData) {
        this.blnAppendedTimezoneToExportData = false;
        this.objExportData = this.data.exportData;
      }
      if(this.data.printerId) {
        this.strPrinterId = this.data.printerId;
      }
    });
  }

  detectChanges() {
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    if (this.dataChangeSubscription) { this.dataChangeSubscription.unsubscribe(); }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.selectedOption = this.data.selector;
    if(changes.data.currentValue.selector)
      this.selectedOption = changes.data.currentValue.selector;
    if (changes.data.currentValue) {
      this.setStartEndTime();
      if (changes.data.currentValue.chartData && changes.data.currentValue.chartData.length > 1) {
        this.blnShowChart = true;
        this.configureChart();
        this.detectChanges();
      } else {
        this.blnShowChart = false;
      }

      if (changes.data.currentValue.secondaryChartData && changes.data.currentValue.secondaryChartData.length > 1) {
        this.blnShowSecondaryChart = true;
        this.configureSecondaryChart();
        this.detectChanges();
      } else {
        this.blnShowSecondaryChart = false;
      }

      if(changes.data.currentValue.exportData) {
        this.blnAppendedTimezoneToExportData = false;
        this.objExportData = changes.data.currentValue.exportData;
      }
      if(changes.data.currentValue.printerId) {
        this.strPrinterId = changes.data.currentValue.printerId;
      }

      if (changes.data.currentValue.chartDropDownOptions) {
        this.objChartOptions = changes.data.currentValue.chartDropDownOptions;
      }
      this.detectChanges();
    }
  }

  setStartEndTime() {
    this.startDate = this.data.startDate || new Date();
    this.endDate = this.data.endDate || new Date();
    this.objMinDate = this.startDate;
    this.objMaxDate = this.endDate;
    this.objStartMaxDate = this.endDate;
  }

  configureChart() {
    this.dynamicChart = {
      chartType: this.data.chartType,
      dataTable: this.data.chartData,
      options: this.data.chartOptions
    };
  }

  configureSecondaryChart() {
    this.secondaryDynamicChart = {
      chartType: this.data.secondaryChartType,
      dataTable: this.data.secondaryChartData,
      options: this.data.secondaryChartOptions
    };
  }

  dateChange(event, type) {
    switch (type) {
      case 0: this.startDate = new Date(event.value); break;
      default: this.endDate = new Date(event.value); break;
    }
    this.objMinDate = this.startDate;
    this.objMaxDate = new Date();
    this.objStartMaxDate = this.endDate;
  }

  onOptionChange(key) {
    if (key.value == 'custom') {
      this.blnShowdatepicker = true;
    } else {
      this.eventEmit.emit(key.value);
      this.blnShowdatepicker = false;
    }
  }

  public onReady(event) {
    // console.log(event);
  }

  onSubmitRange() {
    // this.blnShowChart = true;
    this.eventEmit.emit({ 'startDate': this.startDate, 'endDate': this.endDate });
  }

  changeMaxEndTime() {
    this.objMaxDate = new Date();
    this.objStartMaxDate = this.endDate;
  }

  toggleDurationToCustom() {
    if (this.objChartOptions.findIndex(x => x.key == 'custom') > -1) {
      this.selectedOption = "custom";
    }
  }

  export() {
    let timeZone = this.strPrinterTz ? this.strPrinterTz : "+00:00";
    if (!this.blnAppendedTimezoneToExportData) {
      this.blnAppendedTimezoneToExportData = true;
      this.objExportData = this.objExportData.map(x => {
        let obj = { ...x };
        obj["start"] += timeZone;
        obj["end"] += timeZone;
        return obj;
      });
    }

    let exportOptions = {
      showLabels: true,
      headers: ["Status","Start Date","End Data","Duration"]
    };
    new AngularCsv(this.objExportData, "Timeline chart: "+this.strPrinterId, exportOptions);
  }
}
