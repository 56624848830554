import { isArray } from 'util';
import { isNullOrUndefinedOrEmpty, getPrinterModelFromId } from '../functions/common-functions';
import { PrinterModels } from './constants/constants';

export class PrinterJob {
    build_mode: string;
    build_time_estimate: number;
    total_stage_height: number;
    status: string;
    device_id: string;
    build_style: string;
    job_id: string;
    estimated_end_time: number;
    submit_time: number;
    pending_duration: number;
    current_height: any;
    current_stage_height: number;
    current_timezone: string;
    start_time: string;
    status_code: number;
    TimeStamp: string;
    total_print_height: number;
    last_updated_time: number;
    elapsed_duration: number;
    native_code: string;
    material_name: string;
    printer_name: string;
    build_estimated_end_time: string;
    current_layer: null;
    material_type: string[]
    build_elapsed_time: number;
    build_duration: number;
    build_estimate_duration: number;
    build_id: string;
    build_start_time: string;
    build_status_change_time: string;
    build_submit_time: string;
    build_thumbnail_name: number;
    build_total_layers: number;
    build_x_extent: number;
    build_y_extent: number;
    build_z_extent: number;
    build_current_height: number;
    timezone: string;
    build_progress: string;
    fixed_total: number;
    material_estimate_total_volume: any[];
    material_estimate_total: string;
    sw_version: string;
    total_layers: any;
    controller_internal_temperature: number;
    cold_temperature: boolean;
    projector_manual_compensation: number;
    completion_time: number;
    build_feed_percentage: any;
    build_log: object[];
    consumed_material_volume: number;
    estimated_material_volume: number;
    consumed_material_quantity: number;
    estimated_material_quantity: number;
    constructor(row: any) {
        this.device_id = !isNullOrUndefinedOrEmpty(row.device_id) ? row.device_id : "N/A";
        this.material_name = this.deduceMaterial(row);
        this.material_estimate_total_volume = isArray(row.material_estimate_total_volume) ? row.material_estimate_total_volume :
            (!isNullOrUndefinedOrEmpty(row.material_estimate_total_volume) ? row.material_estimate_total_volume : "0");
        this.build_mode = !isNullOrUndefinedOrEmpty(row.build_mode) ? row.build_mode : "N/A";
        this.build_time_estimate = !isNullOrUndefinedOrEmpty(row.build_time_estimate) ? row.build_time_estimate : "N/A";
        this.total_stage_height = !isNullOrUndefinedOrEmpty(row.total_stage_height) ? row.total_stage_height : "N/A";
        this.status = !isNullOrUndefinedOrEmpty(row.status) ? row.status : "N/A";
        this.build_style = !isNullOrUndefinedOrEmpty(row.build_style) ? row.build_style : "N/A";
        this.job_id = !isNullOrUndefinedOrEmpty(row.job_id) ? row.job_id : "N/A";
        this.estimated_end_time = !isNullOrUndefinedOrEmpty(row.estimated_end_time) ? row.estimated_end_time : "N/A";
        this.submit_time = !isNullOrUndefinedOrEmpty(row.submit_time) ? row.submit_time : 0;
        this.pending_duration = !isNullOrUndefinedOrEmpty(row.pending_duration) ? row.pending_duration : "N/A";
        this.current_height = !isNullOrUndefinedOrEmpty(row.current_height) ? row.current_height : "N/A";
        this.current_stage_height = !isNullOrUndefinedOrEmpty(row.current_stage_height) ? row.current_stage_height : "N/A";
        this.current_timezone = !isNullOrUndefinedOrEmpty(row.current_timezone) ? row.current_timezone : null;
        this.start_time = !isNullOrUndefinedOrEmpty(row.start_time) ? row.start_time : "N/A";
        this.status_code = !isNullOrUndefinedOrEmpty(row.status_code) ? row.status_code : "N/A";
        this.TimeStamp = !isNullOrUndefinedOrEmpty(row.TimeStamp) ? row.TimeStamp : "N/A";
        this.total_print_height = !isNullOrUndefinedOrEmpty(row.total_print_height) ? row.total_print_height : "N/A";
        this.last_updated_time = !isNullOrUndefinedOrEmpty(row.last_updated_time) ? row.last_updated_time : "N/A";
        this.elapsed_duration = !isNullOrUndefinedOrEmpty(row.elapsed_duration) ? row.elapsed_duration : "N/A";
        this.native_code = !isNullOrUndefinedOrEmpty(row.native_code) ? row.native_code : "N/A";
        this.printer_name = !isNullOrUndefinedOrEmpty(row.printer_name) ? row.printer_name : "N/A";
        this.build_estimated_end_time = !isNullOrUndefinedOrEmpty(row.build_estimated_end_time) ? row.build_estimated_end_time : "N/A";
        this.build_id = !isNullOrUndefinedOrEmpty(row.build_id) ? row.build_id : "N/A";
        this.build_start_time = !isNullOrUndefinedOrEmpty(row.build_start_time) ? row.build_start_time : "N/A";
        this.build_status_change_time = !isNullOrUndefinedOrEmpty(row.build_status_change_time) ? row.build_status_change_time : "N/A";
        this.build_submit_time = !isNullOrUndefinedOrEmpty(row.build_submit_time) ? row.build_submit_time : "N/A";
        this.build_thumbnail_name = !isNullOrUndefinedOrEmpty(row.build_thumbnail_name) ? row.build_thumbnail_name : "N/A";
        this.build_total_layers = !isNullOrUndefinedOrEmpty(row.build_total_layers) ? row.build_total_layers : "N/A";
        this.build_x_extent = !isNullOrUndefinedOrEmpty(row.build_x_extent) ? row.build_x_extent : "N/A";
        this.build_y_extent = !isNullOrUndefinedOrEmpty(row.build_y_extent) ? row.build_y_extent : "N/A";
        this.build_z_extent = !isNullOrUndefinedOrEmpty(row.build_z_extent) ? row.build_z_extent : "N/A";
        this.build_current_height = !isNullOrUndefinedOrEmpty(row.build_current_height) ? row.build_current_height : "N/A";
        this.timezone = !isNullOrUndefinedOrEmpty(row.timezone) ? row.timezone : "N/A";
        this.build_progress = !isNullOrUndefinedOrEmpty(row.build_progress) ? row.build_progress : "N/A";
        this.fixed_total = !isNullOrUndefinedOrEmpty(row.fixed_total) ? row.fixed_total : "N/A";
        this.sw_version = !isNullOrUndefinedOrEmpty(row.sw_version) ? row.sw_version : "N/A";
        this.total_layers = !isNullOrUndefinedOrEmpty(row.total_layers) ? row.total_layers : "N/A";
        this.build_duration = !isNullOrUndefinedOrEmpty(row.build_duration) ? row.build_duration : "N/A";
        this.current_layer = !isNullOrUndefinedOrEmpty(row.current_layer) ? row.current_layer : "N/A";
        this.controller_internal_temperature = !isNullOrUndefinedOrEmpty(row.controller_internal_temperature) ? row.controller_internal_temperature : "N/A";
        this.cold_temperature = !isNullOrUndefinedOrEmpty(row.cold_temperature) ? row.cold_temperature : "N/A";
        this.projector_manual_compensation = !isNullOrUndefinedOrEmpty(row.projector_manual_compensation) ? row.projector_manual_compensation : "N/A";
        this.completion_time = !isNullOrUndefinedOrEmpty(row.completion_time) ? row.completion_time : "N/A";
        this.material_estimate_total = this.deduceMaterialEstimateTotal(row);
        this.build_feed_percentage = !isNullOrUndefinedOrEmpty(row.build_feed_percentage) ? row.build_feed_percentage : "N/A";
        this.build_log = !isNullOrUndefinedOrEmpty(row.build_log) ? row.build_log : [];
        this.consumed_material_volume = !isNullOrUndefinedOrEmpty(row.consumed_material_volume) ? row.consumed_material_volume : 'N/A';
        this.consumed_material_quantity = !isNullOrUndefinedOrEmpty(row.consumed_material_quantity) ?
         row.consumed_material_quantity : 'N/A';
        this.estimated_material_volume = !isNullOrUndefinedOrEmpty(row.estimated_material_volume) ? row.estimated_material_volume : 'N/A';
        this.estimated_material_quantity = !isNullOrUndefinedOrEmpty(row.estimated_material_quantity) ?
         row.estimated_material_quantity : 'N/A';
    }

    deduceMaterial(row) {
        if (isArray(row.material_name)) {
            if (getPrinterModelFromId(this.device_id) === PrinterModels.CJP_Panda) {
                return row.material_name
                .reduce(
                    (cummulativeMaterial: string, x) => cummulativeMaterial + ', ' + x)
                .replace(/binder/gi, '').trim();
            } else {
                return row.material_name[0];
            }
        } else {
            return (!isNullOrUndefinedOrEmpty(row.material_name) ? row.material_name : 'N/A')
         }
    }

    deduceMaterialEstimateTotal(row) {
        if (isArray(row.material_estimate_total)) {
            if (getPrinterModelFromId(this.device_id) === PrinterModels.CJP_Panda) {
                return row.material_estimate_total
                .reduce(
                    (cummulativeMaterialEstimateTotal: string, x) => cummulativeMaterialEstimateTotal + ', ' + x.toString());
            } else {
                return (!isNullOrUndefinedOrEmpty(row.material_estimate_total) ? row.material_estimate_total : 'N/A');
                }
        }
    }
}

