import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { GroupResponseModel } from '../models/response';
import { Group } from '../models/group';
import { LoaderService } from './loader.service';
import { CommonService } from './common.service';
import { deployments } from 'src/environments/deployments';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  company = deployments.company;
  groups = null;

  constructor(
    public loaderService: LoaderService,
    private commonService: CommonService
    ) {}

  public getMTMSGroupsData(type?: string, pagesize?: any, pagenumber?: any) {
    this.loaderService.show();
    let promise = new Promise((resolve, reject) => {
      if(this.groups){
        this.loaderService.hide();
        resolve(JSON.parse(this.groups));
      } else {
        let psize = pagesize || 1000;
        let page = pagenumber || 1;

        return this.commonService.getEndpointPromise('GET', `/groups?${type}&per_page=${psize}&page=${page}`, { headers: {} }, 'mtms').then((response: GroupResponseModel) => {
          this.groups = JSON.stringify(response);
          resolve(response);
        }).catch(error => {
          console.log(error);
          reject();
        }).finally(()=>{
          this.loaderService.hide();
        });
      }
    });
    return promise;      
  }

  public getAWSCompanyGroups() {
    this.loaderService.show();
    let promise = new Promise((resolve, reject) => {
      if(this.groups){
        console.log('exists!!!!')
        this.loaderService.hide();
        resolve(this.groups);
      } else {
        return this.commonService.getEndpointPromise('GET', `/groups/list-company-groups?company=${this.company}&count=50&offset=0`, { headers: {} }, 'facade').then((resp) => {
          this.groups = this.filterPools(resp.results, this.company)
          resolve(this.groups);
        }).finally(()=>{
          this.loaderService.hide();
        });
      }
    });
    return promise;      
  }

  public getAWSPrinterGroups(printer_id) {
    this.loaderService.show();
    return this.commonService.getEndpointPromise('GET', `/groups/get-printer-group?company=${this.company}&printer_id=${printer_id}`, { headers: {} }, 'facade').finally(()=>{
      this.loaderService.hide();
    });
  }

  filterPools(data, company) {
    return data.filter(x=> x.parentPath == `/${company}/pools` && x.name != "Google");
  }


  public getAWSGroupsInfo(gname){
    return this.commonService.getEndpointPromise('GET', `/groups/get-group-info?company=${this.company}&group=${
      gname}`, { headers: {} }, 'facade').finally(() => {
    });
  }

  public getGroupsPrinters(gname){
    return this.commonService.getEndpointPromise('GET', `/groups/get-group-printers?company=${this.company}&group=${
      gname}&count=200&offset=0`, { headers: {} }, 'facade').finally(() => {
    });
  }
  public getGroupsUsers(groups){
    let data = {
      headers: {},
      body:{company:this.company, groups:groups}
    }
    return this.commonService.getEndpointPromise('POST', `/companies/${this.company}/groups/users`, data, 'userMgmt').finally(() => {
    });
  }

  public createAWSGroup(groupData?: any) {
    groupData.company = this.company;
    let data = {
      headers: {},
      body:{data:groupData}
    }
    console.log(data);
    this.loaderService.show();
    return this.commonService.getEndpointPromise('POST', `/groups/create-group`, data, 'facade').finally(() => {
      this.loaderService.hide();
    });
  }

  public updateAWSGroup(group, groupData?: any) {
    let updateData = {
        group:group,
        new_data:{}
    };
    if(groupData.name){
      updateData.new_data["attributes"] = {
          alias:groupData.name
      }
    }
    if(groupData.newParent){
      updateData.new_data["attach_to_group"] = groupData.newParent
    }
    if(groupData.newOwner){
      updateData.new_data["owner"] = groupData.newOwner
    }
    let data = {
      headers: {},
      body:{data:updateData}
    }
    console.log(data);
    this.loaderService.show();
    return this.commonService.getEndpointPromise('POST', `/groups/update-group`, data, 'facade').finally(() => {
      this.loaderService.hide();
    });
  }

  public deleteAWSGroup(group, company) {
    let data = {
      headers: {},
      body:{company:company, group:group}
    }
    console.log(data);
    this.loaderService.show();
    return this.commonService.getEndpointPromise('POST', `/groups/delete-group`, data, 'facade').finally(() => {
      this.loaderService.hide();
    });
  }

  addUsersToAWSGroup(gids, data?:object){
    let group = {
      headers: {},
      body: {
        groups:gids,
        users:data
      }
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('PUT', `/profile/${this.company}/addtogroup`, group, 'userMgmt').finally(() => {
      this.loaderService.hide();
    });
  }

  removeUsersFromAWSGroup(gids, data?:object){
    let group = {
      headers: {},
      body: {
        groups:gids,
        users:data
      }
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('PUT', `/profile/${this.company}/removefromgroup`, group, 'userMgmt').finally(() => {
      this.loaderService.hide();
    });
  }

  addPrinterToAWSGroup(gid, data?:object){
    let group = {
      headers: {},
      body: {
        group:gid,
        printer_id:data
      }
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('POST', `/printers/printer/attach-printer`, group, 'facade').finally(() => {
      this.loaderService.hide();
    });
  }

  detachPrinterToAWSGroup(gid, data?:object){
    let group = {
      headers: {},
      body: {
        group:gid,
        printer_id:data
      }
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('POST', `/printers/printer/detach-printer`, group, 'facade').finally(() => {
      this.loaderService.hide();
    });
  }

  public clearGroup(){
    this.groups = null;
  }

  public setGroupData(data) {
    this.groups = JSON.stringify(data);
  }
}
