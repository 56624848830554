import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() total: number;
  @Input() value: number;
  @Input() backgroundColor: string = "lightblue";
  @Input() textColor: string = "#409acc";
  @Input() blnValueOutside: boolean = false;
  @Input() valueLabel: string = "";

  isNaN: Function = Number.isNaN;

  constructor() { }

  ngOnInit() { }

  // ngOnChanges(changes: SimpleChanges): void {
  //   console.log(changes);
  // }
}
