import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatCheckboxChange, MatSlideToggleChange } from '@angular/material';
import { FormGroup } from '@angular/forms';
import { Modal_Prev_maint_select } from 'src/app/functions/modal-trigger';
import { TriggerModalService } from 'src/app/services/trigger-modal.service';

@Component({
  selector: 'app-prev-maint-printer-select-modal',
  templateUrl: './prev-maint-printer-select-modal.component.html',
  styleUrls: ['./prev-maint-printer-select-modal.component.scss']
})
export class PrevMaintPrinterSelectModalComponent implements OnInit {
  arrSelectedPrinters: string[] = [];
  blnCheckAll: boolean = false;
  blnShowBtn: boolean = true;

  pmForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<PrevMaintPrinterSelectModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  onButtonClick(msg = null) {
    if (msg) this.dialogRef.close();
    else
      this.dialogRef.close(this.arrSelectedPrinters);
  }

  onPrinterSelect(event: MatCheckboxChange, printer) {
    if (event.checked) {
      this.arrSelectedPrinters.push(printer);
      this.blnShowBtn = false;
    }
    else {
      let index = this.arrSelectedPrinters.findIndex(x => x == printer);
      if (index > -1) {
        this.arrSelectedPrinters.splice(index, 1);
        this.blnShowBtn = this.arrSelectedPrinters.length == 0;
      }
    }
  }

  selectAllPrinters(event: MatSlideToggleChange) {
    if (event.checked) {
      this.arrSelectedPrinters.length = 0;
      this.blnCheckAll = true; this.blnShowBtn = false;
      this.data.printers.forEach(printer => {
        this.arrSelectedPrinters.push(printer)
      });
    }
    else {
      this.blnCheckAll = false;
      this.arrSelectedPrinters.length = 0;
      this.blnShowBtn = true;
    }
  }
}
