import { PrinterImagePathList } from 'src/app/models/constants/constants';
import { generateTooltip, getPrinterNameFromModel,getPrinterNameFromFullModel, convertUTCtoPrinterTimezone, preparePrintertimeZoneOffset } from 'src/app/functions/common-functions';
import { isNullOrUndefined } from 'util';

export class Printer  {
    printerModel: string;
    modelImg: string;
    printerStatus: string;
    model: string;
    serial_no: string;
    ipAddress: string;
    accountName: string;
    region: string;
    adapterName: string;
    product: string;
    isFollowing: boolean;
    printerFamily: string;
    daily_alert_count?: number;
    printerName: string;
    nickname: any;
    printer_model: string;
    full_model: string;
    connection_status: boolean;
    timezone: string;
    statusUpdatedAt: string;

    constructor(row: any, followed? ){
        // this.printerModel = (row.deviceId ? row.deviceId : 'N/A');
        this.full_model = row.attributes.fullModel;
        this.printer_model = getPrinterNameFromFullModel(row.attributes.fullModel) + ' (' + row.attributes.fullModel + ')';
        this.model = row.attributes.model;
        this.modelImg = this.fetchPrinterImg(row.attributes.model);
        this.printerStatus = (row.attributes.status ? row.attributes.status : 'Unknown');
        this.connection_status = this.printerStatus != 'Disconnected' ? true : false;
        this.serial_no = (row.attributes.serialNumber ? row.attributes.serialNumber : 'N/A');
        this.ipAddress = (row.attributes.ipAddress ? row.attributes.ipAddress : 'N/A');
        this.printerName = this.model + '-' + this.serial_no;
        this.nickname = (row.attributes.alias ? row.attributes.alias : 'N/A');
        this.printerModel = this.model + '-' + this.serial_no;
        this.adapterName = (row.devices && row.devices.in && row.devices.in.publishes_to ? row.devices.in.publishes_to[0] : 'N/A')
        this.accountName = (row.attributes.sfAccountName ? row.attributes.sfAccountName : 'N/A');
        this.region = (row.attributes.region ? row.attributes.region : 'N/A');
        this.product = (row.attributes.product ? row.attributes.product : 'N/A');
        this.isFollowing = (followed) ? this.setPrintingFilter(followed, row.deviceId) : 'N/A';
        this.printerFamily = getPrinterNameFromModel(this.model)
        this.timezone = row.attributes.timezone;
        this.statusUpdatedAt = this.getStatusUpdatedTimeInPrinterTimeZone(row);
    }

    fetchPrinterImg(model){
        let PrinterImg = 'assets/images/printer_images/404.png'
        if(this.full_model == '32004-1') // fix to distinguish 32004-0 & 32004-1
            PrinterImg = 'assets/images/printer_images/DMPFactory350.png'
        else
            PrinterImg = PrinterImagePathList[model] ? PrinterImagePathList[model] : 'assets/images/printer_images/404.png'
        return PrinterImg;
    }

    setPrintingFilter(followed, device){
        followed = followed.map(v => v.toUpperCase());
        device = device.toUpperCase();
        let followFlag = followed.includes(device);
        return followFlag;
    }

    setPrinterInfo(){
      let printer_obj ={
          product : this.product,
          model : this.printerModel,
          serial_no : this.serial_no,
          ip_address : this.ipAddress,
          adapter_name : this.adapterName,
          accountName : this.accountName,
          region : this.region,
          status : this.printerStatus,
          printer_img : this.modelImg
      }  
    return printer_obj;
    }

    getStatusUpdatedTimeInPrinterTimeZone(row) {
        if (isNullOrUndefined(row.attributes.statusUpdatedAt)) return "Missing update information";
        let time = new Date(row.attributes.statusUpdatedAt * 1000).toISOString();
        return convertUTCtoPrinterTimezone(time, this.timezone) + ` UTC ${this.timezone == "" ? "+00:00" : preparePrintertimeZoneOffset(this.timezone)}`;
    }
}
