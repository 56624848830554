import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { SensorHistogramOptions, printStatusColorReference } from 'src/app/models/constants/constants';
import { ChartSelectEvent } from 'ng2-google-charts';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/services/notify.service';
import * as c3 from 'c3';
import { isNullOrUndefined } from 'util';
import { AngularCsv } from 'angular7-csv';

@Component({
  selector: 'dynamic-c3-chart',
  templateUrl: './dynamic-c3-chart.component.html',
  styleUrls: ['./dynamic-c3-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicC3ChartComponent implements OnInit {
  @Input('data') data: any;
  @Input('resize') resize: boolean;

  @Output('eventEmit') eventEmit = new EventEmitter();
  @Output('dataEmitter') dataEmitter = new EventEmitter();
  @Output('loadPercentage') loadPercentage = new EventEmitter();

  objChartOptions: any = SensorHistogramOptions;
  arrprinterStatusReference: object[] = printStatusColorReference;
  startDate: any;
  endDate: any;
  objMinDate: Date;
  objMaxDate: Date;
  objStartMaxDate: Date;
  selected: string = 'default';
  chartMessageKey: string;
  exportFileName: string;

  chart: any;
  secondaryChart: any;
  generateObj: any;
  generateSecondaryObj: any;
  objExportData: any;
  objExportOptions: any;

  blnShowdatepicker: boolean = false;
  blnShowChart: boolean = false;
  blnShowSecondaryChart: boolean = false;
  blnShowOverLay: boolean = false;
  blnShowOptionsToolbar: boolean = true;
  blnEnableDateChange: boolean = true;
  blnShowOverlayToggleButton: boolean = true;
  blnShowPrinterStateLegend: boolean = false;
  blnShowLoadPercentage: boolean = false;
  blnIsComponentDestroyed: boolean = false;
  blnDisableAction: boolean = false;

  numLoadPercentage: number = 0;
  CHUNK_SIZE: number = 10000;

  public selectEvent: ChartSelectEvent;

  customModalSub: Subscription;
  dataChangeSubscription: Subscription;
  no_data_msg: boolean = false;

  constructor(
    private notifyService: NotifyService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    if (!isNullOrUndefined(this.generateObj)) {
      this.chart = c3.generate(this.generateObj);
    }
  }

  ngOnInit() {
    this.dataChangeSubscription = this.notifyService.dynamicChartDataChange.subscribe(data => {
      this.blnDisableAction = true;
      this.data = data;
      if (this.data.chartData) {
        this.setStartEndTime();
        this.configureChart();
        if (this.data.chartData.length > 1) {
          this.blnShowChart = true;
        } else {
          this.blnShowChart = false;
        }
        this.detectChanges();
      }
      if (this.data.chartDataObject) {
        if (Object.entries(this.data.chartDataObject).length > 1) {
          this.blnShowChart = true;
          this.setStartEndTime();
          this.configureChart();
        } else {
          this.blnShowChart = false;
        }
        this.detectChanges();
      }
      if (this.data.secondaryChartDataObject) {
        if (Object.entries(this.data.secondaryChartDataObject).length > 1) {
          this.blnShowSecondaryChart = true;
          this.setStartEndTime();
          this.configureSecondaryChart();
        } else {
          this.blnShowSecondaryChart = false;
        }
        this.detectChanges();
      }
      if (this.data.overlayData && this.data.overlayData.length > 1) {
        this.generateObj.regions = this.data.overlayData;
        if (this.blnShowOverLay) {
          
          this.chart = c3.generate(this.generateObj);
          this.detectChanges();
        }
      }

      if (this.data.exportData) {
        this.objExportData = this.data.exportData;
      }
      this.blnDisableAction = false;
      this.detectChanges();
    });
  }

  detectChanges() {
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.blnIsComponentDestroyed = true;
    this.detectChanges();
    if (this.dataChangeSubscription) { this.dataChangeSubscription.unsubscribe(); }
    if (this.chart) { this.chart.destroy(); this.chart = null; }
    if (this.secondaryChart) { this.secondaryChart.destroy(); this.secondaryChart = null; }
    this.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.resize) {
      if (this.generateObj) {
        this.chart.resize(changes.resize.currentValue);
        this.detectChanges();
      }
    }
    if (changes.data && changes.data.currentValue) {
      this.setStartEndTime();
      if (changes.data.currentValue.chartData) {
        this.data.chartData = changes.data.currentValue.chartData;
        this.configureChart();
        if (this.data.chartData.length < 1) {
          this.blnShowChart = false;
        } else {
          this.blnShowChart = true;
        }
        this.detectChanges();
      }

      if (changes.data.currentValue.chartDataObject) {
        this.data.chartDataObject = changes.data.currentValue.chartDataObject;
        this.configureChart();
        if (this.data.chartDataObject.columns && this.data.chartDataObject.columns.length < 1) {
          this.blnShowChart = false;
        } else {
          this.blnShowChart = true;
        }
        this.detectChanges();
      }

      if (changes.data.currentValue.secondaryChartDataObject && Object.entries(changes.data.currentValue.secondaryChartDataObject).length > 1) {
        this.data.secondaryChartDataObject = changes.data.currentValue.secondaryChartDataObject;
        this.blnShowSecondaryChart = true;
        this.configureSecondaryChart();
        this.detectChanges();
      } else {
        this.blnShowSecondaryChart = false;
      }

      if (changes.data.currentValue.chartDropDownOptions) {
        this.objChartOptions = changes.data.currentValue.chartDropDownOptions;
      }

      if (!isNullOrUndefined(changes.data.currentValue.showOptionsToolbar)) {
        this.blnShowOptionsToolbar = changes.data.currentValue.showOptionsToolbar;
      }

      if (!isNullOrUndefined(changes.data.currentValue.blnShowOverLay)) {
        this.blnShowOverLay = changes.data.currentValue.blnShowOverLay;
      }

      if (!isNullOrUndefined(changes.data.currentValue.chartMessageKey)) {
        this.chartMessageKey = changes.data.currentValue.chartMessageKey;
      }

      if (!isNullOrUndefined(changes.data.currentValue.exportData)) {
        this.objExportData = changes.data.currentValue.exportData;
      }

      if (!isNullOrUndefined(changes.data.currentValue.exportOptions)) {
        this.objExportOptions = changes.data.currentValue.exportOptions;
      }

      if (!isNullOrUndefined(changes.data.currentValue.exportFileName)) {
        this.exportFileName = changes.data.currentValue.exportFileName;
      }

      if (!isNullOrUndefined(changes.data.currentValue.blnEnableDateChange)) {
        this.blnEnableDateChange = changes.data.currentValue.blnEnableDateChange;
      }

      if (!isNullOrUndefined(changes.data.currentValue.blnShowOverlayToggleButton)) {
        this.blnShowOverlayToggleButton = changes.data.currentValue.blnShowOverlayToggleButton;
      }

      if (!isNullOrUndefined(changes.data.currentValue.blnShowPrinterStateLegend)) {
        this.blnShowPrinterStateLegend = changes.data.currentValue.blnShowPrinterStateLegend;
      }

      if (!isNullOrUndefined(changes.data.currentValue.blnShowLoadPercentage)) {
        this.blnShowLoadPercentage = changes.data.currentValue.blnShowLoadPercentage;
      }

      if (!isNullOrUndefined(changes.data.currentValue.selectedOption)) {
        this.selected = changes.data.currentValue.selectedOption;
      }

      this.detectChanges();
    }
  }

  setStartEndTime() {
    this.startDate = this.data.startDate ;
    this.endDate = this.data.endDate;
    this.objMinDate = this.startDate;
    this.objMaxDate = this.endDate;
    this.objStartMaxDate = this.endDate;
  }

  configureChart() {
 
    let dataObj = {};
    if (isNullOrUndefined(this.data.chartDataObject)) {
      dataObj = {
        x: 'x',
        xFormat: '%m/%d/%Y, %H:%M:%S',
        columns: this.data.chartData
      };
    } else {
      if(this.data.chartDataObject.columns.length <= 0){
        this.no_data_msg = true;
      }
      else{
        this.no_data_msg = false;
      }
      
      dataObj = this.data.chartDataObject;
    }

    this.generateObj = {
      bindto: !isNullOrUndefined(this.data.bindingId) ? '#' + this.data.bindingId : "#chart",
      data: dataObj,
      axis: this.data.axis || {},
      regions: [],
      zoom: this.data.zoom || {},
      subchart: this.data.subchart || {},
      size: this.data.size || {},
      tooltip: this.data.tooltip || {},
      padding: this.data.padding || {},
      point: this.data.point || {},
      legend : this.data.legend || {}
    }

    if (this.data.overlayData) this.generateObj.regions = this.data.overlayData;
    this.loadGraphInChunks(this.generateObj);
    // this.chart = c3.generate(this.generateObj);
  }

  configureSecondaryChart() {
    let dataObj = {};
    if (isNullOrUndefined(this.data.secondaryChartDataObject)) {
      dataObj = {
        x: 'x',
        xFormat: '%m/%d/%Y, %H:%M:%S',
        columns: this.data.secondaryChartData
      };
    } else {
      dataObj = this.data.secondaryChartDataObject;
    }

    this.generateSecondaryObj = {
      bindto: !isNullOrUndefined(this.data.secondaryBindingId) ? '#' + this.data.secondaryBindingId : "#secondaryChart",
      data: dataObj,
      axis: this.data.secondaryAxis || {},
      regions: [],
      zoom: this.data.secondaryZoom || {},
      subchart: this.data.secondarySubchart || {},
      size: this.data.size || {},
      padding: this.data.padding || {}
    }
    this.secondaryChart = c3.generate(this.generateSecondaryObj);
  }

  dateChange(event, type) {
    switch (type) {
      case 0: this.startDate = new Date(event.value); break;
      default: this.endDate = new Date(event.value); break;
    }
    this.objMinDate = this.startDate;
    this.objMaxDate = new Date();
    this.objStartMaxDate = this.endDate;
  }

  onOptionChange(key) {
    if (key.value == 'custom') {
      this.blnShowdatepicker = true;
    } else {
      this.eventEmit.emit(key.value);
      this.blnShowdatepicker = false;
    }
  }

  onSubmitRange() {
    this.eventEmit.emit({ 'startDate': this.startDate, 'endDate': this.endDate });
  }

  toggleOverLay() {
    if (this.blnShowOverLay) {
      this.generateObj.regions = [];
    } else {
      this.generateObj.regions = this.data.overlayData;
    }
    this.blnShowOverLay = !this.blnShowOverLay;
    c3.generate(this.generateObj);
    this.detectChanges();
  }

  export() {
    new AngularCsv(this.objExportData, this.exportFileName, this.objExportOptions);
  }

  changeMaxEndTime() {
    this.objMaxDate = new Date();
    this.objStartMaxDate = this.endDate;
  }

  async loadGraphInChunks(generateObj) {
    let loadInChunks = false;
    if (!isNullOrUndefined(generateObj.data['columns'])) {
      if (generateObj.data['columns'].length < 1) {
        this.chart = c3.generate(this.generateObj);
        this.detectChanges();
      } else {
        let start = 0;
        let end = generateObj.data['columns'][0].length;
        let tempDataArr = generateObj.data['columns'].slice();
        tempDataArr.forEach((x, i) => {
          if (x.length > this.CHUNK_SIZE) {
            loadInChunks = true;
            generateObj.data['columns'][i] = x.slice(0, start + 9999);
          }
        });

        // load initial data
        this.chart = c3.generate(this.generateObj);
        this.detectChanges();

        if (loadInChunks) {
          // load chunks every 2 secs
          while (start < end) {
            this.numLoadPercentage = Number((generateObj.data['columns'][0].length / end * 100).toFixed(2));
            this.loadPercentage.emit(this.numLoadPercentage);
            await this.delay(2000);
            start = ((start + this.CHUNK_SIZE) > end) ? end : (start + this.CHUNK_SIZE);
            tempDataArr.forEach((x, i) => {
              generateObj.data['columns'][i].push(...x.slice(start, start + this.CHUNK_SIZE));
            });
            if (this.generateObj.regions && !this.blnShowOverLay) {
              this.generateObj.regions = [];
            }
            c3.generate(this.generateObj);
            this.detectChanges();
          }
          this.numLoadPercentage = 0;
          this.loadPercentage.emit(null);
          this.detectChanges();
        }
      }
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  toggleDurationToCustom() {
    if (this.objChartOptions.findIndex(x => x.key == 'custom') > -1) {
      this.selected = "custom";
    }
  }
}
