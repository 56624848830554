import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Amplify, { API, Auth } from 'aws-amplify';
import amplify from './aws-exports';
import cognito_info from './cognitos/cognito-info.json'

import { I18n } from 'aws-amplify';

I18n.setLanguage('en');
const dict = {
     'en': {
         'Username': 'Enter your email',
         'Username *': 'Email *'
      }
}
I18n.putVocabularies(dict);

try {
	let cognito = cognito_info.info;
	amplify.aws_cognito_region = cognito.aws_cognito_region;
	amplify.aws_user_pools_id = cognito.aws_user_pools_id;
	amplify.aws_user_pools_web_client_id = cognito.aws_user_pools_web_client_id;
	amplify.Analytics={disabled:true};
}catch(e) {
  console.log(e);
}
Amplify.configure(amplify);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));