import { Component, OnInit } from '@angular/core';
import { SvgBaseComponent } from '../../svgbase.component';

@Component({
  selector: 'unknown-icon',
  templateUrl: './unknown.component.html',
  styleUrls: ['./unknown.component.sass']
})
export class UnknownComponent extends SvgBaseComponent {
}
