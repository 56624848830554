import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { isNullOrUndefined } from 'util';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private loaderService: LoaderService
  ) {}

  public getEndpointPromise(method = 'GET', url: string, data: any, endpoint: string, loaderCounter = true): Promise<any> {
    let promise: Promise<any>;
    switch(method) {
      case 'POST': {
        promise = API.post(endpoint, url, data);
        break;
      }
      case 'PUT': {
        promise = API.put(endpoint, url, data);
        break;
      }
      case 'DELETE': {
        promise = API.del(endpoint, url, data);
        break;
      }
      default: {
        promise = API.get(endpoint, url, data);
        break;
      }
    }

    if(!isNullOrUndefined(promise)) {
      if(loaderCounter) this.loaderService.incrementCounter();
      return promise.then(response => {
        return response;
      }).catch(error => {
        throw error.response;
      }).finally(() => {
        if(loaderCounter) this.loaderService.decrementCounter();
      })
    }
  }
}
