import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CustomModalService } from 'src/app/services/custom-modal.service';
import { Subscription } from 'rxjs';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { isNullOrUndefined, isNumber } from 'util';

@Component({
  selector: 'threshold',
  templateUrl: './threshold.component.html',
  styleUrls: ['./threshold.component.scss']
})
export class ThresholdComponent implements OnInit {
  customModalSub: Subscription;

  alertName: string = '';
  error_message: string = '';
  warning_message: string = '';

  threshold_list: object[] = [];
  original_threshold_list: object[] = [];
  number_type_thresholds: string[] = ["lessThan", "lessThanInclusive", "greaterThan", "greaterThanInclusive", "equal", "notEqual"];

  disable_edit: boolean = false;

  @Output() dataEmitter = new EventEmitter<any>(false);

  constructor(
    private customModalService: CustomModalService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.disable_edit = this.data.attrs.read_only ? true : false;
    this.alertName = this.data.attrs.alert_name;
    this.threshold_list = this.data.attrs.threshold_list.filter(x => true);
    this.original_threshold_list = JSON.parse(JSON.stringify(this.threshold_list));
    this.warning_message = "no_threshold_values_updated";

    this.customModalSub = this.customModalService.customModalObs.subscribe(res => {
      let return_object = {
        alert_id: this.data.attrs.alert_id,
        threshold_list: this.convertThresholds(this.threshold_list)
      }
      if (this.error_message == "" && this.warning_message == "") this.dataEmitter.emit(return_object);
    });
  }

  convertThresholds(threshold_list) {
    threshold_list.map(x => {
      if (this.number_type_thresholds.includes(x.operator)) {
        x.threshold = this.normalizeDataType(x.threshold); // if (isNumber(x.threshold)) x.threshold = parseFloat(x.threshold);
      }
    });
    return threshold_list;
  }

  // For some reason the threshold is found to be string, trying to force convert it number which most alerts operate on
  normalizeDataType(threshold) {
    try {
      return parseFloat(threshold);
    } catch (error) {
      return threshold;
    }
  }

  ngOnDestroy(): void {
    if (this.customModalSub) { this.customModalSub.unsubscribe(); }
  }

  onChange(w){
    if (!this.checkIfValuesUpdated()) {
      this.error_message = "";
      this.warning_message = "no_threshold_values_updated";
    } else {      
      this.warning_message = '';
      for (let i = 0; i < this.threshold_list.length; i++) {
        this.error_message = !isNullOrUndefined(this.threshold_list[i]['threshold']) ? (this.threshold_list[i]['threshold'] == "" ? "value_cannot_be_empty" : "") : "";
        if (this.error_message == "") this.error_message = !isNullOrUndefined(this.threshold_list[i]['threshold']) ? (isNaN(this.threshold_list[i]['threshold']) ? "value_not_a_number" : "") : "";
        if (this.error_message == "") this.error_message = this.min_max_validator(this.threshold_list[i]);
        if (this.error_message != "") break;
      }
    }
  }

  min_max_validator(current_threshold) {
    let error = "";
    let filtered_list = this.threshold_list.filter(x => x['threshold_field'] != current_threshold.threshold_field);
    let threshold = filtered_list.find(x => x['fact'] == current_threshold.fact);
    if (threshold) {
      let minVal = null;
      let maxVal = null;

      if (current_threshold.operator.toLowerCase() == "lessthan" && threshold['operator'].toLowerCase() == "greaterthan") {
        minVal = parseFloat(current_threshold.threshold);
        maxVal = parseFloat(threshold['threshold']);
      } else if (current_threshold.operator.toLowerCase() == "greaterthan" && threshold['operator'].toLowerCase() == "lessthan") {
        minVal = parseFloat(threshold['threshold']);
        maxVal = parseFloat(current_threshold.threshold);
      }
      
      if (minVal > maxVal)
        error = "min_cannot_be_greater_than_max";
      if (minVal == maxVal)
        error = "min_cannot_be_equal_to_max";
    }
    return error;
  }

  checkIfValuesUpdated() {
    let updated = false;
    for (let i = 0; i < this.threshold_list.length; i++) {
      let threshold_obj = this.original_threshold_list.find(y => y['fact'] == this.threshold_list[i]['fact'] && y['threshold_field'] == this.threshold_list[i]['threshold_field']);
      if (threshold_obj) {
        if (threshold_obj['threshold'] != this.threshold_list[i]['threshold']) { updated = true; return true; }
      }
    }
    return updated;
  }

}
