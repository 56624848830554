import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotifyService } from 'src/app/services/notify.service';
import { Router, NavigationEnd } from '@angular/router';
import { Routes, mainNavLinks } from 'src/app/models/constants/constants';
import { Subscription } from 'rxjs';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  currentUser: any;
  userName: string = "";
  orgLogo: string = "/assets/images/logo1.png";
  orgName: string = "";

  arrMainNavLinks = mainNavLinks;
  
  blnDisableHamburger: boolean = false;
  blnEnableAdminMenu: boolean = false;

  routerSubscription: Subscription;
  currentUserDataChangeSubscription: Subscription;
  objRouter: Router;

  constructor(
    private authenticationService: AuthenticationService,
    private notifyService: NotifyService,
    private userService: UsersService,
    private router: Router
  ) {
    this.objRouter = router;
  }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUser().then(x => {
      this.userName = x.attributes.name
      this.currentUser.email = x.signInUserSession.idToken.payload['email'];
    });

    this.routerSubscription = this.router.events.subscribe(x => {
      if(x instanceof NavigationEnd) {
        this.blnDisableHamburger = (this.router.url.includes(Routes.dashboard)) ? true : false;
      }
    });

    // this.currentUserDataChangeSubscription = this.notifyService.currentUserDataChange.subscribe(res => {    
    //   if(res) {
    //     let acl = res.acl;
    //     if(acl.is_admin || (acl.groups && acl.groups.write.length > 0)) {
    //       this.blnEnableAdminMenu = true;
    //     }else{
    //       this.blnEnableAdminMenu = false;
    //     }
    //   }
    // });
    this.blnEnableAdminMenu = true;
  }

  ngOnDestroy() {
    if(this.routerSubscription) { this.routerSubscription.unsubscribe(); }
    if(this.currentUserDataChangeSubscription) { this.currentUserDataChangeSubscription.unsubscribe(); }
  }

  logout() {
    this.notifyService.notifyLogout();
    this.authenticationService.signOut();
  }

  navToggle(){
    this.notifyService.notifynavToggle();
  }

}
