import { Component, ChangeDetectorRef, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { NotifyService } from '../services/notify.service';
import { TranslateService } from '@ngx-translate/core';
import { AngularCsv } from 'angular7-csv';

// @Component({
//     changeDetection: ChangeDetectionStrategy.OnPush,
//     encapsulation: ViewEncapsulation.None
// })

export abstract class BaseClass implements OnDestroy {
    constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected notifyService: NotifyService,
        protected translateService: TranslateService
    ) { }

    ngOnDestroy() {
        this.detach();
    }

    detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.markForCheck();
            this.changeDetectorRef.detectChanges();
        }
    }

    detach() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detach();
        }
    }

    public setTranslateDefaults(){
        this.translateService.setDefaultLang('en');
        this.translateService.use('en');
    }

    public exportData(headers, export_data, export_file_name) {
        let export_options= {
            showLabels: true,
            headers: headers
        };
        new AngularCsv(export_data, export_file_name, export_options);
    }
}