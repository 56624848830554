export class CustomDefer {
  private _resolve:any;
  private _reject:any;
  private _promise:any;
  
  constructor() {
    this.create();
  }
  private create() {
    let scope = this;
    this._promise = new Promise(function(resolve, reject) {
      scope._resolve = resolve;
      scope._reject = reject;
    });
    this._promise.then(() => {}, () => {}); // create default then for the case of nobody set receiver to avoid exception.
  }

  resolve(data?:any) {
    this._resolve(data);
  }

  reject(error?:any) {
    this._reject(error);
  }

  then(then:any, error?:any, final?:any) {
    if (!then) then=function(r?:any) {};
    if (!error) error=function(e?:any) {};
    if (final)
      return this._promise.then(then, error).finally(final);
    else
      return this._promise.then(then, error);
  }

  // You cannot use then(...).catch(...);
  // Instead, use then( () => {}, (error) => {});
  // catch(f:any) {
  //   return this._promise.catch(f);
  // }

  finally(f:any) {
    return this._promise.finally(f);
  }

  get promise() {
    return this._promise;
  }
}