import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class NotifyService {

    selectTreeNode: Observable<any>;
    private selectTreeNodeSubject = new Subject();

    searchParamsChange: Observable<any>;
    private searchParamsChangeSubject = new Subject();

    navToggle: Observable<any>;
    private navToggleSubject = new Subject();

    groupSelected: Observable<any>;
    private groupSelectedSubject = new Subject();

    refreshGroupData: Observable<any>;
    private refreshGroupDataSubject = new Subject();

    userSelected: Observable<any>;
    private userSelectedSubject = new Subject();

    refreshUserData: Observable<any>;
    private refreshUserDataSubject = new Subject();

    printerSelected: Observable<any>;
    private printerSelectedSubject = new Subject();

    adminAdapterSelected: Observable<any>;
    private adminAdapterSelectedSubject = new Subject();

    refreshAdminAdapterData: Observable<any>;
    private refreshAdminAdapterDataSubject = new Subject();

    refreshPrinterData: Observable<any>;
    private refreshPrinterDataSubject = new Subject();

    refreshSubgroupTreeData: Observable<any>;
    private refreshSubgroupTreeDataSubject = new Subject();

    commonLayoutSidebarToggle: Observable<any>;
    private commonLayoutSidebarToggleSubject = new Subject();

    commonLayoutLeftComponentEmitter: Observable<any>;
    private commonLayoutLeftComponentEmitterSubject = new BehaviorSubject(false);

    logout: Observable<any>;
    private logoutSubject = new BehaviorSubject(false);

    dynamicChartDataChange: Observable<any>;
    private dynamicChartDataChangeSubject = new Subject();

    searchFilterDataChange: Observable<any>;
    private searchFilterDataChangeSubject = new Subject();

    staticInfoDataChange: Observable<any>;
    private staticInfoDataChangeSubject = new BehaviorSubject(false);

    currentUserDataChange: Observable<any>;
    private currentUserDataChangeSubject = new BehaviorSubject(false);

    followTabSelected: Observable<any>;
    private followTabSelectedSubject = new BehaviorSubject(false);

    historyParamsChange: Observable<any>;
    private historyParamsChangeSubject = new BehaviorSubject(false);

    printerTagChange: Observable<any>;
    private printerTagChangeSubject = new Subject();

    allFollowingDevicesPageLoad: Observable<any>;
    private allFollowingDevicesPageLoadSubject = new Subject();

    customGridDataChange:Observable<any>;
    private customGridDataChangeSubject = new Subject();

    printerInfoChange: Observable<any>;
    private printerInfoChangeSubject = new BehaviorSubject(false);

    followingPrinterInfo: Observable<any>;
    private followingPrinterInfoSubject = new BehaviorSubject(false);

    updateStatePrinterCount: Observable<any>;
    private updateStatePrinterCountSubject = new Subject();

    startCalculateStatePrinterCount: Observable<any>;
    private startCalculateStatePrinterCountSubject = new Subject();

    shutdownCommandInitiated: Observable<any>;
    private shutdownCommandInitiatedSubject = new Subject();

    printerStatusOrder: Observable<any>;
    private printerStatusOrderSubject = new BehaviorSubject(false);

    deployedAdapterSelected: Observable<any>;
    private deployedAdapterSelectedSubject = new Subject();

    constructor() {
        this.selectTreeNode = this.selectTreeNodeSubject.asObservable();
        this.searchParamsChange = this.searchParamsChangeSubject.asObservable();
        this.navToggle = this.navToggleSubject.asObservable();
        this.groupSelected = this.groupSelectedSubject.asObservable();
        this.refreshGroupData = this.refreshGroupDataSubject.asObservable();
        this.userSelected = this.userSelectedSubject.asObservable();
        this.refreshUserData = this.refreshUserDataSubject.asObservable();
        this.printerSelected = this.printerSelectedSubject.asObservable();
        this.refreshPrinterData = this.refreshPrinterDataSubject.asObservable();
        this.commonLayoutSidebarToggle = this.commonLayoutSidebarToggleSubject.asObservable();
        this.refreshSubgroupTreeData = this.refreshSubgroupTreeDataSubject.asObservable();
        this.commonLayoutLeftComponentEmitter = this.commonLayoutLeftComponentEmitterSubject.asObservable();
        this.logout = this.logoutSubject.asObservable();
        this.dynamicChartDataChange = this.dynamicChartDataChangeSubject.asObservable();
        this.searchFilterDataChange = this.searchFilterDataChangeSubject.asObservable();
        this.staticInfoDataChange = this.staticInfoDataChangeSubject.asObservable();
        this.currentUserDataChange = this.currentUserDataChangeSubject.asObservable();
        this.followTabSelected = this.followTabSelectedSubject.asObservable();
        this.historyParamsChange = this.historyParamsChangeSubject.asObservable();
        this.printerTagChange = this.printerTagChangeSubject.asObservable();
        this.allFollowingDevicesPageLoad = this.allFollowingDevicesPageLoadSubject.asObservable();
        this.customGridDataChange = this.customGridDataChangeSubject.asObservable();
        this.printerInfoChange = this.printerInfoChangeSubject.asObservable();
        this.followingPrinterInfo = this.followingPrinterInfoSubject.asObservable();
        this.updateStatePrinterCount = this.updateStatePrinterCountSubject.asObservable();
        this.startCalculateStatePrinterCount = this.startCalculateStatePrinterCountSubject.asObservable();
        this.refreshAdminAdapterData = this.refreshAdminAdapterDataSubject.asObservable();
        this.adminAdapterSelected = this.adminAdapterSelectedSubject.asObservable();
        this.shutdownCommandInitiated = this.shutdownCommandInitiatedSubject.asObservable();
        this.printerStatusOrder = this.printerStatusOrderSubject.asObservable();
        this.deployedAdapterSelected = this.deployedAdapterSelectedSubject.asObservable();
    }

    notifySelectTreeNode(data: any) {
        this.selectTreeNodeSubject.next(data);
    }

    notifyParamsChangeToSearchFilter(data: any) {
        this.searchParamsChangeSubject.next(data);
    }

    notifynavToggle() {
        this.navToggleSubject.next(true);
    }

    notifygroupSelected(data) {
        this.groupSelectedSubject.next(data);
    }

    notifyGroupDataRefresh(){
        this.refreshGroupDataSubject.next(true);
    }

    notifyuserSelected(data) {
        this.userSelectedSubject.next(data);
    }

    notifyUserDataRefresh(){
        this.refreshUserDataSubject.next(true);
    }

    notifyprinterSelected(data) {
        this.printerSelectedSubject.next(data);
    }

    notifyAdminAdapterSelected(data) {
        this.adminAdapterSelectedSubject.next(data);
    }

    notifyPrinterDataRefresh(){
        this.refreshPrinterDataSubject.next(true);
    }

    notifySubgroupTreeDataRefresh(){
        this.refreshSubgroupTreeDataSubject.next(true);
    }

    notifycommonLayoutSidebarToggle(closed?: boolean){
        this.commonLayoutSidebarToggleSubject.next(closed);
    }

    notifyCommonLayoutLeftComponentEmitter(data){
        this.commonLayoutLeftComponentEmitterSubject.next(data);
    }

    notifyLogout() {
        LocalStorageService.clear(LocalStorageService.Key_FilterCriteria);
        this.logoutSubject.next(true);
    }

    notifyDynamicChartDataChange(data) {
        this.dynamicChartDataChangeSubject.next(data);
    }

    notifySearchFilterDataChange(data) {
        this.searchFilterDataChangeSubject.next(data);
    }

    notifyStaticInfoChange(data) {
        this.staticInfoDataChangeSubject.next(data);
    }

    notifyCurrentUserDataChange(data) {
        this.currentUserDataChangeSubject.next(data);
    }

    notifyFollowTabSelected(data) {
        this.followTabSelectedSubject.next(data);
    }

    notifyHistoryParamsChange(data) {
        this.historyParamsChangeSubject.next(data);
    }

    notifyPrinterTagChangeSubject(data) {
        this.printerTagChangeSubject.next(data);
    }

    notifyAllFollowingDevicesPageLoadSubject(data) {
        this.allFollowingDevicesPageLoadSubject.next(data);
    }

    notifyCustomGridDataChangeSubject(data){
        this.customGridDataChangeSubject.next(data);
    }
    notifyPrinterInfoChangeSubject(data) {
        this.printerInfoChangeSubject.next(data);
    }

    notifyFollowingPrinterInfoSubject(data) {
        this.followingPrinterInfoSubject.next(data);
    }

    notifyUpdateStatePrinterCountSubject(data: any) {
        this.updateStatePrinterCountSubject.next(data);
    }

    notifyStartCalculateStatePrinterCountSubject(data: any) {
        this.startCalculateStatePrinterCountSubject.next(data);
    }

    notifyShutdownCommandInitiatedSubject(data: any) {
        this.shutdownCommandInitiatedSubject.next(data);
    }

    notifyPrinterStatusOrderSubject(data: any) {
        this.printerStatusOrderSubject.next(data);
    }

    notifyDeployedAdapterSelected(data) {
        this.deployedAdapterSelectedSubject.next(data);
    }
}

