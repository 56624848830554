import { Component, OnInit, Inject, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatChipInputEvent, MatSnackBar, MatAutocompleteSelectedEvent } from '@angular/material';
import { isNullOrUndefined } from 'util';
import { Observable, Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AlertMessage } from 'src/app/models/alertMessage';
import { PrintersService } from 'src/app/services/printers.service';
import { NotifyService } from 'src/app/services/notify.service';
import { BaseClass } from 'src/app/models/baseClass';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'tags-modal',
  templateUrl: './tags-modal.component.html',
  styleUrls: ['./tags-modal.component.scss']
})
export class TagsModalComponent extends BaseClass implements OnInit {
  printerForm: FormGroup;

  companyTags: string[] = [];
  originalTags: string[] = [];
  arrTags: string[] = [];
  toRemoveTags: string[] = [];
  filteredTags: Observable<string[]>;

  blnTagsEdited: boolean;
  objAlertMessage: AlertMessage;
  tagModalSubscription: Subscription;

  company: string;
  deviceId: string;
  strTitle: string;

  @ViewChild('tagInput') tagInput: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<TagsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackbar: MatSnackBar,
    private printerService: PrintersService,
    translateService: TranslateService,
    public loaderService: LoaderService,
    notifyService: NotifyService,
    changeDetectorRef: ChangeDetectorRef,
  ) {
      super(changeDetectorRef, notifyService, translateService);
      this.objAlertMessage = new AlertMessage(this.translateService, this.snackbar);
  }

  ngOnInit() {
    this.company = this.data.company;
    this.deviceId = this.data.device_id;

    this.printerForm = new FormGroup({
      tag: new FormControl(null)
    });
    this.detectChanges();
    this.getCompanyTags();
  }

  onButtonClick(msg) {
    this.dialogRef.close(msg);
  }

  private _filter(value: string): string[] {
    if (!isNullOrUndefined(value)) {
      // const filterValue = value.toLowerCase();
      const filterValue = value.toLowerCase().replace(/[^a-z0-9]/g, '-').replace(/[-]+/g, '-').replace(/^-|-$/g, '');
      return this.companyTags.filter(option => option.toLowerCase().includes(filterValue));
    }
  }

getPrinterDetails() {
  if (this.deviceId) {
    this.printerService.getAWSPrinter(this.deviceId).then(data => {
      if (data) {
        console.log(data);
        // this.printerDetailsData = data;
        this.setData(data);
      } else {
        // this.noData = true;
        this.objAlertMessage.showError('100', null, 6000);
      }
    });
  }
}

setData(data) {
  let printerGroups = [];
  //this.printerDetailsData.group_ids = [];
  if (data.groups.in) {
    if (data.groups.in.tagged) {
      this.originalTags = data.groups.in.tagged
      .filter( x => x.startsWith(`/${this.company}/tags`))
      .map( x => x.split('/')
      .pop());
    } else {
      this.originalTags = [];
    }
  }
  this.arrTags = this.originalTags.slice();
  this.blnTagsEdited = false;
}

getCompanyTags() {
  this.printerService.getAWSCompanyTags(this.company).then(data => {
      if (data.statusCode === 200) {
        this.companyTags = data.body.results.map(x => x.groupPath.split('/').pop());
        this.filteredTags = this.printerForm.controls.tag.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      }
    }).then(x => {
      this.getPrinterDetails();
    });
}

onTagsSave() {
  const toAddTags: string[] = [];
  const toRemoveTags: string[] = [];
  const toCreateTags: string[] = [];

  this.arrTags.forEach(x => {
    if (this.originalTags.findIndex(y => y === x) < 0) {
      toAddTags.push(x);
    }
    if (this.companyTags.findIndex(z => z === x) < 0) {
      toCreateTags.push(x);
    }
  });
  this.originalTags.forEach(x => {
    if (this.arrTags.findIndex(y => y === x) < 0) {
      toRemoveTags.push(x);
    }
  });
  console.log(toCreateTags);
  console.log(toAddTags);
  console.log(toRemoveTags);

  let failed = false;
  const bar = new Promise((resolve) => {
    if (toCreateTags.length === 0) {
      resolve();
    }
    toCreateTags.forEach((x, index, array) => {
      this.printerService.createAWSTag(this.company, x).then(data => {
        if (data.statusCode === 200) {
          console.log('create tag success');
        } else {
          failed = true;
        }
        if (index === array.length - 1) { resolve(); }
      });
    });
  });
  bar.then(() => {
    console.log('All create done!');
    if (failed) {
      this.objAlertMessage.showError('device_tags_update_failed');
      this.blnTagsEdited = false;
      return;
    }
    this.getCompanyTags();
    failed = false;
    if (toAddTags.length > 0) {
      const bar2 = new Promise((resolve) => {
        toAddTags.forEach((x, index, array) => {
          this.printerService.attachAWSTag(this.company, x, [this.deviceId]).then(data => {
            if (data.statusCode === 200) {
              const ret = JSON.parse(data.body);
              if (ret.Fail === this.deviceId) {
                failed = true;
              }
            } else {
              failed = true;
            }
            if (index === array.length - 1) { resolve(); }
          });
        });
      });
      bar2.then(() => {
        console.log('All attach done!');
        if (failed) {
          this.objAlertMessage.showError('device_tags_update_failed', null, 5000);
          this.blnTagsEdited = false;
          this.getPrinterDetails();
          return;
        }
        this.objAlertMessage.showSuccess('device_tags_update_success');
        this.getPrinterDetails();
      });
    }
  });

  failed = false;
  if (toRemoveTags.length > 0) {
    const bar3 = new Promise((resolve) => {
      toRemoveTags.forEach((x, index, array) => {
        this.printerService.detachAWSTag(this.company, x, [this.deviceId]).then(data => {
          if (data.statusCode === 200) {
            const ret = JSON.parse(data.body);
            if (ret.Fail === this.deviceId) {
              failed = true;
            }
          } else {
            failed = true;
          }
          if (index === array.length - 1) { resolve(); }
        });
      });
    });
    bar3.then(() => {
      console.log('All remove done!');
      if (failed) {
        this.objAlertMessage.showError('device_tags_update_failed', null, 5000);
        this.blnTagsEdited = false;
        this.getPrinterDetails();
        return;
      }
      this.objAlertMessage.showSuccess('device_tags_update_success');
      this.getPrinterDetails();
    });
  }
  this.blnTagsEdited = false;
}

onTagsCancel() {
  this.blnTagsEdited = false;
  this.arrTags = this.originalTags.slice();
}

addToTagsArray(event: MatChipInputEvent) {
  const val = event.value.toLowerCase().replace(/[^a-z0-9]/g, '-').replace(/[-]+/g, '-').replace(/^-|-$/g, '');
  const existingIndex = this.arrTags.findIndex(x => x === val.trim());
  if (existingIndex >= 0) {
    this.objAlertMessage.showError('tag_exists_in_list', null, 4000);
  }
  if (val !== '' && existingIndex < 0) {
      this.blnTagsEdited = true;
      this.arrTags.push(val);
  }
  event.input.value = '';
}

removeFromTagsArray(tag) {
  this.blnTagsEdited = true;
  const index = this.arrTags.indexOf(tag);
  if (index >= 0) {
    this.arrTags.splice(index, 1);
  }
}

onTagOptionSelect(event: MatAutocompleteSelectedEvent): void {
  if (!this.arrTags.includes(event.option.viewValue)) {
    this.arrTags.push(event.option.viewValue);
  }
  this.tagInput.nativeElement.value = '';
  this.blnTagsEdited = true;
}

onChange(event) {
    event.target.value = event.target.value.toLowerCase().replace(/[^a-z0-9]/g, '-').replace(/[-]+/g, '-');
  }
}
