export const serverUrls = {
  "dev": {
		mtms:"https://mtms-3dc-dev.herokuapp.com/api/v1",
		facade:"https://api2-dev.3dconnect.3dsystems.com/facade",
		facade2:"https://api2-dev.3dconnect.3dsystems.com/facade2",
		userMgmt:"https://api2-dev.3dconnect.3dsystems.com/usermanagement",
		devicerRegistration:"https://api2-dev.3dconnect.3dsystems.com/registration"
	},
	"sandbox": {
		mtms:"https://mtms-3dc-dev.herokuapp.com/api/v1",
		"hydra2": "https://jwoudyzsp2.execute-api.us-west-2.amazonaws.com/dev/",
		"timeseries": "https://0rqlsoqmfl.execute-api.us-west-2.amazonaws.com/dev/",
		"hydra2-users": "https://q7ljhl04g4.execute-api.us-west-2.amazonaws.com/dev",
		cdf: "https://urj85yb4i7.execute-api.us-west-2.amazonaws.com/Stage",
		awsUserMgmt:"https://q7ljhl04g4.execute-api.us-west-2.amazonaws.com/dev",
		assetLibrary:"https://urj85yb4i7.execute-api.us-west-2.amazonaws.com/Stage",
		deviceRegistration:"https://tut5k1gxk8.execute-api.us-west-2.amazonaws.com/prod",
		connectionData: "https://bpz8ryzzh8.execute-api.us-west-2.amazonaws.com/dev"
	},
	"staging": {
  		mtms:"https://mtms-3dc-dev.herokuapp.com/api/v1",
		facade:"https://api2-staging.3dconnect.3dsystems.com/facade",
		facade2:"https://api2-staging.3dconnect.3dsystems.com/facade2",
		userMgmt:"https://api2-staging.3dconnect.3dsystems.com/usermanagement",
		devicerRegistration:"https://api2-staging.3dconnect.3dsystems.com/registration"
	},
	"production": {
		mtms:"https://mtms-3dc-prod.herokuapp.com/api/v1",
		facade:"https://api2-prod.3dconnect.3dsystems.com/facade",
		facade2:"https://api2-prod.3dconnect.3dsystems.com/facade2",
		userMgmt:"https://api2-prod.3dconnect.3dsystems.com/usermanagement",
		devicerRegistration:"https://api2-prod.3dconnect.3dsystems.com/registration"
	}
};
