import { Component, Input } from '@angular/core';
import { SvgBaseComponent } from '../../svgbase.component';

@Component({
    selector: 'close-icon',
    templateUrl: './close.component.html',
    styles:['./close.component.css']
})
export class CloseComponent extends SvgBaseComponent{

}