import { Component, OnInit, ComponentFactoryResolver, ViewChild, ViewContainerRef, Input, OnDestroy, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { NotifyService } from 'src/app/services/notify.service';
import { ComponentDictionary } from 'src/app/models/componentDictionary';
import { MatDrawer } from '@angular/material';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { Routes } from 'src/app/models/constants/constants';

@Component({
  selector: 'common-layout',
  templateUrl: './common-layout.component.html',
  styleUrls: ['./common-layout.component.scss']
})

export class CommonLayoutComponent implements OnInit, OnDestroy, OnChanges {
  toggleSidebarSubscription: Subscription;
  blnHideContentContainer: boolean;

  @Input() leftComponent: ComponentDictionary;
  @Input() leftComponentWidthPercent: number = 0;
  @Input() sideBarComponent: ComponentDictionary;
  @Input() sideBarType: string = 'side';
  @Input() sideBarPosition: string = 'start';
  @Input() isSideBarOpened: boolean = false;
  @Input() openSideBar: boolean = false;
  @Input() showLinkBar: boolean = false;
  @Input() linkData: any[] = [];

  @Output() emittedEvent = new EventEmitter();

  @ViewChild('sideBarDrawer') sideBarDrawer: MatDrawer;
  @ViewChild('leftComponentContainer', { read: ViewContainerRef }) leftComponentContainer: ViewContainerRef;
  @ViewChild('sideBarComponentContainer', { read: ViewContainerRef }) sideBarComponentContainer: ViewContainerRef;

  constructor(
    private resolver: ComponentFactoryResolver,
    private notifyService: NotifyService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.blnHideContentContainer = (this.leftComponentWidthPercent == 100);
    if (this.leftComponent) {
      this.createLeftComponent();
    }
    if (this.sideBarComponent) {
      this.createSideBarComponent();
      this.toggleSidebarSubscription = this.notifyService.commonLayoutSidebarToggle.subscribe(res => {
        if (res) {
          this.sideBarDrawer.close();
        } else if(res == false) {
          this.sideBarDrawer.open();
        }
        // else {
        //   this.sideBarDrawer.toggle();
        // }
      });
    }
  }

  ngAfterViewInit() {
    if(this.router.url.includes(Routes.dashboard)){
      let device_menu_panel = document.getElementsByClassName('left-panel') as HTMLCollectionOf<HTMLElement>;;
      if (device_menu_panel.length != 0) {
        device_menu_panel[0].style['min-width'] = "280px";
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
  }

  ngOnDestroy() {
    if (this.toggleSidebarSubscription) { this.toggleSidebarSubscription.unsubscribe(); }
  }

  createLeftComponent() {
    this.leftComponentContainer.clear();
    let factory = this.resolver.resolveComponentFactory(this.leftComponent.component);
    let componentRef = this.leftComponentContainer.createComponent(factory);
    // bind input
    // componentRef.instance.message = message;

    // listen to output which is defined in "output" property of leftComponent
    if (this.leftComponent.data && this.leftComponent.data.output) {
      componentRef.instance[this.leftComponent.data.output].subscribe(val => this.emittedEvent.emit(val));
    }
  }

  createSideBarComponent() {
    this.sideBarComponentContainer.clear();
    let factory = this.resolver.resolveComponentFactory(this.sideBarComponent.component);
    let componentRef = this.sideBarComponentContainer.createComponent(factory);
  }

  linkRedirect(item) {
      this.router.navigate([item.link]);
  }

  closeSideBar() {
    this.sideBarDrawer.close();
    this.notifyService.notifycommonLayoutSidebarToggle(true);
  }

}
