import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotifyService } from './services/notify.service';
import { LocalStorageService } from './services/local-storage.service';
import { AutoLogoutService } from './services/auto-logout.service';
import { AuthenticationService } from './services/authentication.service';
import { CustomModalService } from './services/custom-modal.service';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './interceptors/loader.interceptor';



import { AmplifyService } from 'aws-amplify-angular';
import { DatePipe } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatSidenavModule, MatMenuModule, MatSnackBarModule, MatButtonModule, MatTooltipModule } from '@angular/material';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { SvgModule } from './modules/svg/svg.module';
import { MatDividerModule } from '@angular/material/divider';
import { GlobalErrorHandler } from './services/global-error-handler';
import { SharedModule } from './modules/shared/shared.module';
import { UtilModuleModule } from './modules/util-module/util-module.module';

@NgModule({
  declarations: [
    AppComponent,
    MainHeaderComponent
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatSidenavModule,
    MatMenuModule,
    MatButtonModule,
    MatTooltipModule,
    SvgModule,
    MatDividerModule,
    MatSnackBarModule,
    UtilModuleModule,
    SharedModule
  ],
  providers: [
    NotifyService,
    LocalStorageService,
    AutoLogoutService,
    CustomModalService,
    AmplifyService,
    AuthenticationService,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    DatePipe,
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  entryComponents: [
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
