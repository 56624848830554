import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, ElementRef, SimpleChanges } from '@angular/core';

@Component({
  selector: 'dynamic-stacked-chart',
  templateUrl: './dynamic-stacked-chart.component.html',
  styleUrls: ['./dynamic-stacked-chart.component.scss']
})

export class DynamicStackedChartComponent implements OnChanges {
  @Input() inputChartData: object[];
  @Input() height: string = "20em";
  @Input() width: string = "100%";
  @Input() showExportIcon: boolean = false;
  @ViewChild('myCanvas')
  @Output('eventEmit') chartEmit = new EventEmitter();
  @Output() chartEmiter: EventEmitter<any> = new EventEmitter();
  @Output('onExportClickEvent') onExportClickEvent = new EventEmitter();

  objMinDate: any;
  objMaxDate: any;
  objStartMaxDate: any;
  startDate: any;
  endDate: any;

  colorArr: any[];
  public canvas: ElementRef;
  public context: CanvasRenderingContext2D;

  public chartLabels: any[];
  public chartColors: any[];
  public chartDataSet: object;
  public chartType = 'bar';
  chartOptions: any;
  selected: string = 'default';

  numLoadPercentage: number = 0;

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.inputChartData = changes.inputChartData.currentValue;
    this.chartLabels = this.inputChartData["labels"];
    this.chartDataSet = this.inputChartData["datasets"];
    this.chartColors = this.inputChartData["colors"];
    this.chartOptions = this.inputChartData["options"];
  }

  setStartEndTime() {
    // this.startDate = this.options["start"] || new Date();
    // this.endDate = this.options["end"] || new Date();
    // this.objMinDate = this.startDate;
    // this.objMaxDate = this.endDate;
    // this.objStartMaxDate = this.endDate;
  }

  dateChange(event, type) {
    switch (type) {
      case 0: this.startDate = new Date(event.value); break;
      default: this.endDate = new Date(event.value); break;
    }
    this.objMinDate = this.startDate;
    this.objMaxDate = new Date();
    this.objStartMaxDate = this.endDate;
  }

  onSubmitRange() {
    let obj = {
      'startDate': this.startDate,
      'endDate': this.endDate
    };
    this.chartEmiter.emit(obj)
  }

  toggleDurationToCustom() {
    if (this.chartOptions.findIndex(x => x.key == 'custom') > -1) {
      this.selected = "custom";
    }
  }

  onOptionChange(key) {
    this.chartEmiter.emit(key.value);
  }

  onExportClick() {
    this.onExportClickEvent.emit(this.inputChartData);
  }

}
