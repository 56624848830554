import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { NotifyService } from 'src/app/services/notify.service';
import { Subscription, Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { PrintersService } from 'src/app/services/printers.service';
import { startWith, map } from 'rxjs/operators';
import { MatCheckbox, MatCheckboxChange, MatAutocompleteSelectedEvent } from '@angular/material';
import { printerModelList2 } from 'src/app/models/constants/constants'
import { SFRegions, DefaultPrinterStatePreference } from 'src/app/models/constants/constants'
import { PrinterStateMappings } from 'src/app/models/constants/constants'
import { deployments } from 'src/environments/deployments';
import { TitleCasePipe } from '@angular/common';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit {
  company = deployments.company;
  filterForm: FormGroup;

  printerStatusData: any[] = [];
  statusOrder: any[] = [];
  modelData: any[];
  printerStateDataFromStaticInfo: any[] = [];

  totalCount: number = 0;

  staticInfoDataChangeSubscription: Subscription;
  searchParamsChangeSubscription: Subscription;
  printerTagUpdatedSubscription: Subscription;
  formValueChangeSubscription: Subscription;
  updateStatePrinterCountSubscription: Subscription;
  startCalculateStatePrinterCountSubscription: Subscription;
  printerStatusOrderSubscription: Subscription;


  existingTags: string[];
  tagList: string[] = [];
  regionData: string[] = [];
  filteredTags: Observable<string[]>;

  @Output() searchInput = new EventEmitter<any>(false);

  @ViewChild('selectAllCheckBox') selectAllCheckBox: MatCheckbox;
  @ViewChild('tagInput') tagInput: ElementRef;

  constructor(
    private notifyService: NotifyService,
    private _formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private printerService: PrintersService,
    private titleCasePipe: TitleCasePipe
  ) { }

  ngOnInit() {
    this.printerStatusData = DefaultPrinterStatePreference;
    this.printerStatusOrderSubscription = this.notifyService.printerStatusOrder.subscribe(order => {
      if (order) {
        this.printerStatusData = order;
      }
    });
    this.regionData = SFRegions;
    this.initForm();
    this.getExisitingTags();
    this.modelData = printerModelList2;
    this.searchParamsChangeSubscription = this.notifyService.searchParamsChange.subscribe(params => {
      if (params) {
        this.statusOrder = params.status_order;
        this.onFilterParamsChange(params);
      } else {
        this.initForm();
      }
    });

    this.printerTagUpdatedSubscription = this.notifyService.printerTagChange.subscribe(data => {
      this.getExisitingTags();
    });
    this.startCalculateStatePrinterCountSubscription = this.notifyService.startCalculateStatePrinterCount.subscribe(data => {
      this.displayStartCounting(data);
    });
    this.updateStatePrinterCountSubscription = this.notifyService.updateStatePrinterCount.subscribe(data => {
      this.updateCount(data);
    });
    this.printerStatusOrderSubscription.unsubscribe();
  }

  ngOnDestroy(): void {
    if (this.staticInfoDataChangeSubscription) { this.staticInfoDataChangeSubscription.unsubscribe() }
    if (this.searchParamsChangeSubscription) { this.searchParamsChangeSubscription.unsubscribe(); }
    if (this.printerTagUpdatedSubscription) { this.printerTagUpdatedSubscription.unsubscribe(); }
    if (this.formValueChangeSubscription) { this.formValueChangeSubscription.unsubscribe(); }
    if (this.updateStatePrinterCountSubscription) { this.updateStatePrinterCountSubscription.unsubscribe(); }
    if (this.startCalculateStatePrinterCountSubscription) { this.startCalculateStatePrinterCountSubscription.unsubscribe(); }
  }

  detectChanges() {
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    }
  }

  initForm() {
    this.selectAllCheckBox.checked = false;

    this.filterForm = this._formBuilder.group({
      'printer_status': this._formBuilder.array([]),
      'model': this._formBuilder.control(null),
      'name': this._formBuilder.control(null),
      'nickname': this._formBuilder.control(null),
      // 'adapter': this._formBuilder.control(null),
      'region': this._formBuilder.control(null),
      'tag': this._formBuilder.control(null),
      'ipAddress': this._formBuilder.control(null)
    });
    this.formValueChangeSubscription = this.filterForm.valueChanges.subscribe(x => {
      if (this.modelData && x.model) {
        this.selectAllCheckBox.checked = (x.model.length == this.modelData.length);
      }
    });
    this.buildPrinterStatusFormArray();
  }

  onFilterApply() {
    this.buildPrinterStatusFormArray();
    let data = { ...this.filterForm.value };
    let selected_printer_status_indexes = [];
    data.printer_status.map((x, i) => {
      if (x.status) {
        selected_printer_status_indexes.push(i);
      }
    });
    let selected_printer_statuses = this.printerStatusData.filter((x, i) => {
      return selected_printer_status_indexes.includes(i);
    });
    let statusArr = [];
    selected_printer_statuses.forEach(x => {
      PrinterStateMappings.forEach(y =>{
        if(x['value'] == Object.keys(y)){
          let status = Object.values(y)[0];
          status.forEach(status => {
            statusArr.push(this.titleCasePipe.transform(status)); 
          });
        }
      });
    });
    data.printer_status = statusArr;
    data.status_order = this.statusOrder;
    data.tag = this.tagList.toString();
    // data.printer_status = selected_printer_statuses;

    // data.tag = this.tagList;
    data.model = data.model ? data.model : [];
    if(data.name) {
      data.name = data.name.trim();
      //If user choose group model and type printer name remove the model filter
      if(data.model && data.model != [] && data.model.length == 2){
        data.model = []
      }
    }else{
      data.name = null
    }
    //data.name = data.name ? data.name.trim() : null;
    this.notifyService.notifySearchFilterDataChange(data);
  }
  displayStartCounting(data) {
    this.buildPrinterStatusFormArray()
    this.printerStatusData.forEach((x) => {
      if(data.find(y => y[x.value])){
        x.totalCount = "..."
      }
    });
  }
  updateCount(data) {
    let state = Object.keys(data.state)[0]
    //console.log(this.printerStatusData[state])
    if(state){
      let fdata;
      if(fdata = this.printerStatusData.find(y => y.value == state)){
        fdata.totalCount = data.count;
        this.detectChanges();
      }
    }
  }

  onFilterReset() {
    this.initForm();
    this.tagList = [];
    let data = { ...this.filterForm.value };
    data.printer_status = [];
    if (this.selectAllCheckBox.checked) this.selectAllCheckBox.checked = false;
    this.notifyService.notifySearchFilterDataChange(data);
  }

  onSearch(searchInput) {
    this.searchInput.next(searchInput);
  }

  buildPrinterStatusFormArray(printerStatusArr?: []) {
    let formArray = this.filterForm.get('printer_status') as FormArray;
    this.printerStatusData.forEach((x) => {
      x.totalCount = "0"
      formArray.push(this._formBuilder.group({
        status: (printerStatusArr && printerStatusArr.find(y => x.key == y['key'])) ? true : false
      }));
    });
    this.detectChanges();
  }

  onFilterParamsChange(data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        switch (key) {
          case 'model': this.filterForm.patchValue({ 'model': data[key] }); break;
          // case 'adapter': this.filterForm.patchValue({ 'adapter': data[key] }); break;
          case 'model': this.filterForm.patchValue({ 'model': isNullOrUndefined(data[key]) ? null : data[key][0] }); break;
          case 'name': this.filterForm.patchValue({ 'name': data[key] }); break;
          case 'nickname': this.filterForm.patchValue({ 'nickname': data[key] }); break;
          case 'region': this.filterForm.patchValue({ 'region': data[key] }); break;
          case 'tag': {
            this.tagList = (data[key] == "" || isNullOrUndefined(data[key])) ? [] : (typeof data[key] == "string" ? data[key].split(',') : data[key]);
            this.filterForm.patchValue({ 'tag': null });
            break;
          }
          case 'ipAddress': this.filterForm.patchValue({ 'ipAddress': data[key] }); break;
          case 'printer_status': {
            if (!isNullOrUndefined(data[key])) {
              let lower_case_data = data[key].map(a => a.toLowerCase());
              let appliedStatusIndex = [];
              this.printerStatusData.forEach((x, i) => {
                let exists = false;
                let statuses = PrinterStateMappings.find(k => Object.keys(k)[0] == x.value);
                let difference = statuses[x.value].filter(t => lower_case_data.indexOf(t.toLowerCase()) == -1);
                if (difference.length == 0) exists = true;
                // data[key].forEach(y => {
                  // console.log(x);
                  // let lowercase_array = x.value.map(v => v.toLowerCase());
                  // if (x.value == y.toLowerCase()) {
                  //   exists = true;
                  // }
                // });
                if (exists) appliedStatusIndex.push(i);
              });
              this.filterForm.controls['printer_status']['controls'].map((x, i) => {
                x.patchValue({ 'status': appliedStatusIndex.includes(i) });
              });
            } else {
              this.filterForm.controls['printer_status']['controls'].map(x => {
                x.patchValue({ 'status': false });
              });
            }
            break;
          }
          case 'state_count': {
            if (Object.entries(data[key]).length > 0) {
              this.printerStatusData = data.state_count;
              this.totalCount = 0;
              this.printerStatusData.forEach(x => {
                this.totalCount += x.totalCount;
              });
              this.buildPrinterStatusFormArray(data.printer_status);
              this.detectChanges();
            }
            break;
          }
        }
      }
    }
    this.detectChanges();
  }

  async getExisitingTags() {
    this.printerService.getAWSCompanyTags(this.company).then(data => {
      if (data.statusCode == 200) {
        this.existingTags = data.body.results.map(x => x.groupPath.split('/').pop());
        // console.log(this.existingTags);
        this.filteredTags = this.filterForm.controls.tag.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
      }
    });

  }

  private _filter(value: string): string[] {
    if (!isNullOrUndefined(value)) {
      // const filterValue = value.toLowerCase();
      const filterValue = value.toLowerCase().replace(/[^a-z0-9]/g, '-').replace(/[-]+/g, '-').replace(/^-|-$/g, '');
      return this.existingTags.filter(option => option.toLowerCase().includes(filterValue));
    }
  }

  onChange(event) {
    event.target.value = event.target.value.toLowerCase().replace(/[^a-z0-9]/g, '-').replace(/[-]+/g, '-');
  }

  modelChange(event: MatCheckboxChange) {
    if (event.checked)
      this.filterForm.patchValue({ 'model': this.modelData.map(x => x.value) });
    else this.filterForm.patchValue({ 'model': [] });
    this.filterForm.markAsDirty();
    this.onFilterApply();
  }

  removeFromTagList(tag): void {
    const index = this.tagList.indexOf(tag);

    if (index >= 0) {
      this.tagList.splice(index, 1);
    }
    this.filterForm.markAsDirty();
  }

  onTagOptionSelect(event: MatAutocompleteSelectedEvent): void {
    if (!this.tagList.includes(event.option.viewValue))
      this.tagList.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
  }

  clearInput() {
    this.tagInput.nativeElement.value = '';
  }

  getToolTipData(typ: string) {
    let data = { ...this.filterForm.value };
    if (data) {
      if (typ == 'model') {
        return data.model;
      } else if (typ == 'region') {
        return data.region;
      }

    }
  }
}
