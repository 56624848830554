import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

export class AlertMessage {
    constructor(
        private translateService: TranslateService,
        private snackBar: MatSnackBar
    ) { }

    showSuccess(messageKey: string): void {
        this.translateService.get('messages.' + messageKey).subscribe((msg: string) => {
            this.snackBar.open(msg, null, {
                panelClass: ['notify-success'],
                duration: 4000,
                verticalPosition: 'top'
            });
        });
    }

    showError(messageKey: string, action?, duration?: number, additionalMessage: string = ""): void {
        this.translateService.get('messages.' + messageKey).subscribe((msg: string) => {
            let message = (additionalMessage != "") ? (msg + ": " + additionalMessage) : msg;
            this.snackBar.open(message, (action) ? action : null, {
                panelClass: ['notify-error'],
                duration: (duration) ? duration : null,
                verticalPosition: 'top'
            });
        });
    }

    showInfo(messageKey: string, action?, duration?: number, additionalMessage: string = ""): void {
        this.translateService.get('messages.' + messageKey).subscribe((msg: string) => {
            let message = (additionalMessage != "") ? (msg + ": " + additionalMessage) : msg;
            this.snackBar.open(message, (action) ? action : null, {
                panelClass: ['notify-info'],
                duration: (duration) ? duration : null,
                verticalPosition: 'top',
                horizontalPosition: 'end'
            });
        });
    }
}