import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomDefer } from 'src/app/models/customDefer';
import { Auth } from 'aws-amplify';
import { Printer } from 'src/app/models/printerNew';

import { LoaderService } from './loader.service';
import { CommonService } from './common.service';
import { NotifyService } from './notify.service';
import { serverUrls } from 'src/environments/server-urls';
import { deployments } from 'src/environments/deployments';
import { saveAs } from 'file-saver';
import { isNullOrUndefined } from 'util';
import { timer, from } from 'rxjs'
import { map, concatMap, filter, take, switchMap, tap, delay } from 'rxjs/operators'
import { interval } from 'd3';
import { Observable } from 'rxjs/Rx';
import { TitleCasePipe } from '@angular/common';
import * as _ from 'lodash';
import { PrinterStateMappings } from 'src/app/models/constants/constants';
import { convertUTCtoPrinterTimezone, isNullOrUndefinedOrEmpty } from '../functions/common-functions';

@Injectable({
  providedIn: 'root'
})
export class PrintersService {
  company = deployments.company;
  alldevices = null;
  devices = null;
  devices2 = null;
  groups = null;
  device = null;
  staticInfo = null;
  private accessToken = null;
  adminAdapters = null;
  adminAdaptersLoaded = false;
  printerDetailsCache: object[] = [];

  deployedAdapters = null;

  constructor(
    private http: HttpClient,
    public loaderService: LoaderService,
    private commonService: CommonService,
    private notifyService: NotifyService,
    private titleCasePipe: TitleCasePipe
  ) { }

  setAccessToken(token) {
    this.accessToken = token;
  }


  public registerAdapters(adapters: any) {
    let url = `/devices`;
    let data = {
      headers: {},
      body: { adapters: adapters }
    }

    this.loaderService.show();
    return this.commonService.getEndpointPromise('POST', url, data, 'deviceRegistration')
      .finally(() => {
        this.loaderService.hide();
      });
  }

  public removeAdapters(adapters: any) {
    let url = `/devices`;
    let data = {
      headers: {},
      body: { adapters: adapters }
    }

    this.loaderService.show();
    return this.commonService.getEndpointPromise('DELETE', url, data, 'deviceRegistration')
      .finally(() => {
        this.loaderService.hide();
      });
  }

  public getAWSCompanyTags(company: any) {
    let url = `/companies/${company}/tags`;

    // this.loaderService.show();
    return this.commonService.getEndpointPromise('GET', url, { headers: {} }, 'facade2');
      // .finally(() => {
      //   this.loaderService.hide();
      // });
  }

  public createAWSTag(company: any, tag: any) {
    let url = `/companies/${company}/tags`;
    let data = {
      headers: {},
      body: { tag_name: tag }
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('POST', url, data, 'facade2')
      .finally(() => {
        this.loaderService.hide();
      });
  }
  public attachAWSTag(company: any, tag: any, printer_ids: any) {
    let url = `/companies/${company}/tags/${tag}/attach`;
    let data = {
      headers: {},
      body: { printer_ids: printer_ids }
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('PUT', url, data, 'facade2')
      .finally(() => {
        this.loaderService.hide();
      });

  }
  public detachAWSTag(company: any, tag: any, printer_ids: any) {
    let url = `/companies/${company}/tags/${tag}/detach`;
    let data = {
      headers: {},
      body: { printer_ids: printer_ids }
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('PUT', url, data, 'facade2')
      .finally(() => {
        this.loaderService.hide();
      });
  }

  public getAWSPrinter(printerid: any, showLoader = true) {
    //This is temporary call directlry from AL, Will crreate Gateway endpoint later
    let url = `/devices/${printerid}`;
    if (showLoader) this.loaderService.show();
    return this.commonService.getEndpointPromise('GET', url, { headers: {} }, 'facade')
    .then(res => {
      if (!this.printerDetailsCache.find(x => x["printerName"] == printerid)) {        
        let obj = {
          'printerName': printerid,
          'fullModel': res.attributes.fullModel,
          'timeZone': res.attributes.timezone,
          'model': res.attributes.model,
          'submodel': res.attributes.submodel
        }
        this.printerDetailsCache.push(obj);
      }
      return res
    })
    .finally(() => {
      if (showLoader) this.loaderService.hide();
    });
  }

  public getPrinterDetailsFromCache(printerId: any) {
    let data = {};
    if (this.printerDetailsCache.length > 0) {
      data = this.printerDetailsCache.find(x => x['printerName'] == printerId);
    }
    return data;
  }

  public getAlertDefinitions(printerid: any) {
    return this.commonService.getEndpointPromise('GET', `/printers/${printerid}/alertdefinitions`, { headers: {} }, 'alertService')
  }

  public getGroupsData(data?: any): CustomDefer {
    let defer = new CustomDefer();
    if (data == "public" && this.groups) {
      defer.resolve(this.groups);
    } else {
      this.loaderService.show();
      this.commonService.getEndpointPromise('GET', `/groups/get-group-subgroups?company=${this.company}&group=${data}`, { headers: {} }, 'facade').then(response => {
        if (data == "public") {
          this.groups = response;
        }
        defer.resolve(response);
      }).catch(error => {
        console.log(error);
        defer.resolve([]);
      }).finally(() => {
        this.loaderService.hide();
      });
    }
    return defer;
  }

  public getMTMSDevicesData(refresh?: boolean): CustomDefer {
    let defer = new CustomDefer();
    if (this.devices && !refresh) {
      defer.resolve(this.devices);
    } else {
      this.loaderService.show();
      this.commonService.getEndpointPromise('GET', `/devices?per_page=1000`, { headers: {} }, 'mtms').then(response => {
        this.devices = response.contents;
        defer.resolve(response.contents);
      }).catch(error => {
        defer.reject();
        console.log(error);
      }).finally(() => {
        this.loaderService.hide();
      });
    }
    return defer;
  }



  public getMTMSDeviceData(id: any) {
    this.loaderService.show();
    return this.commonService.getEndpointPromise('GET', `/devices/${id}?detail=true`, { headers: {} }, 'mtms').finally(() => {
      this.loaderService.hide();
    });
  }

  public getAllDeviceInfo(filterData?, pagenumber?, pagesize?) {
    //printers/alldeviceinfo
    let url = `/printers/alldeviceinfo?pagenumber=${pagenumber}&pagesize=${pagesize}`;
    if (filterData) {
      if (filterData.adapterId) url += `&adapterId=${filterData.adapterId}`;
      if (filterData.modelNumber) url += `&modelNumber=${filterData.modelNumber}`;
      if (filterData.printerids) url += `&printerids=${filterData.printerids}`;
      if (filterData.printername) url += `&printeridstring=${filterData.printername}`;
      if (filterData.printerState) url += `&printerState=${filterData.printerState}`;
      if (filterData.region) url += `&region=${filterData.region}`;
      if (filterData.tag) url += `&tags=${filterData.tag}`;
      if (filterData.customer) url += `&customerName=${filterData.customer}`;
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('GET', url, { headers: {} }, 'printerService').finally(() => {
      this.loaderService.hide();
    });
  }

  public updateMTMSDeviceData(id: number, params) {
    let data = {
      headers: {},
      body: params
    }

    this.loaderService.show();
    return this.commonService.getEndpointPromise('PUT', `/devices/${id}`, data, 'mtms').finally(() => {
      this.loaderService.hide();
    });
  }

  public getPrinterTriggeredAlerts(printerids: any, start: string, end: string, alertName?: string, propertyName?: string, fromID?: number, toID?: number, firstID?: number, lastID?: number) {
    let url = `/alert-service/printers/${printerids}/triggeredalerts?start=${start}&end=${end}`;
     if (alertName) url += `&alert_name=${alertName}`;
     if (propertyName) url += `&property_name=${propertyName}`;
    return this.commonService.getEndpointPromise('GET', url, { headers: {} }, 'facade');
  }

  public getPrinterAlertDefinition(model, company_id = "3d systems, inc.") {
    return this.commonService.getEndpointPromise('GET', `/alert-service/companies/${company_id}/models/${model}/alerts`, { headers: {} }, 'facade')
    // .finally(() => {
    //   this.loaderService.hide();
    // });
  }

  public getGranularPrinterAlertDefinition(printer_id) {
    return this.commonService.getEndpointPromise('GET', `/alert-service/printers/${printer_id}/alerts`, { headers: {} }, 'facade')
    // .finally(() => {
    //   this.loaderService.hide();
    // });
  }

  public updateCompanyAlertThreshold(model, alert_id, threshold_obj, company_id = '3d systems, inc.') {
    let data = {
      headers: {},
      body: {
        "attributes_to_update": threshold_obj
      }
    }
    return this.commonService.getEndpointPromise('PUT', `/alert-service/companies/${company_id}/models/${model}/alerts/${alert_id}`, data, 'facade')

  }

  public updateIndividualPrinterAlertThreshold(printer_id, alert_id, threshold_obj) {
    let data = {
      headers: {},
      body: {
        "attributes_to_update": threshold_obj
      }
    }
    return this.commonService.getEndpointPromise('PUT', `/alert-service/printers/${printer_id}/alerts/${alert_id}`, data, 'facade')

  }

  public getStaticInfo(refresh?: boolean) {
    // this.loaderService.show();
    // return this.commonService.getEndpointPromise('GET', `/printers/getStaticInfo`, { headers: {} }, 'printerService').finally(() => {
    //   this.loaderService.hide();
    // });

    let defer = new CustomDefer();
    if (this.staticInfo && !refresh) {
      defer.resolve(this.staticInfo);
    } else {
      this.loaderService.show();
      this.commonService.getEndpointPromise('GET', `/printers/getStaticInfo`, { headers: {} }, 'printerService').then(response => {
        this.staticInfo = response;
        this.notifyService.notifyStaticInfoChange(response);
        defer.resolve(response);
      }).catch(error => {
        defer.reject();
        console.log(error);
      }).finally(() => {
        this.loaderService.hide();
      });
    }
    return defer;
  }

  public getPrinterPropertyHistory(params, reportDataRequested?) {
    let data = {
      headers: {},
      body: params
    }
    return this.commonService.getEndpointPromise('POST', `/printers/propertyTimeline?reportDataRequested=${reportDataRequested}`, data, 'printerService');
  }

  public getPrinterTimelineHistory(params) {
    let data = {
      headers: {},
      body: params
    }
    return this.commonService.getEndpointPromise('POST', `/printers/printerTimelineHistory`, data, 'printerService');
  }

  public getAllPrinterProperties(printerid: any) {
    return this.commonService.getEndpointPromise('GET', `/printers/${printerid}/getAllPrinterProperties`, { headers: {} }, 'printerService')
  }

  public getPrinterAlertPropertyHistory(params) {
    let data = {
      headers: {},
      body: params
    }
    return this.commonService.getEndpointPromise('POST', `/alerts/propertiesHistory`, data, 'alertService');
  }

  public subscribeToAlert(printerId, alertId?) {
    let data = {
      headers: {},
      body: null
    }
    let url = (alertId) ? `/printers/${printerId}/alerts/${alertId}/subscribe` : `/printers/${printerId}/alerts/subscribeToAll`;
    this.loaderService.show();
    return this.commonService.getEndpointPromise('POST', url, data, 'alertService').finally(() => {
      this.loaderService.hide();
    });
  }

  public unsubscribeAlert(printerId, alertId?) {
    let data = {
      headers: {},
      body: null
    }
    let url = (alertId) ? `/printers/${printerId}/alerts/${alertId}/unsubscribe` : `/printers/${printerId}/alerts/unsubscribeFromAll`;
    this.loaderService.show();
    return this.commonService.getEndpointPromise('DELETE', url, data, 'alertService').finally(() => {
      this.loaderService.hide();
    });
  }

  public getPrinterStatusesFromGroupOrOrganization(id, isOrganization = false) {
    //printers/alldeviceinfo
    let url = ``;
    if (isOrganization) {
      url = `/printers/alldeviceinfo?organizationId=${id}`;
    } else {
      url = `/printers/alldeviceinfo?groupId=${id}`;
    }

    this.loaderService.show();
    return this.commonService.getEndpointPromise('GET', url, { headers: {} }, 'printerService').finally(() => {
      this.loaderService.hide();
    });
  }

  public getSensorDataCSV(printerid: any, startDate, endDate, propertyNamesCsv?: string) {
    const url = serverUrls[deployments.stage].printerService + `/printers/${printerid}/sensor/getExportDataCSV?start=${startDate}&end=${endDate}`;
    const headers = new HttpHeaders()
      .set('Authorization', this.accessToken);

    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'arraybuffer';
      withCredentials?: boolean;
    } = {
      responseType: 'arraybuffer',
      headers
    };

    return this.http.post(url, { propertyNames: propertyNamesCsv }, options).pipe(
      map((file: ArrayBuffer) => {
        return file;
      })
    );
  }

  public getPrinterAdapterDetails(printerid: any) {
    this.loaderService.show();
    return this.commonService.getEndpointPromise('GET', `/printers/${printerid}/adapterDetails`, { headers: {} }, 'printerService')
      .finally(() => {
        this.loaderService.hide();
      });
  }

  public refreshSalesforceInfo(printerId: string) {
    return this.commonService.getEndpointPromise('PUT', `/devices/${printerId}/refresh_salesforce_info`, {}, 'mtms');
  }

  public clearSubgroupTreeData() {
    this.groups = null;
  }

  public setGroupData(data) {
    this.groups = data;
  }

  public clearStaticInfoData() {
    this.staticInfo = null;
    this.notifyService.notifyStaticInfoChange(null);
  }

  public updatePrinterProperty(printerid: string, property: string, value: string) {
    const data = {
      headers: {},
      body: { property, value }
    };
    return this.commonService.getEndpointPromise('PUT', `/printers/${printerid}/updatePrinterProperty`, data, 'printerService')
  }
  ///////////////////////////////////
  // HYDRA - 2.0 APIS

  // API 1 - allsensorinfo
  public getAllSensorInfo2(printerid: any, fetch_derived_props?, fetch_timezone_info?) {
    this.loaderService.show();
    let url = `/printers/${printerid}/sensor/allsensorinfo`;
    if (fetch_derived_props) url += `?derived_props=true`;
    if (fetch_timezone_info) url += `${fetch_derived_props ? '&' : '?'}fetch_timezone_info=true`;
    return this.commonService.getEndpointPromise('GET', url, { headers: {} }, 'facade')
      .finally(() => {
        this.loaderService.hide();
      });
  }

  // API 2 - jobinfo
  public getAllJobData2(printerid: any, status?: string, start?, end?) {
    this.loaderService.show();
    let url = `/printers/${printerid}/jobs?status=${status}&start=${start}&end=${end}`;
    return this.commonService.getEndpointPromise('GET', url, { headers: {} }, 'facade')
      .finally(() => {
        this.loaderService.hide();
      });
  }

  public getAllJobDataAsync(printerid: any, status?: string, start?, end?) {
    this.loaderService.show();
    this.loaderService.incrementCounter();
    const url = `/printers/${printerid}/jobs?status=${status}&start=${start}&end=${end}`;
    const payload = { headers: {} };
    const execStatus = ['completed', 'failed', 'cancelled'];

    return this.commonService.getEndpointPromise('GET', url, payload, 'facade2', false)
    .then(
      res => {
        const body = JSON.parse(res['body']);
        return Observable.interval(5000)
              .concatMap(() => this.http.get(body.url))
              .startWith(0)
              .filter(x => ((x['status'] === 'complete') || x['status'] === 'error'))
              .take(1)
              .timeout(120000)
              .toPromise().then(data => {
                return data;
              }).finally(() => {this.loaderService.decrementCounter(); this.loaderService.hide(); });
      }
    );
  }

  //API 3 - sensorspecific
  public getSpecificSensorDetails2(printerid: any, property: string) {
    // this.loaderService.show();
    let url = `/printers/${printerid}/sensor/${property}`;
    //https://jwoudyzsp2.execute-api.us-west-2.amazonaws.com/dev/printers/32004-LMP53/sensor/adapter_id
    return this.commonService.getEndpointPromise('GET', url, { headers: {} }, 'facade')
      // .then(res =>{
      //   console.log(url);
      //   console.log(res);
      // })
      .finally(() => {
        // this.loaderService.hide();
      });
  }


  //API 4 - timeseries data
  public getHistoricDataBackup(printerId: string, property: string, startDate?, endDate?, scrollId?, originalArr = [], size?, order?, singleCall?) {
    this.loaderService.show();
    let allRec: any[] = originalArr;
    let defaultSize = 10000;
    let runLoop = (singleCall) ? false : true;
    let data = {
      headers: {
        'Cache-Control': 'no-cache'
      },
      body: {
        "deviceId": printerId,
        "uuid": property,
        "startDate": startDate,
        "endDate": endDate,
        "scrollId": scrollId,
        "size": size ? size : defaultSize,
        "order": order ? order : 'asc',
        "scrollable": runLoop
      }
    }

    return this.commonService.getEndpointPromise('POST', `/timeseries`, data, 'timeseries-data')
      .then(res => {

        if (res && res.body && res.body.hits) {
          this.loaderService.show();
          allRec = allRec.concat(res.body.hits.hits);
          if (!isNullOrUndefined(res.body._scroll_id)) {
            var scrollId = res.body._scroll_id;
          }
          return ( !runLoop || isNullOrUndefined(scrollId) ) ? allRec : this.getHistoricData(printerId, property, startDate, endDate, scrollId, allRec, size, order);
        }

      })
      .finally(() => {
        this.loaderService.hide();
      });
  }

  public getHistoricData(printerId: string, property: string, startDate?, endDate?, scrollId?, originalArr = [], size?, order?) {
    this.loaderService.show();
    let allRec: any[] = originalArr;
    let data = {
      headers: {
        'Cache-Control': 'no-cache'
      },
      body: {
        "deviceId": printerId,
        "uuid": property,
        "startDate": startDate,
        "endDate": endDate,
        "scrollId": scrollId,
        "size": size ? size : undefined,
        "order": order ? order : 'asc'
      }
    }

    return this.commonService.getEndpointPromise('POST', `/timeseries`, data, 'timeseries-data')
      .then(res => {

        if (res && res.body && res.body.hits) {
          this.loaderService.show();
          let noOfRec = res.body.hits.hits.length;
          let recLimit = 9999;
          allRec = allRec.concat(res.body.hits.hits);
          if (noOfRec > recLimit) {
            var scrollId = res.body.hits.hits[recLimit]._id;
          }
          return (noOfRec != 10000) ? allRec : this.getHistoricData(printerId, property, startDate, endDate, scrollId, allRec);
        }

      })
      .finally(() => {
        this.loaderService.hide();
      });
  }


  // API 5 - fetch printer list for particular user
  public getAWSPrintersData(status?, models?, regions?, deviceIds?, tag?, submodel?, refresh?): CustomDefer {
    let defer = new CustomDefer();
    let url = '/printers';
    if (status)
      url = '/printers?status=' + status;
    if (!isNullOrUndefined(models) && models.length) {
      url = (url.indexOf('?') >= 0) ? url + '&fullModel=' + models : url + '?fullModel=' + models
    }
    if (!isNullOrUndefined(regions) && regions.length) {
      url = (url.indexOf('?') >= 0) ? url + '&region=' + regions : url + '?region=' + regions
    }
    if (!isNullOrUndefined(deviceIds) && deviceIds.length) {
      url = (url.indexOf('?') >= 0) ? url + '&deviceId=' + deviceIds : url + '?deviceId=' + deviceIds
    }
    if (!isNullOrUndefined(tag) && tag.length) {
      url = (url.indexOf('?') >= 0) ? url + '&tag=' + tag : url + '?tag=' + tag
    }
    if (!isNullOrUndefined(submodel) && submodel.length) {
      url = (url.indexOf('?') >= 0) ? url + '&submodel=' + submodel : url + '?submodel=' + submodel
    }

    // if (this.devices && !refresh) {
    //   defer.resolve(this.devices);
    // } else {
      this.loaderService.show();
      this.commonService.getEndpointPromise('GET', url, { headers: {} }, 'facade').then(response => {
        this.devices = response;
        defer.resolve(response);
      }).catch(error => {
        defer.reject();
      }).finally(() => {
        this.loaderService.hide();
      });
    // }
    return defer;
  }

  public getAWSPrintersData2(count?, offset?, hideLoader?): CustomDefer {
    let defer = new CustomDefer();
    let url = `/whitelist?whitelist_owner=3ds&not_attached=false&count=${count}&offset=${offset}`;
    if (this.devices2) {
      console.log('printers exists!')
      defer.resolve(this.devices2);
    } else {
      if (!hideLoader) this.loaderService.show();
      this.commonService.getEndpointPromise('GET', url, { headers: {} }, 'facade').then(response => {
        defer.resolve(response);
      }).catch(error => {
        defer.reject();
      }).finally(() => {
        this.loaderService.hide();
      });
    }
    return defer;
  }

  public setDevices(data) {
    this.devices2 = data;
  }

  public setAdminAdapters(data) {
    this.adminAdapters = data;
   }

  //API 6 - follow printer 
  public followPrinter(username, deviceId, companyId) {
    let data = {
      headers: {},
      body: { 'username': username, 'printer': deviceId }
    }
    return this.commonService.getEndpointPromise('PUT', `/profile/${companyId}/followprinter`, data, 'userMgmt')
  }


  //API 7 - unfollow printer 
  public unfollowPrinter(username, deviceId, companyId) {
    let data = {
      headers: {},
      body: { 'username': username, 'printer': deviceId }
    }
    return this.commonService.getEndpointPromise('PUT', `/profile/${companyId}/unfollowprinter`, data, 'userMgmt')
  }

  //API 8 - delete Individual printer alert
  public deleteIndividualPrinterAlert(printer_id, alert_id) {
    let data = {
      headers: {},
      body: null
    }
    return this.commonService.getEndpointPromise('DELETE', `/alert-service/printers/${printer_id}/alerts/${alert_id}`, data, 'facade')
  }

  //API 9 - get printer historic connection data
  public getPrinterConnectionData(printerId: any, start?, end?) {
    let data = {
      headers: {},
      body: {
        "device_id": printerId,
        "interval_start": start,
        "interval_end": end
      }
    }

    this.loaderService.show();
    return this.commonService.getEndpointPromise('POST', '/printers/connection-data', data, 'connectionData')
      .finally(() => {
        this.loaderService.hide();
      });
  }

  public getGranularPrinterThresholds(printer_id) {
    return this.commonService.getEndpointPromise('GET', `/alert-service/printers/${printer_id}/alertsv1`, { headers: {} }, 'facade');
  }

  public getGranularPrinterDefinitionFromThresholdData(printer_id, body_data) {
    let data = {
      headers: {},
      body: body_data
    }
    return this.commonService.getEndpointPromise('POST', `/alert-service/printers/${printer_id}/alertsv1`, data, 'facade');
  }

  public getTelemetryCount(printer_ids: string, telemetry_type, start_time, end_time) {
    let data = {
      headers: {},
      body: {
        "printers": printer_ids,
        "telemetry_type": telemetry_type,
        "start": start_time,
        "end": end_time
      }
    }
    return this.commonService.getEndpointPromise('POST', '/printers/printer/telemetry-count', data, 'facade');
  }

  // DASHBOARD : devices API
  cartesianProduct(arr) {
    return arr.reduce(function (a, b) {
      return a.map(function (x) {
        return b.map(function (y) {
          return x.concat([y]);
        })
      }).reduce(function (a, b) { return a.concat(b) }, [])
    }, [[]])
  }

  prepareUrl(arr) {
    let url = '/devices?type=printer&count=20';
    arr.forEach((x, i) => {
      if (x) {
        switch (i) {
          case 0: url = url + '&eq=status:' + x; break;
          case 1: url = url + '&eq=fullModel:' + x; break;
          case 2: url = url + '&startsWith=fullDeviceId:' + x; break;
          // case 2: url = url + '&startsWith=serialNumber:' + x; break;
          case 3: url = url + '&eq=alias:' + x; break;
          case 4: url = url + '&eq=region:' + x; break;
          case 5: x.split(/\s*,\s*/).forEach(tag => {
            url = url + '&exist=tagged:in:groupPath:/3ds/tags/' + tag;
          });     break;
          case 6: url = url + '&eq=ipAddress:' + x; break;
          case 7: url = url + '&startsWith=serialNumber:' + x; break;
          default: url; break
        }
      }
    });
    return url;
  }

  //Dashboard : get printers count for each state
  public getPrintersCount(state, allStates, filterObj) {
    // console.log(state);
    // console.log(allStates);
    // console.log(filterObj)
    let allCount: any = 0;
    let initialUrlArr = [];
    let filterArr = [];
    let name_filter_included = null;
    const keys = Object.keys(filterObj)
    for (const key of keys) {
      if(key =='printer_status'){
        filterArr.push([null]);
        // // console.log(filterArr)
      }else if (key != 'status_order' && key != 'model_group') {
        let val = filterObj[key];
        if(key == 'name' && val){
          name_filter_included = val;
        }
        if (Array.isArray(val) && val.length > 0) {
          filterArr.push(val)
        }
        else if (val && val.length > 0)
          filterArr.push(val.split())
        else
          filterArr.push([null])
      }
    }
    // console.log(filterArr)
    let cartesianArr = this.cartesianProduct(filterArr);
    // console.log(cartesianArr)
    if(filterObj.hasOwnProperty('model_group')){
      name_filter_included = null;
    }
    if(name_filter_included){
      let name_filter_arr = JSON.parse(JSON.stringify(cartesianArr));
      name_filter_arr.forEach(x => {
        x[2] = null
        x.push(name_filter_included)
      });
      cartesianArr = cartesianArr.concat(name_filter_arr);
    }
    // console.log(cartesianArr)
    cartesianArr.forEach(element => {
      let url = this.prepareUrl(element);
      initialUrlArr.push(url);
    });
    // console.log(initialUrlArr)
    let countUrls = this.setupStateUrls(state, allStates, initialUrlArr)
    // console.log(countUrls)
    let promise = new Promise((resolve, reject) => {
      let rindex = 0
      countUrls.forEach((x, index, array) => {
        this.commonService.getEndpointPromise('GET', x, { headers: {} }, 'facade', false).then(res => {
          // console.log(res)
          rindex = rindex+1;
          allCount = allCount + res.total;
          if(rindex == array.length) resolve({data:allCount});
        }).catch(error => {
          reject("error");
        })
      });
    });

    return promise;
  }

  setupStateUrls(state, allStates, initialUrlArr = []) {
    let states = Object.keys(state).map(key => state[key]);
    // console.log(states) 
    let retArr = []
    let state_query = "";
    if(states[0].length == 1){
      state_query = '&eq=status:' + this.titleCasePipe.transform(states[0][0])
    }else{
      let not_eq_sate = _.difference(allStates,states[0])
      not_eq_sate.forEach(x=>{
        state_query = state_query + ("&neq=status:" + this.titleCasePipe.transform(x.toString()))
      })
    }
    initialUrlArr.forEach(x => {
      retArr.push(x + state_query + "&summarize=true")
    })
    // console.log(retArr)
    return retArr;
  }

  public setupStateArr(stateArr = [], initialUrlArr = []) {
    let retArr = []
    //let state_query = "";
    let query_arr = []
    if(stateArr.length == 1){
      //state_query = '&eq=status:' + this.titleCasePipe.transform(stateArr[0])
      query_arr.push('&eq=status:' + this.titleCasePipe.transform(stateArr[0]))
    }else{

      stateArr.forEach(x =>{
        let neq_state = ""
        PrinterStateMappings.forEach(y => {
          let values = Object.values(y);
          if(values[0].indexOf(x.toLowerCase()) < 0){
            values[0].forEach(z => {
              neq_state = neq_state + ("&neq=status:" + this.titleCasePipe.transform(z));
            });
          }
        });
        if(query_arr.indexOf(neq_state) < 0) query_arr.push(neq_state)
      });
      //let not_eq_sate = _.difference(AllStates,stateArr.join('|').toLowerCase().split('|'));
      //console.log(not_eq_sate);
      // not_eq_sate.forEach(x=>{
      //   state_query = state_query + ("&neq=status:" + this.titleCasePipe.transform(x.toString()))
      // })
    }
    initialUrlArr.forEach(x => {
      query_arr.forEach(y => {
        retArr.push(x + y);
      });
    })
    return retArr;
  }

  // DASHBOARD 2: devices API
  public getPrinterListing3(filterObj, followPrinterArr = [], scrollFlag?, urlArr?, originalArr = [], offsetVal?) {
    this.loaderService.show();
    let initialUrlArr = urlArr || [];
    let allRec: any[] = originalArr;
    let offset = offsetVal || 0;
    let name_filter_included = null;
    if (!scrollFlag || scrollFlag == false) {
      let filterArr = [];
      let stateArr = [];
      if(filterObj['printer_status'].length == 0)
        filterObj['printer_status'] = filterObj['status_order']
      const keys = Object.keys(filterObj);
      for (const key of keys) { 
        if (key == 'printer_status') {
          filterArr.push([null]);
          let val = filterObj[key];
          if (Array.isArray(val) && val.length > 0) {
            stateArr = val;
          }
        } else if (key != 'status_order' && key != 'model_group') {
          let val = filterObj[key];
          
          if (key == 'name' && val){
            name_filter_included = val;
          }
          if (Array.isArray(val) && val.length > 0) {
            filterArr.push(val)
          }
          else if (val && val.length > 0)
            filterArr.push(val.split())
          else
            filterArr.push([null])
        }
      }
      if(filterObj.hasOwnProperty('model_group')){
        name_filter_included = null;
      }
      let cartesianArr = this.cartesianProduct(filterArr);

      if (name_filter_included) {
        let name_filter_arr = JSON.parse(JSON.stringify(cartesianArr));
        name_filter_arr.forEach(x => {
          x[2] = null
          x.push(name_filter_included)
        });
        cartesianArr = cartesianArr.concat(name_filter_arr);
      } 
      cartesianArr.forEach(element => {
        let url = this.prepareUrl(element);
        initialUrlArr.push(url);
      });
      if (true) {
        initialUrlArr = this.setupStateArr(stateArr, initialUrlArr);
        // console.log(setStateUrlArr)
      }
    }
    let endUrl = initialUrlArr[0];
    if (offset > 0) {
      endUrl = endUrl + '&offset=' + offset;
    }
    return this.commonService.getEndpointPromise('GET', endUrl, { headers: {} }, 'facade')
      .then(res => {
        let scrollDetails = initialUrlArr;
        let noOfRec = res.pagination.count;
        allRec = allRec.concat(res.results);
        if (noOfRec < 20 && initialUrlArr.length == 1)
          scrollDetails = false;
        let printerDetails = [];
        allRec.forEach(x => {
          //if(!x.attributes.serialNumber) {console.log(x)}
          var individualPrinter = new Printer(x, followPrinterArr);
          printerDetails.push(individualPrinter)
        });
        if (noOfRec < 20) {
          offsetVal = 0;
        }
        let resultObj = {
          "resultArr": printerDetails,
          "urlArr": scrollDetails,
          "offset": offsetVal
        }
        return (noOfRec >= 20 || initialUrlArr.length == 1) ? resultObj : (initialUrlArr.shift(), this.getPrinterListing3(undefined, followPrinterArr, true, initialUrlArr, allRec));
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.loaderService.hide();
      });
  }

  public getAdapterListing() {
    let url = '/devices?type=adapter';
    this.loaderService.show();
    return this.commonService.getEndpointPromise('GET', url, { headers: {} }, 'facade').finally(() => {
      this.loaderService.hide();
    });
  }

  public getDefaultPrinterThreshold(printer_id) {
    return this.commonService.getEndpointPromise('GET', `/alert-service/printers/${printer_id}/alerts/thresholds`, { headers: {} }, 'facade');
  }

  //Update AL for SF
  public UpdateSFRecords(printer_ids) {
    let data = {
      headers: {},
      body: { "printer_ids": printer_ids }
    }
    this.loaderService.show();
    //https://0mc3cbxf1k.execute-api.us-east-2.amazonaws.com/dev/printers/entitlement
    return this.commonService.getEndpointPromise('POST', '/printers/entitlement', data, 'facade').finally(() => {
      this.loaderService.hide();
    });
  }

  //Export Data 
  async  getTimeseriesExport(printerId, propertyCSV, startDate, endDate) {
    let options2 = { headers: new HttpHeaders().set('Content-Type', 'application/csv') };
    // S1 -> hit timeseies export API
    let responseObj = {
      "message": "no_timeseries_data_found",
      "url": null
    }

    let data = {
      headers: {},
      body: {
        "deviceId": printerId,
        "uuid": propertyCSV,
        "startDate": startDate,
        "endDate": endDate
      }
    }

    return this.commonService.getEndpointPromise('POST', `/timeseries/export`, data, 'timeseries-data')
      .then(async response => {
        if (response.statusCode != 200)
          return responseObj;
        let pollUrl = JSON.parse(response.body).url;
        // return pollUrl;
        return await Observable.interval(5000)
          .concatMap(() => this.http.get(pollUrl))
          .filter(x => (x['status'] == 'complete' || x['status'] == 'error'))
          .take(1)
          .timeout(600000)
          .toPromise().then(data => {
            if (data['status'] != 'complete')
              return responseObj;
            responseObj['message'] = 'success'
            responseObj['url'] = data['result']['presigned'];
            return responseObj;
          }).catch(error => {
            responseObj['message'] = 'timeout_occured'
            return responseObj ;
          })
          .finally(() => { this.loaderService.hide(); });
      });
  }

  public getAdapters(count?, offset?, deviceId?, serialNumber?, ipAddress?, status?) {
    this.loaderService.show();
    let url = '/devices?type=adapter';

    if (count > 0) { url += '&count=' + count; }
    if (offset > 0 ) { url += '&offset=' + offset; }
    if (deviceId) { url += '&startsWith=deviceId:' + deviceId; }
    if (serialNumber) { url += '&startsWith=serialNumber:' + serialNumber; }
    if (status) { url += '&eq=status:' + status; }
    if (ipAddress) { url += '&startsWith=ipAddress:' + ipAddress; }

    return this.commonService.getEndpointPromise('GET', url, { headers: {} }, 'facade').finally(() => { this.loaderService.hide(); })
  }

/*************************GG JOBS APIs START**************************** */
  public sendAdapterRemoteCommand(adapter_id: string, command_str: string) {
    this.loaderService.show();
    const payload = {
      headers: {},
      body: { adapter: adapter_id, operation: command_str }
    };
    return this.commonService.getEndpointPromise('POST', '/jobs', payload, 'facade2').finally(() => {
      console.log('hiding loader - invoke remote command');
      this.loaderService.hide();
    });
  }

  public getAdapterRemoteCommandJob(job: string) {
    // this.loaderService.show(); console.log('display loader - remote command status');
    const url = `/jobs/${job}`;
    const payload = { headers: {} };
    const status = ['completed', 'failed', 'cancelled'];

    return Observable.interval(10000)
    .concatMap(() => this.commonService.getEndpointPromise('GET', url, payload, 'facade2', false))
    .map(x => JSON.parse(x.body))
    .filter(x => ((x.success === true && status.includes(x.status.toLowerCase())) || x.success === false))
    .take(1)
    .timeout(120000)
    .toPromise().then(data => {
      return data;
    });
    // .finally(() => {this.loaderService.hide(); console.log('hiding loader - remote command status'); });
  }
  /*************************GG JOBS APIs END**************************** */

    //API - Get Timeseries Aggregated value
    public getHistoricAggregatedData(printerId: string, property: string, startDate, endDate, fixedInterval, interval?, op?, timezone = "0000") {
      this.loaderService.show();
      let data = {
        headers: {},
        body: {
          "deviceId": printerId,
          "uuid": property,
          "startDate": startDate,
          "endDate": endDate,
          "interval": interval ? interval : 'minutes',
          "op": op? op : "avg_min_max",
          "fixed_interval": fixedInterval        
        }
      }
      return this.commonService.getEndpointPromise('POST', `/timeseries/aggregation`, data, 'timeseries-data')
      .then( res => {
        let intervalObj = [{'avg':[]}, {'min:':[]}, {'max':[]}, {'export': []}];
        if (res.body && res.body.aggregations && res.body.aggregations.avg_per_interval && res.body.aggregations.avg_per_interval.buckets) {
          res.body.aggregations.avg_per_interval.buckets.forEach(element => {
            if(!isNullOrUndefined(element['avg_value'].value) && !isNullOrUndefined(element['min_value'].value) && !isNullOrUndefined(element['max_value'].value)) {
              let timestamp = convertUTCtoPrinterTimezone(new Date(element['key_as_string']).toISOString(), timezone);
              let avgObj = {'x': timestamp, 'y': element['avg_value'].value };
              intervalObj[0].avg.push(avgObj);
              let minObj = {'x': timestamp, 'y': element['min_value'].value }
              intervalObj[1]["min:"].push(minObj);
              let maxObj = {'x': timestamp, 'y': element['max_value'].value };
              intervalObj[2].max.push(maxObj);
              let exportObj = { "timestamp": timestamp, "avg_value": element['avg_value'].value, "min": element['min_value'].value, "max": element['max_value'].value };
              intervalObj[3].export.push(exportObj);
            }
          });
        }
        else if ( res.body && res.body.error)
          intervalObj = [];
        return intervalObj;
      })
      .finally(() => {
        this.loaderService.hide();
      })
    }

  public getAdapterLogList(adapter: string) {
    const url = `/device_logs/list/${adapter}`;
    return this.commonService.getEndpointPromise('GET', url, {}, 'facade2', false);
  }

  public downloadAdapterLog(key: string) {
    this.loaderService.show();
    // key = escape(btoa(key));
    // console.log(key);
    const payload = {headers: {}, body: {key}};
    const url = `/device_logs/download`;
    return this.commonService.getEndpointPromise('POST', url, payload, 'facade2', false).finally(() => {this.loaderService.hide(); });
  }

  // API to update AL fields
  public UpdateALProperty(device, payload) {
    console.log(payload);
    let data = {
      headers: {},
      body: payload }
    
    return this.commonService.getEndpointPromise('PUT', `/devices/${device}`, data, 'facade')
  }

  public getDeployedAdapters(): CustomDefer {
    let defer = new CustomDefer();
    this.loaderService.show();

    if(!this.deployedAdapters) {
      const url = `/adapters/provisioned`;

      this.commonService.getEndpointPromise('GET', url, {}, 'facade2')
                        .then( (response) => {defer.resolve(response); })
                        .finally(() => this.loaderService.hide());
    } else {
      defer.resolve(this.deployedAdapters);
      this.loaderService.hide()
    }
    return defer;
  }

  public setDeployedAdapters(data) {
    this.deployedAdapters = data;
  }

  public getFleetStatusSummaryRequest(params) {
    let data = {
      headers: {},
      body: params
    }
    return this.commonService.getEndpointPromise('POST', '/fleet/status/summary', data, 'facade2');
  }

  public getAlertPareto(params) {
    const data = {
      headers: {},
      body: params
    };

    return this.commonService.getEndpointPromise('POST', '/fleet/alerts/report', data, 'facade2');
  }

  public getAdapterConnectedDevicesReport(params) {
    const payload = {
      headers: {},
      body: params
    };
    const status = ['complete', 'error', 'processing'];
    const url = '/fleet/adapters/report';

    this.loaderService.show();

    return this.commonService.getEndpointPromise('POST', url, payload, 'facade2', false).then(
      res => {
        // console.log(res);
        const body = JSON.parse(res.body);
        // return this.http.get(body.url).toPromise();
        return Observable.interval(5000)
              .concatMap(() => this.http.get(body.url))
              .startWith(0)
              .filter(x => ((x['status'] === 'complete' ) || x['status'] === 'error'))
              .take(1)
              .timeout(120000)
              .toPromise().then(data => {
                return data;
              }).finally(() => this.loaderService.hide());
      }
    );
  }

  public getModelBuildReport(startDate, endDate, fullModel) {
    return this.commonService.getEndpointPromise('GET', `/fleet?startDate=${startDate}&endDate=${endDate}&fullModel=${fullModel}`, {}, 'facade');
  }

  public deletePrinter(printer_id) {
      let data = {
        headers: { "content-type": "application/json" },
        body:{printer_id: printer_id}
      }
  
      let url = `/printers/printer/delete-printer`;
      return this.commonService.getEndpointPromise('DELETE', url, data, 'facade'); 
  }
}
