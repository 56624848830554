import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomModalComponent } from '../../components/modals/custom-modal/custom-modal.component';
import { ChartsModuleModule } from '../charts-module/charts-module.module';
import { MaterialModuleModule } from '../material-module/material-module.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    CustomModalComponent
  ],
  imports: [
    CommonModule,
    ChartsModuleModule,
    MaterialModuleModule,
    SharedModule
  ],
  exports: [
    CustomModalComponent
  ],
  entryComponents: [
    CustomModalComponent
  ]
})
export class UtilModuleModule { }
