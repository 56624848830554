import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, ElementRef, SimpleChanges } from '@angular/core';
import { Chart} from 'chart.js';

@Component({
  selector: 'dynamic-piechart',
  templateUrl: './dynamic-piechart.component.html',
  styleUrls: ['./dynamic-piechart.component.scss']
})

export class DynamicPiechartComponent implements OnChanges {
  @Input() chartData: object[];
  @Input() options: any[];
  @Input() chartOptions: object[];
  @Input() height: string = "20em";
  @Input() width: string = "100%";
  @Input() showAnimation: boolean = true;
  @ViewChild('myCanvas')
  @Output('eventEmit') pieChartEmit = new EventEmitter();
  @Output() chartEmiter: EventEmitter<any> = new EventEmitter();

  objMinDate: any;
  objMaxDate: any;
  objStartMaxDate: any;
  startDate: any;
  endDate: any;

  colorArr: any[];
  public canvas: ElementRef;
  public context: CanvasRenderingContext2D;

  public doughnutChartLabels: any[];
  public doughnutChartColors: any[];
  public doughnutChartData: any[];
  public doughnutChartType = 'pie';
  public pieChartOptions
  objChartOptions: any;
  selected: string = 'default';

  numLoadPercentage: number = 0;

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    let colorObj = { 'backgroundColor': this.chartData["datasets"][0].backgroundColor }
    let colorArr = []
    this.doughnutChartLabels = this.chartData["labels"];
    this.doughnutChartData = this.chartData["datasets"][0].data;
    this.doughnutChartColors = colorArr;
    this.objChartOptions = this.options["dropdown"];
    colorArr.push(colorObj);
    this.selected = (this.options["selected"]) ? this.options["selected"] : "default";
    this.pieChartOptions = this.chartOptions ? this.chartOptions : {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        // position: 'bottom',
        display:false
      },
      title: {
        display: false,
        text: 'Chart.js Doughnut Chart'
      },
      // animation: {
      //   animateScale: true,
      //   animateRotate: true
      // },
      tooltips: {
        custom: function(tooltip) {
          tooltip.displayColors = false;
          },
        callbacks: {
          label: function(tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            let secs = dataset.data[tooltipItem.index];
            let sec_num = parseInt(secs, 10)
            let hours   = Math.floor(sec_num / 3600)
            let minutes = Math.floor(sec_num / 60) % 60
            let seconds = sec_num % 60;
            let time =  [hours,minutes,seconds]
            .map(v => v < 10 ? "0" + v : v)
            .join(":")
            return [data.labels[tooltipItem.index], time + ' HH:MM:SS']
          }
        }
      },
      
    }
    if (this.showAnimation) {
      this.pieChartOptions["animation"] = {        
        duration: 500,
        easing: "easeOutQuart",
        onComplete: function () {
          var ctx = this.canvas.getContext('2d');
          ctx.font = Chart.helpers.fontString("Georgia", 'normal', "Georgia");
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';
    
          this.data.datasets.forEach(function (dataset) {  
            for (var i = 0; i < dataset.data.length; i++) {
              var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                  total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                  mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius)/2,
                  start_angle = model.startAngle,
                  end_angle = model.endAngle,
                  mid_angle = start_angle + (end_angle - start_angle)/2;
    
              var x = mid_radius * Math.cos(mid_angle);
              var y = mid_radius * Math.sin(mid_angle);
    
              ctx.fillStyle = '#fff';
              if (i == 3){ // Darker text color for lighter background
                ctx.fillStyle = '#444';
              }

              var percent = ((dataset.data[i]/total) * 100).toFixed(2) + "%";
              if(Math.round(dataset.data[i]/total*100) !=0)  
                ctx.fillText(percent, model.x + x, model.y + y + 15);
            }
          });
        }
      }
    }
    this.setStartEndTime();
  }

  setStartEndTime() {
    this.startDate = this.options["start"] || new Date();
    this.endDate = this.options["end"] || new Date();
    this.objMinDate = this.startDate;
    this.objMaxDate = this.endDate;
    this.objStartMaxDate = this.endDate;
  }

  dateChange(event, type) {
    switch (type) {
      case 0: this.startDate = new Date(event.value); break;
      default: this.endDate = new Date(event.value); break;
    }
    this.objMinDate = this.startDate;
    this.objMaxDate = new Date();
    this.objStartMaxDate = this.endDate;
  }

  onSubmitRange() {
    let obj = {
      'startDate': this.startDate,
      'endDate': this.endDate
    };
    this.chartEmiter.emit(obj)
  }

  toggleDurationToCustom() {
    if (this.objChartOptions.findIndex(x => x.key == 'custom') > -1) {
      this.selected = "custom";
    }
  }

  onOptionChange(key) {
    this.chartEmiter.emit(key.value);
  }

}
