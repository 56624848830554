import { Injectable } from '@angular/core';
import { API, Auth } from 'aws-amplify';
import { User } from '../models/user';
import { UserResponseModel } from '../models/response';
import { CustomDefer } from '../models/customDefer';

import { LoaderService } from './loader.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from './common.service';
import { NotifyService } from './notify.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { deployments } from 'src/environments/deployments';
import { start } from 'repl';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  company = deployments.company;
  users = null;
  currentUser = null;

  constructor(
    public loaderService: LoaderService,
    private httpClient: HttpClient,
    private commonService: CommonService,
    private notifyService: NotifyService,
    private authenticationService: AuthenticationService,
  ) {}

  public getMTMSUsersData(){
    this.loaderService.show();
    return this.commonService.getEndpointPromise('GET', `/users?per_page=1000`, { headers: {} }, 'mtms').finally(() => {
      this.loaderService.hide();
    });
  }

  public getAWSUsersData(company){
    let data = {
      headers: {},
      body:{company:company}
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('POST', `/companies/${company}/users`, data, 'userMgmt').finally(() => {
      this.loaderService.hide();
    });
  }

  public createMTMSUser(newEmail, newMeta){
    let data = {
      headers: {},
      body:{email:newEmail, meta:newMeta}
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('POST', `/users`, data, 'mtms').finally(() => {
      this.loaderService.hide();
    });
  }
  public createAWSUser(userData, company){
    userData.company = company
    let data = {
      headers: {},
      body:userData
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('POST', `/profile/${this.company}/admin/user-create`, data, 'userMgmt').finally(() => {
      this.loaderService.hide();
    });
  }

  public requestAWSSUser(newMeta){
    newMeta.frontend_url = deployments.url + "/signup/approval"
    newMeta.env = deployments.stage
    newMeta.company = deployments.company
    let data = {
      headers: {},
      body:newMeta
    }
    console.log(data);
    this.loaderService.show();
    return this.commonService.getEndpointPromise('POST', `/profile/${this.company}/user-request`, data, 'userMgmt').finally(() => {
      this.loaderService.hide();
    });
  }

  public requestMTMSUser(newMeta){
    newMeta.front_end_url = deployments.url + "/signup/approval"
    let data = {
      headers: {},
      body:newMeta
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('POST', `/users/request`, data, 'mtms').finally(() => {
      this.loaderService.hide();
    });
  }

  public approveMTMSUser(newMeta){
    let data = {
      headers: {},
      body:newMeta
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('PUT', `/users/request`, data, 'mtms').finally(() => {
      this.loaderService.hide();
    });
  }

  public updateMTMSUser(id, newMeta){
    let data = {
      headers: {},
      body:{meta:newMeta}
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('PUT', `/users/${id}`, data, 'mtms').finally(() => {
      this.loaderService.hide();
    });
  }

  public updateAWSSUser(username, userData){
    let data = {
      headers: {},
      body:{username:username, attributes:userData}
    }
    console.log(data.body)
    this.loaderService.show();
    return this.commonService.getEndpointPromise('PUT', `/profile//admin/user-update`, data, 'userMgmt').finally(() => {
      this.loaderService.hide();
    });
  }

  public updateAWSUserInitialLogin(username, company){
    let data = {
      headers: {},
      body:{username:username}
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('PUT', `/profile/${company}/user-mark-login`, data, 'userMgmt').finally(() => {
      this.loaderService.hide();
    });
  }

  public updateAWSUserProfie(username, access_token, company, attributes){
    let data = {
      headers: {},
      body:{access_token: access_token, username:username, attributes:attributes}
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('PUT', `/profile/${company}/info`, data, 'userMgmt').finally(() => {
      this.loaderService.hide();
    });
  }

  deleteMTMSUser(id:number){
    this.loaderService.show();
    return this.commonService.getEndpointPromise('DELETE', `/users/${id}`, { headers: {} }, 'mtms').finally(() => {
      this.loaderService.hide();
    });
  }

  public deleteAWSSUser(userData){
    let data = {
      headers: {},
      body:{users:[userData]}
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('DELETE', `/profile//admin/user-delete`, data, 'userMgmt').finally(() => {
      this.loaderService.hide();
    });
  }

  public updateMTMSUserGroups(id, newIds){
    let data = {
      headers: {},
      body:{group_ids:newIds}
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('PUT', `/users/${id}/groups`, data, 'mtms').finally(() => {
      this.loaderService.hide();
    });
  }

  public getMTMSUserDetailsData(id: number){
    this.loaderService.show();
    return this.commonService.getEndpointPromise('GET', `/users/${id}?acl=true`, { headers: {} }, 'mtms').finally(() => {
      this.loaderService.hide();
    });
  }
  public getAWSSAdaminUserDetailsData(username){
    let data = {
      headers: {},
      body:{username:username}
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('POST', `/profile/${this.company}/admin/user-info`, data, 'userMgmt').finally(() => {
      this.loaderService.hide();
    });
  }

  public getAWSSUserDetailsData(access_token, company){
    let data = {
      headers: {},
      body:{access_token: access_token}
    }
    this.loaderService.hide();
    return this.commonService.getEndpointPromise('POST', `/profile/${company}/info`, data, 'userMgmt').finally(() => {
      this.loaderService.hide();
    });
  }

  public sendMTMSUserReinvitation(id: number){
    this.loaderService.show();
    return this.commonService.getEndpointPromise('POST', `/users/${id}/reset_password`, { headers: {} }, 'mtms').finally(() => {
      this.loaderService.hide();
    });
  }

  public sendAWSUserResetPwd(username, company){
    let data = {
      headers: {},
      body:{username: username}
    }
    this.loaderService.show();
    return this.commonService.getEndpointPromise('PUT', `/profile/${company}/resetpassword`, data, 'userMgmt').finally(() => {
      this.loaderService.hide();
    });
  }

  public setCurrentUserData(user){
    if(!this.currentUser || (this.currentUser && this.currentUser['cognito:username'] != user['cognito:username'])){
      this.currentUser = user;
    }
    return true;
  }
  
  public getCurrentUserAclData(refresh?: boolean) {
    let defer = new CustomDefer();
    // this.loaderService.show();
    if (this.currentUser && !refresh) {
      // this.loaderService.hide();
      defer.resolve(this.currentUser);
    } else {
      this.authenticationService.currentUser().then(x => {
        this.currentUser = x.signInUserSession.idToken.payload;
        this.currentUser.accessToken = x.signInUserSession.accessToken.jwtToken;
        this.notifyService.notifyCurrentUserDataChange(this.currentUser);
        defer.resolve(this.currentUser);
      }).catch(error => {
        defer.reject();
        console.log(error);
      }).finally(() => {
        // this.loaderService.hide();
      });
      // this.commonService.getEndpointPromise('GET', `/users/0?acl=true`, { headers: {} }, 'mtms').then(response => {        
      //   this.currentUser = response;        
      //   // this.currentUser.id = response.id;
      //   this.notifyService.notifyCurrentUserDataChange(response);
      //   defer.resolve(response);
      // }).catch(error => {
      //   defer.reject();
      //   console.log(error);
      // }).finally(() => {
      //   // this.loaderService.hide();
      // });
    }
    return defer;
  }

  public async updateAlertThreshold(printerId, alertName, params) {
    let data = {
      body: params
    }
    let url = `/printers/${printerId}/alerts/${alertName}/update`;

    this.loaderService.show();
    return this.commonService.getEndpointPromise('POST', url, data, 'alertService').finally(() => {
      this.loaderService.hide();
    });
  }

  public getUsersSubscriptions(printerId) {
    let url = `/printers/${printerId}/alerts/subscriptions`;

    this.loaderService.show();
    return this.commonService.getEndpointPromise('GET', url, {headers: {}}, 'alertService').finally(() => {
      this.loaderService.hide();
    });
  }

  public getUsersAlertSubscriptions() {
    this.loaderService.show();
    return this.commonService.getEndpointPromise('GET', `/profile/subscriptions1`, {headers: {}}, 'userMgmt').finally(() => {
    this.loaderService.hide();  });
  }

  public subscribeToAlert(printerId, alertIds?) {
    let data = {
      headers: { "content-type": "application/json" },
      body: null
    }
    if (alertIds) {
      data.body = {
        "event_ids": alertIds
      }
    }
    let url = (alertIds) ? `/alert-service/printers/${printerId}/alerts/subscribe` : `/alert-service/printers/${printerId}/alerts/subscribe/all`;
    return this.commonService.getEndpointPromise('POST', url, data, 'facade');
  }

  public unsubscribeFromAlert(printerId, alertIds?) {
    let data = {
      headers: { "content-type": "application/json" },
      body: null
    }
    if (alertIds) {
      data.body = {
        "event_ids": alertIds
      }
    }
    let url = (alertIds) ? `/alert-service/printers/${printerId}/alerts/subscribe` : `/alert-service/printers/${printerId}/alerts/subscribe/all`;
    return this.commonService.getEndpointPromise('DELETE', url, data, 'facade');
  }

  public subscribeToAllAlerts(printer_id) {
    let data = {
      headers: { "content-type": "application/json" },
      body: null
    }

    let url = `/alert-service/printers/${printer_id}/alerts/async/subscribe/all`;
    return this.commonService.getEndpointPromise('POST', url, data, 'facade'); 
  }

  public unsubscribeFromAllAlerts(printer_id) {
    let data = {
      headers: { "content-type": "application/json" },
      body: null
    }

    let url = `/alert-service/printers/${printer_id}/alerts/async/subscribe/all`;
    return this.commonService.getEndpointPromise('DELETE', url, data, 'facade'); 
  }
    

  public clearCurrentUserData() {
    this.currentUser = null;
  }

  public getUserLogingData(startDate, endDate) {
    console.log('recieved');
    console.log(startDate, endDate);
    const data = {
      headers: {},
      body: {
        start_login_time : startDate.toString(),
        end_login_time: endDate.toString()}
    };
    const url = `/profile/${this.company}/admin/users-log`;
    this.loaderService.show();
    return this.commonService.getEndpointPromise('POST', url,  data, 'userMgmt')
    .finally(() => { this.loaderService.hide(); });
  }
}
