import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicChartComponent } from './dynamic-chart/dynamic-chart.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModuleModule } from '../material-module/material-module.module';
import { DynamicC3ChartComponent } from './dynamic-c3-chart/dynamic-c3-chart.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { ChartsModule } from 'ng2-charts';
import { ChartjsComponent } from './dynamic-chartjs/dynamic-chartjs.component';
import { Chart } from 'chart.js';
import { DynamicPiechartComponent } from './dynamic-piechart/dynamic-piechart.component'
import { DynamicStackedChartComponent } from './dynamic-stacked-chart/dynamic-stacked-chart.component';

@NgModule({
  declarations: [
    DynamicChartComponent,
    DynamicC3ChartComponent,
    ChartjsComponent,
    DynamicPiechartComponent,
    DynamicStackedChartComponent
  ],
  imports: [
    CommonModule,
    MaterialModuleModule,
    SharedModule,
    Ng2GoogleChartsModule,
    ChartsModule
  ],
  exports: [
    DynamicChartComponent,
    DynamicC3ChartComponent,
    ChartjsComponent,
    DynamicPiechartComponent,
    DynamicStackedChartComponent,
    ChartsModule
  ],
  entryComponents: [
    DynamicChartComponent,
    DynamicC3ChartComponent,
    DynamicPiechartComponent,
    DynamicStackedChartComponent,
    ChartjsComponent
  ]
})
export class ChartsModuleModule { }
