import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class LoaderService {
    loaderCounter: number = 0;
    isLoading = new Subject<boolean>();
    show() {
        this.isLoading.next(true);
    }
    hide() {
        if (this.loaderCounter <= 0) this.isLoading.next(false);
    }

    public incrementCounter() {
        this.loaderCounter++;
    }

    public decrementCounter() {
        this.loaderCounter--;
        if (this.loaderCounter <= 0) {
            this.loaderCounter = 0;
            this.isLoading.next(false);
        }
    }
}