import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class CustomModalService {

    customModalObs: Observable<any>;
    private customModalSub = new Subject();

    constructor() {
        this.customModalObs = this.customModalSub.asObservable();
    }

    public getData() {
        this.customModalSub.next('emitData');
    }
}
