import { user_types } from './constants/constants';

export class User {
		id: number;
    name: string;
    email: string;
    meta: object;
    group_ids: [];
    acl: object;
    status: string;
    user_type: string;
    company: string;

    constructor(user: object) {
      this.name = user['name'];
      this.email = user['email'];
      this.group_ids = JSON.parse(user['group_owner_of']);
      this.id = user['cognito:username'];
      this.user_type = this.deduce_user_type(user);
      this.company = user['company'];
    }

    deduce_user_type(user: object) {
      if (user['superadmin'].toLowerCase() == "true") {
        return user_types.super_admin;
      } else if (user['admin'].toLowerCase() == "true") {
        return user_types.admin;
      } else if (this.group_ids.length > 0) {
        return user_types.group_owner;
      }
      return user_types.user;
    }
}